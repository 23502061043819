import * as type from './type';

const initialState = {
  galleries: [],
  isShowVirtualModal: false,
  activeGallery: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_ACTIVE_GALLERY:
      return {
        ...state,
        activeGallery: action?.data?.data,
      };
    case type.SET_IS_SHOW_VIRTUAL_MODAL:
      return {
        ...state,
        isShowVirtualModal: action?.data,
      };
    case type.GET_GALLERY_LIST:
      return {
        ...state,
        galleries: action?.data?.data,
      };
    default:
      return state;
  }
};
