import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import Select from 'react-select';
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from '../../../apis/api/customer';
import { CountryOptions, getSafeCountryCallingCode } from "../../../components/phone-input/phone-input";
import { useTranslation } from "react-i18next";

import HubSpotStopSyncCustomer from "../../../components/hubspot/hubspot-stop-sync-customer";
import HubSpotSyncUpdateCustomer from "../../../components/hubspot/hubspot-sync-update-customer";
import HubSpotConfirmSyncCustomer from '../../../components/hubspot/hubspot-confirm-sync-customer';

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Please enter a valid email").required("Email is a required field"),
	mobile: yup.string().trim().min(8).required("Phone number is a required field"),
	countryCode: yup.string().trim().required("Country code is a required field"),
});

const EditCustomerModal = ({ customer, show, setShow, onSaveSuccess }) => {
	const {t, i18n} = useTranslation();
	const [customerState, setCustomer] = useState(customer);
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [address, setAddress] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [isShowDeleteCustomerModal, setIsShowDeleteCustomerModal] = useState(false);
	const [isShowHubSpotStopSyncModal, setIsShowHubSpotStopSyncModal] = useState(false);
	const [isShowHubSpotConfirmSyncModal, setIsShowHubSpotConfirmSyncModal] = useState(false);
  const [errors, setErrors] = useState({});

	useEffect(() => {
		if (customerState) {
			setFirstName(customerState.firstname || '');
			setSurName(customerState.surname || '');
			setEmail(customerState.email || '');
			setMobile(customerState.mobile || '');
			setAddress(customerState.address || '');
			setCountryCode(customerState.countryCode || '');
		}
	}, [customerState])

	const handleUpdateCustomer = async () => {
		if (!customerState) return;
		
		try {
			const data = {
				firstname,
				surname,
				email,
				mobile,
				address,
				countryCode,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.updateCustomer(customerState.id, result);
			if (res.data) {
				toast.success(t("Customer updated successfully!")); 
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleDeleteCustomer = async () => {
		if (!customerState) return;

		try {
			const res = await customersAPI.deleteCustomer(customerState.id);
			if (res.data) {
				toast.success(t("Customer deleted successfully!"));
				handleCloseDeleteCustomerModal();
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			setErrors(err);
			toast.error(err.message);
		}
	}

	const handleOpenDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(true);
	}

	const handleClose = () => {
		setShow(false);
		onSaveSuccess?.();
	};

	const handleCloseDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(false);
	}

	const onSyncHubSpotSuccess = async () => {
		try {
			const res = await customersAPI.getCustomerDetail(customerState.id);
			setCustomer(res.data);
		} catch(ex) {
			console.err(ex);
		}
	}

	const onStopSyncHubSpot = () => {
		setIsShowHubSpotStopSyncModal(true);
	}

	const onSyncHubSpot = () => {
		setIsShowHubSpotConfirmSyncModal(true);
	}

	const handleCloseStopSyncHubSpotModal = () => {
		setIsShowHubSpotStopSyncModal(false);
	}

	const handleCloseConfirmSyncHubSpotModal = () => {
		setIsShowHubSpotConfirmSyncModal(false);
	}

	if (isShowDeleteCustomerModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowDeleteCustomerModal}
				onHide={handleCloseDeleteCustomerModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("DELETE CUSTOMER")}?</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseDeleteCustomerModal} />
					</div>
					<div className="confirm">
						{t("Are you sure you want to delete")} {customer?.name}?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div onClick={handleDeleteCustomer}>{t("YES, DELETE")}</div>
				</Modal.Footer>
			</Modal>
		);
	}

	if (isShowHubSpotStopSyncModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowHubSpotStopSyncModal}
				onHide={handleCloseStopSyncHubSpotModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>STOP SYNCING TO HUBSPOT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseStopSyncHubSpotModal} />
					</div>
					<div className="confirm">
						Are you sure you want to stop syncing {firstname} {surname}? Hubspot will no longer receive updates about this profile.
					</div>
				</Modal.Body>
				<Modal.Footer>
					<HubSpotStopSyncCustomer 
						customer={customerState}
						onStopSyncSuccess={() => {
							onSyncHubSpotSuccess();
							handleCloseStopSyncHubSpotModal();
						}}
					/>						
				</Modal.Footer>
			</Modal>
		);
	}

	if (isShowHubSpotConfirmSyncModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowHubSpotConfirmSyncModal}
				onHide={handleCloseConfirmSyncHubSpotModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>SYNC TO HUBSPOT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseConfirmSyncHubSpotModal} />
					</div>
					<div className="confirm">
						Are you sure you want to sync {firstname} {surname} to Hubspot?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<HubSpotConfirmSyncCustomer
						customer={customerState}
						onSyncSuccess={() => {
							onSyncHubSpotSuccess();
							handleCloseConfirmSyncHubSpotModal();
						}}
					/>						
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<Modal
			className="wrap-update-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{t("UPDATE CUSTOMER")}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("First Name")}*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder={t("First Name")}
							onChange={(e) => setFirstName(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{t(errors?.firstname)}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("Last Name")}*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder={t("Last Name")}
							onChange={(e) => setSurName(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{t(errors?.surname)}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Address")}</Form.Label>
						<FormControl
							id="address"
							name="address"
							type="input"
							value={address}
							placeholder={t("Address")}
							onChange={(e) => setAddress(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
					</Form.Group>
				</div>				
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Email")}*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder={t("Email")}
							onChange={(e) => setEmail(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{t(errors?.email)}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Phone Number")}*</Form.Label>
						<div className="phone">
							<div className="phone-input">
								<div className="flag">
									<div className={`country ${countryCode || 'hidden'}`} />
								</div>
							</div>
							<Select
								className="mobile-code"
								classNamePrefix="select"
								value={{ value: countryCode || '', label: `+${+getSafeCountryCallingCode(countryCode)}`}}
								options={CountryOptions(i18n.language)}
								name="countryCode"
								isSearchable={false}
								menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
								styles={{
									menuPortal: base => ({...base, zIndex: 9999})
								}}
								onChange={(item) => setCountryCode(item?.value)}
								disabled={customerState.isSyncHubSpot}
							/>
							<FormControl
								className="mobile-input"
								id="mobile"
								name="mobile"
								type="input"
								value={mobile}
								placeholder={t("Phone")}
								onChange={(e) => setMobile(e.target.value)}
								disabled={customerState.isSyncHubSpot}
							/>
						</div>
						<span className="error">{t(errors?.countryCode || errors.mobile)}</span>
					</Form.Group>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<span className="save" onClick={handleUpdateCustomer}>{t("SAVE")}</span>
				{!customer.isActive && <span className="delete" onClick={handleOpenDeleteCustomerModal}>{t("DELETE CUSTOMER")}</span>}
			</Modal.Footer>
		</Modal>
	);
};

export default EditCustomerModal;
