import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./en.json"
import az from "./az.json"
import ru from "./ru.json"

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {translation: en},
      az: {translation: az},
      ru: {translation: ru},
    },
    debug: process.env.NODE_ENV === 'development',
    // lng: "en", //if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });
