import React, { useState, useEffect, useMemo } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import { useTranslation } from "react-i18next";
import amenityV2 from "../../../apis/api/amenityV2";

const validationSchema = yup.object().shape({
	title: yup.string().trim().required("Amenity name is a required field"),
	description: yup.string().trim().required("Description cannot be empty"),
});

const EditAmenityModal = ({ amenity, show, setShow, onSaveSuccess }) => {
	const {t, i18n} = useTranslation();

	const [language, setLanguage] = useState()

	const [title, setTitle] = useState({});
	const [description, setDescription] = useState({});
  const [errors, setErrors] = useState({}) 

	useEffect(() => {
		if (amenity) {
			setTitle(amenity.title);
			setDescription(amenity.description);
		}
	}, [amenity])

	useEffect(() => {
		setLanguage(i18n.language)
	}, [i18n.language])

	const validate = (data) => {
		const err = {};
		let valid = true
		if(!data.title || !data.title[language] || !data.title[language].trim()) {
			err.title = "Amenity name is a required field"
			valid = false;
		}
		if(!data.description || !data.description[language] ||  !data.description[language].trim()) {
			err.description = "Description cannot be empty"
			valid = false;
		}
		setErrors(err)
		return valid
	}

	const handleUpdateAmanity = async () => {
		if (!amenity) return;
		
		try {
			const data = {
				title,
				description,
			};
			setErrors({});
			if(!validate(data)) return;
			const res = await amenityV2.updateAmenityV2(amenity.id, data);
			if (res.data) {
				toast.success("Amenity updated successfully!"); 
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {}
	};

	const handleClose = () => {
		setShow(false);
	};

	const titleValue = useMemo(() => {
		if(title && title[language]) return title[language]
		return ""
	}, [language, title])

	const descriptionValue = useMemo(() => {
		if(description && description[language]) return description[language]
		return ""
	}, [language, description])

	return (
			<Modal
				className="wrap-update-amenity-modal"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("UPDATE AMENITY")}</Modal.Title> 
					<div className="wrap-form">
					<div className="form-info">
						<select
							onChange={(e) => setLanguage(e.target.value)}
							value={language}
						>
							<option value="en">{t("EN")}</option>
							<option value="az">{t("AZ")}</option>
							<option value="ru">{t("RU")}</option>
						</select>
					</div>
					</div>
					
				
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleClose} />
					</div>
					<div className="wrap-form-group">
						<Form.Group className="form-group">
							<Form.Label>{t("Amenity Name")}*</Form.Label>
							<FormControl
								id="name"
								name="name"
								type="input"
								value={titleValue}
								placeholder="Amenity Name"
								onChange={(e) => setTitle({...title, [language]: e.target.value})}
							/>
							<span className="error">{t(errors?.title)}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group
							className="form-group"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>{t("Description")}*</Form.Label>
							<Form.Control
								as="textarea" rows={6}
								onChange={(e) => setDescription({...description, [language]: e.target.value})}
								value={descriptionValue}
							/>
							<span className="error">{t(errors?.description)}</span>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button onClick={handleUpdateAmanity}>{t("SAVE")}</button>
				</Modal.Footer>
			</Modal>
	);
};

export default EditAmenityModal;
