import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getFaqList = (data) => client.get(endPoint.FAQ_LIST, data);

export default {
  getFaqList,
};
