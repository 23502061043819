import React, { useEffect, useState } from "react";
import "./index.scss";
import jwtAuth from "../../apis/utils/jwtAuth";
import authApi from "../../apis/api/auth";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  reqGetUserProfile,
} from "../../reduxs/user/action";
import {
  reqSetIsShowLanguageModal,
} from "../../reduxs/guide-session/action";
import ROUTE_PATH from "../../routers/path";
import { useTranslation } from "react-i18next";
import LanguageModal from "../../components/guide-sesions/language-modal";
import icBaku from "../../assets/images/baku_logo.svg";

const HoldingPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);
  const {t} = useTranslation();

  useEffect(() => {
    getUserProfile();
    dispatch(reqSetIsShowLanguageModal(true));
  }, []);

  useEffect(() => {
    redirectIfAuthenticated();
  }, [authUser]);

  useEffect(() => {
    if (email || password) {
      // validateEmail(email)
      //   ? setErrorMessage("")
      //   : setErrorMessage("The email must be a valid email address.");
      // validatePassword(password)
      //   ? setErrorMessage("")
      //   : setErrorMessage(
      //       "The password must be minimum eight characters, at least one letter and one number."
      //     );
      setErrorMessage("")
    }
  }, [email, password]);

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken() && authUser) {
      history.push("/");
    }
  };

  const handleForgotPassword = () => {
    history.push(ROUTE_PATH.FORGOT_PASSWORD);
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      history.push(history.location?.state?.path || "/");
    } else {
      setErrorMessage(t('errors.wrong_email_or_password'));
    }
  };

  const getUserProfile = async () => {
    try {
      dispatch(reqGetUserProfile());
    } catch (error) {
      return;
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className="icon-cube">
        <img
          src={icBaku}
          alt="icon-cube"
        />
      </div>
      <div className="wrap-modal-form">
        <div className={`modal-form`}>
          <h4 className="modal-form__title mb-0">
            {t('WELCOME TO THE BAKU')}
            <br />
            {t('RITZ-CARLTON RESIDENCES APP')}
          </h4>
          <div className="modal-form__body">
            <span className='label-form'>
              {t('Email')}
            </span>
            <div className="form-group-login">
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                className="form-control"
                id="email"
                placeholder={t('Email')}
              />
            </div>
            <span className='label-form'>
              {t('Password')}
            </span>
            <div className="form-group-login">
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                id="password"
                aria-describedby="passwordHelp"
                placeholder={t('Password')}
              />
            </div>
            <p id="passwordHelp" className="text-danger mt-2 mb-0">
              {errorMessage}
            </p>
            <button
              type="submit"
              onClick={handleLogin}
              className="btn-holding-login"
            >
              <span className="txt-login">{t('Log in')}</span>
            </button>
            <button
              type="submit"
              onClick={handleForgotPassword}
              className="btn-forgot"
            >
              <span className="txt-login">{t('Forgot Password')}</span>
            </button>
          </div>
        </div>
      </div>
      <LanguageModal />
    </div>
  );
};

export default HoldingPage;
