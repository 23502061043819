import React, { useState } from "react";
import Modal from "../../modal-base";
import "./index.scss";

const ServicesModal = ({ isShowServicesModal, onHide }) => {
  const onHideModal = () => {
    onHide();
  };

  return (
    <Modal
      classNames="modal-services"
      open={isShowServicesModal}
      onHide={onHideModal}
    >
      <div className="service-wrapper">
        <div className="left-content">
          <span className="title-content">SERVICES</span>
          <span className="des-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur
          </span>
        </div>
        <div className="left-content right-content">
          <span className="title-content">SERVICES</span>
          <span className="des-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ServicesModal;
