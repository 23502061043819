import React from 'react';
import { useTranslation } from 'react-i18next';

import './sidenav.scss';

const SideNav = (props) => {
  const { i18n } = useTranslation();

  return (
    <div
      id={props.id}
      className={`sidenav ${props.isDark && 'sidenav--dark'} ${
        props.position === 'left' ? 'float-start' : 'float-end'
      } ${
        i18n.language === 'ru' ? 'ru' : ''
      } d-flex-column h-100 ${props.isTransparent ? 'invisible' : ''}`}
    >
      {props.heading && (
        <div className="sidenav__heading">
          <h2 className="text-capitalize f-title">{props.heading}</h2>
          {
            props.icon && <p className={'sidenav__heading__icon'}>{props.icon}</p>
          }

        </div>
      )}
      <div className="sidenav__body d-flex-column">{props.children}</div>
      {
        props?.footer && (
          <div className={'sidenav__footer'}>
            {props.footer}
          </div>
        )
      }
    </div>
  );
};

export default SideNav;
