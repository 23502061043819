import React, { useRef, useState, useEffect } from "react";
import * as dat from "dat.gui";
import { reqGetUserProfile } from "../../reduxs/user/action";
import {
  Canvas,
  useThree,
  extend,
  useFrame,
  useLoader
} from "react-three-fiber";
import * as THREE from "three";
import { CustomControls } from "./CustomControls";
import { Vector3 } from "three";
import {
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { rStats } from "../../helper/rStats";
import { glStats, threeStats } from "../../helper/rStats.extras";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import { getMediaUrl } from "../../helper/media";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal
} from "../../reduxs/explore-modal/action";
import { reqSetIsShowFilter } from "../../reduxs/unit-explore/action";
import { reqSetActiveTransportOption } from "../../reduxs/home/action";
import { reqSetPage } from "../../reduxs/home/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { useDispatch, useSelector } from "react-redux";
import { OrbitCustomControls } from "./OrbitCustomControls";
import socket from "../../helper/socket";
import { clearFilterUnit } from "../../helper/utils";

extend({ CustomControls });
extend({ OrbitCustomControls });

export let selectedHotspot3D;
export let selectedHotspot;

const CanvasBox = React.memo(
  React.forwardRef((props, refScene) => {
    const {
      _3dSetting,
      controls,
      fbxs,
      hotspots,
      isIntroduction,
      objects,
      targetPosition,
      activeObjectIds,
      setActiveObjectIds,
      isPresentation,
    } = props;
    const { i18n } = useTranslation();

    const [ambientLight, setAmbientLight] = useState({
      intensity: 70,
      color: "#1c2222"
    });
    const guiContainerRef = useRef();
    const refCurrentHotspotActive = useRef();

    useEffect(() => {
      dispatch(reqGetUserProfile());
      const gui = new dat.GUI({ autoPlace: false });
      if (guiContainerRef.current) {
        guiContainerRef.current.appendChild(gui.domElement);
      }
      gui.add(ambientLight, "intensity", 0, 1000).onChange((value) => {
        setAmbientLight((prev) => ({ ...prev, intensity: value }));
      });
      gui.addColor(ambientLight, "color").onChange((value) => {
        setAmbientLight((prev) => ({ ...prev, color: value }));
      });
      return () => gui.destroy();
    }, []);

    const authUser = useSelector((state) => state.user.data);

    const dispatch = useDispatch();
    const page = useSelector((state) => state.home.page);

    let lastPosition = new THREE.Vector3();
    let lastTarget = new THREE.Vector3();
    let pointerDown = false;
    const refListenSocket = useRef(false);

    useEffect(() => {
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
      return () => {
        refListenSocket.current = false;
        if (socket) socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
    }, [isPresentation]);

    const listenerSharedUIAction = ({ content }) => {
      if (content.action === ACTION_NAME.CLICK_HOTSPOT && !refListenSocket.current) {
        refListenSocket.current = true;
        handleClickHotspot(content.data.hotspot);
        let timeout = setTimeout(() => {
          refListenSocket.current = false;
          clearTimeout(timeout)
        }, 500);
      }
    };

    const sendCameraPos = () => {
      if (!isPresentation && controls.current?.didUpdate) {
        lastPosition.x = controls.current.object.position.x;
        lastPosition.y = controls.current.object.position.y;
        lastPosition.z = controls.current.object.position.z;

        lastTarget.x = controls.current.target.x;
        lastTarget.y = controls.current.target.y;
        lastTarget.z = controls.current.target.z;

        let quaternion = {
          x: controls.current.object.quaternion.x,
          y: controls.current.object.quaternion.y,
          z: controls.current.object.quaternion.z,
          w: controls.current.object.quaternion.w,
        };

        socket.emit(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, {
          content: {
            position: lastPosition,
            quaternion: quaternion,
            zoom: controls.current.object.zoom,
          },
          to: authUser?.id,
          from: authUser?.id,
        });
      }
    }

    const light = useRef();
    let glS, tS, rS;
    let timeVector3 = new Vector3(0, 0, 0);
    let alphaVector3 = new Vector3(0, 0, 0);

    let isCameraAnimation = false;

    let position = new THREE.Vector3();

    const [selectedHotspotId, setSelectedHotspotId] = useState("");
    const [isCameraAnimated, setCameraAnimated] = useState(false);
    // let hotspot3Ds = [];
    const hotspot3Ds = useRef([]);
    let hotspotHasChildren = {};
    let pointerDownId;
    let hotspotPointerDownId;

    let meshInstanceMap = {};

    populateMeshInstanceMapKeys(fbxs);
    associateModelsToMap(objects);

    function getFbxFileName(fbx) {
      return _.snakeCase(fbx.name);
    }

    function getModelFileName(model) {
      return _.snakeCase(model["3d_filename"]);
    }

    function populateMeshInstanceMapKeys(fbxs) {
      fbxs.forEach((fbx) => {
        let entry = { model: fbx, instances: [] };
        let key = getFbxFileName(fbx);
        meshInstanceMap[key] = entry;
      });
    }

    function associateModelsToMap(objects) {
      objects.forEach((obj) => {
        // Make assumption that we can remove .fbx from file_name
        let name = getModelFileName(obj);
        if (!meshInstanceMap[name]) {
          console.warn("No FBX File supplied for", obj);
          return;
        }
        meshInstanceMap[name].instances.push(obj);
      });
    }

    function handleAreaClick(controls, camLookAtPosition, camPosition) {
      return controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition
      );
    }

    function updateHotspot() {
      for (let i = 0; i < hotspot3Ds.current.length; i++) {
        let hotspot3D = hotspot3Ds.current[i];
        if (!hotspot3D || !hotspot3D.material) {
          continue;
        }
        let hotspot = hotspot3D.userData;
        hotspot3D.material.map = hotspot.texture;
        let isVisible = true;
        let isSubHotspot = hotspot.parent_id != null;
        if (isSubHotspot) {
          isVisible = hotspot.parent_id == selectedHotspotId;
        } else if (hotspotHasChildren[hotspot.id]) {
          isVisible = hotspot.id != selectedHotspotId;
        }
        hotspot3D.visible = isVisible;
        if (!isVisible) {
          hotspot3D.layers.set(LAYERS.DISABLE);
        } else {
          hotspot3D.layers.set(hotspot.layer);
        }
      }
    }

    function threePosition(data) {
      return new Vector3(data.x, data.z, -data.y);
    }

    function threePosition2(data, vector) {
      vector.x = data.x;
      vector.y = data.z;
      vector.z = -data.y;
    }

    function setColor(color, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.color.set(color);
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].color.set(color);
            }
          } else {
            child.material.color.set(color);
          }
        }
      });
    }

    function animateAlpha(alpha, object3d) {
      if (alpha == object3d.userData.alpha) {
        return;
      }

      alphaVector3.x = object3d.userData.alpha;
      alphaVector3.y = 0;
      alphaVector3.z = 0;

      gsap.to(alphaVector3, {
        duration: 0.2,
        x: alpha,
        y: 0.0,
        z: 0.0,
        onUpdate: function () {
          setAlpha(alphaVector3.x, object3d);
        },
        onComplete: function () {
          object3d.userData.alpha = alpha;
        }
      });
    }

    function setAlpha(alpha, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.opacity = alpha;
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].opacity = alpha;
            }
          } else {
            child.material.opacity = alpha;
          }
        }
      });
    }


    const handleClickHotspot = (hotspot) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_HOTSPOT, {
          hotspot,
        });
      }

      switch (hotspot.link_type) {
        case HOTSPOT_TYPES.FUTURE_ITEM:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT_DETAIL:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.AMENITY:
          dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
          break;
        case HOTSPOT_TYPES.LOCATION_PAGE:
          dispatch(reqSetPage(PAGES.LOCATION_PAGE));
          break;
        case HOTSPOT_TYPES.UNIT_EXPLORE:
          setActiveObjectIds([])
          dispatch(reqSetIsShowFilter(true));
          dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
          clearFilterUnit();
          break;
        case HOTSPOT_TYPES.MODAL:
          if (hotspot.link) {
            if (refCurrentHotspotActive.current === hotspot?.id) {
              dispatch(reqSetIsShowExploreModal(false));
              refCurrentHotspotActive.current = 0;
              setSelectedHotspotId(0)
            } else {
              dispatch(reqSetExploreModal(hotspot.link));
              dispatch(reqSetIsShowExploreModal(true));
              refCurrentHotspotActive.current = hotspot.id;
              setSelectedHotspotId(hotspot.id)
            }
          }
        default:
          break;
      }

      switch (hotspot.layer) {
        case LAYERS.D_HOTSPOT:
          controls.current.hideLayer(LAYERS.D_HOTSPOT);
          controls.current.showAndEnableLayer(LAYERS.EXPLORE_DISTRICT_HOTSPOT);
        default:
          break;
      }

      if (hotspot.parent_id) {
        return;
      }

      const hotspot3D = hotspot3Ds.current.find((hotspot3D) => hotspot3D?.userData.id === hotspot.id);
      updateHotspot();

      if (hotspot3D && hotspot3D.material) {
        hotspot3D.material.map = hotspot.activeTexture && refCurrentHotspotActive.current === hotspot.id ? hotspot.activeTexture : hotspot.texture;
      }
      selectedHotspot3D = hotspot3D;
      selectedHotspot = hotspot;
      if (refCurrentHotspotActive.current !== 0) {
        refCurrentHotspotActive.current = hotspot.id;
      }
    };

    const Hotspot = React.memo((props) => {
      const onPointerOver = () =>
        controls.current && controls.current.setCursorStyle("pointer");
      const onPointerOut = () =>
        controls.current && controls.current.setCursorStyle("grab");
      const webglHotspots = hotspots.map((hotspot) => {
        const imagePath = hotspot.image_path?.[i18n.language] || hotspot.image_path;
        hotspot.texture = useLoader(
          THREE.TextureLoader,
          getMediaUrl(imagePath)
        );
        if (hotspot.active_image_path) {
          hotspot.activeTexture = useLoader(
            THREE.TextureLoader,
            getMediaUrl(hotspot.active_image_path)
          );
        }
        return hotspot;
      });
      const { selectedHotspotId } = props;
      if (!isPresentation) {
        hotspot3Ds.current = [];
      }
      // hotspot3Ds.current = [];
      hotspotHasChildren = {};
      return (
        <group>
          {webglHotspots.map((hotspot, index) => {
            threePosition2(hotspot.position, position);

            let isVisible = true;
            let isSubHotspot = hotspot.parent_id != null;
            if (isSubHotspot) {
              isVisible = hotspot.parent_id == selectedHotspotId;
              hotspotHasChildren[hotspot.parent_id] = true;
            }

            const hasLine = hotspot.line_position;
            if (hasLine && !hotspot.lineGeometry) {
              hotspot.lineGeometry = new THREE.BufferGeometry().setFromPoints([
                new THREE.Vector3(position.x, position.y, position.z),
                new THREE.Vector3().copy(hotspot.line_position),
              ]);
            }

            return (
              <React.Fragment key={`hotspot-${hotspot?.id}`}>
                {hasLine && (
                  <line
                    geometry={hotspot.lineGeometry}
                    visible={isVisible}
                    layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  >
                    <lineBasicMaterial color={"white"} />
                  </line>
                )}
                <sprite
                  ref={(r) => {
                    if (r && r.material) {
                      r.material.map.minFilter =
                        THREE.LinearMipMapNearestFilter;
                      r.material.map.magFilter = THREE.LinearFilter;
                      r.material.precision = "highp";
                      r.material.map.needsUpdate = true;
                      r.renderOrder = 1;
                    }
                    if (isPresentation) {
                      if (hotspot3Ds.current.length <= webglHotspots.length) {
                        hotspot3Ds.current.push(r);
                      }
                    } else {
                      hotspot3Ds.current.push(r);
                    }
                  }}
                  visible={isVisible}
                  layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  onPointerOver={() => onPointerOver()}
                  onPointerOut={() => onPointerOut()}
                  userData={hotspot}
                  onPointerDown={() => {
                    hotspotPointerDownId = hotspot.id;
                  }}
                  onPointerUp={(e) => {
                    if (hotspotPointerDownId == hotspot.id) {
                      e.stopPropagation();
                      handleClickHotspot(e.object.userData);
                    }

                    hotspotPointerDownId = null;
                  }}
                  key={index}
                  position={[position.x, position.y, position.z]}
                  scale={[
                    hotspot?.scale?.x || 1,
                    hotspot?.scale?.y || 1,
                    hotspot?.scale?.z || 1,
                  ]}
                >
                  <spriteMaterial
                    sizeAttenuation={false}
                    fog={false}
                    precision="highp"
                    attach="material"
                    map={(selectedHotspotId === hotspot.id && hotspot.activeTexture) ? hotspot.activeTexture : hotspot.texture}
                  />
                </sprite>
              </React.Fragment>
            );
          })}
        </group>
      );
    });
    Hotspot.displayName = "Hotspot";

    const RenderInstance = (instance, model) => {
      let isClickable = true;

      let use_color =
        instance.type == OBJECT_TYPES.DO || instance.type == OBJECT_TYPES.FD;
      let use_texture = instance.use_texture;
      let isActive = activeObjectIds.includes(instance.id);

      model.children.map((mesh_, index) => {
        if (mesh_?.material?.color != null && !use_color) {
          let hexString = mesh_.material.color.getHexString();
          Object.assign(instance, { color: `#${hexString}` });
        }

        const userData = {
          alpha: instance.alpha != null ? instance.alpha / 100.0 : 1.0,
          hover_alpha:
            instance.hover_alpha != null ? instance.hover_alpha / 100.0 : 1,
          active_alpha:
            instance.active_alpha != null ? instance.active_alpha / 100.0 : 1.0,
          color: instance.color ?? "#999999",
          hover_color: instance.hover_color ?? instance.color,
          active_color: instance.active_color ?? instance.color,
          isActive: isActive,
          layer: instance.layer,
          activeEmissive: instance.activeEmissive,
          activeEmissiveIntensity: instance.activeEmissiveIntensity
        };

        Object.assign(mesh_, { userData: userData, name: instance.id });
      });

      return model.children.map((mesh_, index) => {
        let isTransparency =
          (instance.alpha != null && instance.alpha <= 80.0) ||
          (instance.hover_alpha != null && instance.hover_alpha <= 80.0) ||
          (instance.active_alpha != null && instance.active_alpha <= 80.0);
        if (isActive) {
          setColor(mesh_.userData.active_color, mesh_);
          isTransparency && setAlpha(mesh_.userData.active_alpha, mesh_);
        } else {
          if (!use_texture || use_color) {
            setColor(mesh_.userData.color, mesh_);
          }
          if (!use_texture || isTransparency) {
            setAlpha(mesh_.userData.alpha, mesh_);
          }
        }

        const onPointerOver =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("pointer");
              instance.hover_color &&
                setColor(mesh_.userData.hover_color, mesh_);
              animateAlpha(mesh_.userData.hover_alpha, mesh_);
              mesh_.userData.isHover = true;
            }
            : null;

        const onPointerOut =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("grab");
              if (mesh_.userData.isHover) {
                setColor(mesh_.userData.color, mesh_);
                animateAlpha(mesh_.userData.alpha, mesh_);
                mesh_.userData.isHover = false;
              }
            }
            : null;

        const onPointerDown = (e) => {
          e.stopPropagation();
          pointerDownId = instance.id;
        };

        const onPointerUp = () => {
          pointerDownId == instance.id && onClick != null && onClick();
          pointerDownId = null;
        };

        const onClick = isClickable
          ? async () => {
            setActiveObjectIds([instance.id]);
            dispatch(reqSetActivePrecinctID(null));

            if (instance.cam_position) {
              const camPosition = threePosition(instance.cam_position);
              const camLookAtPosition =
                instance.cam_focus_point_position != null
                  ? threePosition(instance.cam_focus_point_position)
                  : position;
              handleAreaClick(controls, camLookAtPosition, camPosition);
            }
            if (instance?.modal) {
              dispatch(reqSetIsShowExploreModal(true));
              dispatch(reqSetExploreModal(instance?.modal));
            }
            if (instance?.sub_precinct) {
              dispatch(reqSetActiveTransportOption([instance?.sub_precinct]));
            } else {
              dispatch(reqSetActiveTransportOption([]));
            }
          }
          : null;

        let meshInstance = (
          <mesh
            key={index}
            {...mesh_}
            layers={instance.layer != null ? instance.layer : null}
            userData={mesh_.userData}
            name={instance.id}
            onPointerDown={instance.type && onPointerDown}
            onPointerUp={instance.type && onPointerUp}
            onPointerOut={instance.type && onPointerOut}
            onPointerOver={instance.type && onPointerOver}
          />
        );
        return meshInstance;
      });
    };

    function FbxModel() {
      if (!isIntroduction) {
        return <group />;
      }

      if (light.current != null) {
        light.current.layers.enableAll();
      }

      return (
        <group ref={refScene}>
          {Object.keys(meshInstanceMap).map((entry) => {
            const targetMap = meshInstanceMap[entry];
            if (!targetMap) return;
            const model = targetMap.model;
            const instances = targetMap.instances;
            return instances.map((instance) => {
              return RenderInstance(instance, model);
            });
          })}
        </group>
      );
    }

    const AnimationCamera = React.memo((props) => {
      const { animation3dSetting, controls } = props;
      const { camera } = useThree();

      new THREE.Vector3(
        -102.89578369966134,
        -1.1178292546754195e-14,
        131.5388245709879
      );
      const targetPosition =
        animation3dSetting != null && animation3dSetting.cam_position != null
          ? threePosition(animation3dSetting.cam_position)
          : new THREE.Vector3(
            -92.46747002504912,
            260.2837561175679,
            391.6135906913746
          );
      const delta = new Vector3(
        -200 - targetPosition.x,
        270 - targetPosition.y,
        -630 - targetPosition.z
      );

      const pipeSpline = new THREE.CatmullRomCurve3([
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z),
        new THREE.Vector3(200 - delta.x, 330 - delta.y, -226 - delta.z),
        new THREE.Vector3(0 - delta.x, 285 - delta.y, -331 - delta.z),
        targetPosition
      ]);

      setCameraAnimated(true);

      camera.position.copy(
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z)
      );
      camera.updateProjectionMatrix();

      timeVector3.x = 0;
      timeVector3.y = 0;
      timeVector3.z = 0;

      return <group />;
    });
    AnimationCamera.displayName = "AnimationCamera";

    const CameraControls = React.memo(() => {
      const { camera, gl, raycaster } = useThree();
      const domElement = gl.domElement;

      gl.localClippingEnabled = true;
      gl.info.autoReset = false;
      // Set max canvas resolution to 1080p without forcing container style updates
      useThree().gl.setSize(
        Math.min(window.innerWidth, 1280),
        Math.min(window.innerHeight, 720),
        false
      );

      if (isIntroduction) {
        glS = new glStats(); // init at any point
        tS = new threeStats(gl); // init after WebGLRenderer is created

        rS = new rStats({
          userTimingAPI: true,
          values: {
            frame: { caption: "Total frame time (ms)", over: 16 },
            fps: { caption: "Framerate (FPS)", below: 30 },
            calls: { caption: "Calls (three.js)", over: 3000 },
            raf: { caption: "Time since last rAF (ms)" },
            rstats: { caption: "rStats update (ms)" }
          },
          groups: [
            { caption: "Framerate", values: ["fps", "raf"] },
            {
              caption: "Frame Budget",
              values: ["frame", "texture", "setup", "render"]
            },
          ],
          fractions: [{ base: "frame", steps: ["action1", "render"] }],
          plugins: [tS, glS]
        });

        useFrame(({ gl, scene, camera }) => {
          rS("frame").start();
          glS.start();

          rS("frame").start();
          rS("rAF").tick();
          rS("FPS").frame();

          rS("action1").start();
          rS("action1").end();

          rS("render").start();
          gl.render(scene, camera);
          rS("render").end();

          rS("frame").end();
          rS().update();

          gl.info.reset();
        }, 1);
      }

      useFrame(() => {
        if (controls.current?.needReloadSelectedHotspotId) {
          // selectedHotspotId = "";
          setSelectedHotspotId("")
          updateHotspot();
          controls.current.needReloadSelectedHotspotId = false;
        }

        sendCameraPos();

        if (!isCameraAnimation && isCameraAnimated) {
          if (controls != null && controls.current != null && !isPresentation) {
            controls.current.update();
          }
          return;
        }
      });

      let x = targetPosition.x;
      let y = targetPosition.y;
      let z = targetPosition.z;

      if (camera.targetPosition) {
        x = camera.targetPosition.x;
        y = camera.targetPosition.y;
        z = camera.targetPosition.z;
      }

      return (
        <orbitCustomControls
          ref={(r) => {
            controls.current = r;
            //FirstAction();
          }}
          args={[camera, domElement, [0, 0, 0], [0, 0, 0]]}
          raycaster={raycaster}
          disabledUpdate={isIntroduction && !isCameraAnimated}
          neverUpdate={false}
          autoRotate={false}
          enableDamping={true}
          maxDistance={1000}
          minDistance={2}
          zoomSpeed={2}
          rotateSpeed={0.8}
          minZoom={_3dSetting.minZoom ?? 0.2}
          maxZoom={_3dSetting.maxZoom ?? 8}
          minHeight={10}
          maxHeight={1000}
          movingCurveSpeed={1 ?? 0.5}
          target={[x, y, z]}
        />
      );
    });
    CameraControls.displayName = "CameraControls";

    return (
      <>
        {authUser?.webGLArtist && (
          <div
            ref={guiContainerRef}
            style={{
              position: "absolute",
              right: "0%", // Position the left edge of the div in the center of the screen
              bottom: "50%", // Shift the div leftwards by half of its width
              zIndex: 1
            }}
          />
        )}
        <Canvas
          style={{ backgroundColor: "#000000" }}
          gl={{
            outputEncoding: THREE.sRGBEncoding,
            logarithmicDepthBuffer: true,
            outputEncoding: THREE.sRGBEncoding
          }}
          pixelRatio={Math.max(window.devicePixelRatio, 2)}
          camera={{
            position: [
              1020 + _3dSetting.cam_position.x,
              540 + _3dSetting.cam_position.z,
              630 - _3dSetting.cam_position.y
            ],
            fov: _3dSetting.FOV,
            near: 1,
            far: 10000
          }}
          onPointerEnter={(e) => (pointerDown = true)}
          onPointerLeave={(e) => (pointerDown = false)}
          onTouchStart={(e) => (pointerDown = true)}
          onTouchEnd={(e) => (pointerDown = false)}
          onWheel={() => sendCameraPos()}
        >
          {isIntroduction && !isCameraAnimated && !isPresentation && (
            <AnimationCamera
              animation3dSetting={_3dSetting}
              controls={controls}
            />
          )}
          <ambientLight
            intensity={ambientLight.intensity}
            color={ambientLight.color}
          />
          <React.Suspense fallback={null}>
            <FbxModel />
            <Hotspot selectedHotspotId={selectedHotspotId} />
          </React.Suspense>
          {<CameraControls />}
        </Canvas>
      </>
    );
  })
);

CanvasBox.displayName = "CanvasBox";

export default CanvasBox;
