import * as type from './type';

const initialState = {
  isShowUserTypeModal: false,
  isShowListInvestorModal: false,
  isShowListConsultantModal: false,
  isShowCreateInvestorProfileModal: false,
  isShowCreateTenantProfileModal: false,
  isShowCreateConsultantProfileModal: false,
  isShowListTenantModal: false,
  userSelectType: '',
  isShowPostSessionPopup: false,
  isShowLanguageModal: false,
  isVideoMuted: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_IS_SHOW_POST_SESSION_POPUP:
      return {
        ...state,
        isShowPostSessionPopup: action.data,
      };
    case type.SET_IS_SHOW_LIST_CONSULTANT_MODAL:
      return {
        ...state,
        isShowListConsultantModal: action.data,
      };
    case type.SET_IS_SHOW_LANGUAGE_MODAL:
      return {
        ...state,
        isShowLanguageModal: action.data,
      };
    case type.SET_IS_SHOW_USER_TYPE_MODAL:
      return {
        ...state,
        isShowUserTypeModal: action.data,
      };
    case type.SET_IS_SHOW_LIST_INVESTOR_MODAL:
      return {
        ...state,
        isShowListInvestorModal: action.data,
      };
    case type.SET_IS_SHOW_LIST_TENANT_MODAL:
      return {
        ...state,
        isShowListTenantModal: action.data,
      };
    case type.SET_USER_SELECT_TYPE:
      return {
        ...state,
        userSelectType: action.data,
      };
    case type.SET_IS_SHOW_CREATE_INVESTOR_PROFILE_MODAL:
      return {
        ...state,
        isShowCreateInvestorProfileModal: action.data,
      };
    case type.SET_IS_SHOW_CREATE_TENANT_PROFILE_MODAL:
      return {
        ...state,
        isShowCreateTenantProfileModal: action.data,
      };
    case type.SET_IS_SHOW_CREATE_CONSULTANT_PROFILE_MODAL:
      return {
        ...state,
        isShowCreateConsultantProfileModal: action.data,
      };
    case type.SET_VIDEO_MUTED:
      return {
        ...state,
        isVideoMuted: action.data,
      };
    default:
      return state;
  }
};
