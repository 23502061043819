import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import ServicesModal from "./component/services-modal";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { ChevronLeft } from "../svgs/icons";
import imageAmenities1 from "../../assets/images/image-amenities-1.png";
import imageAmenities2 from "../../assets/images/image-amenities-2.png";
import imageAmenities3 from "../../assets/images/image-amenities-3.png";
import imageAmenities5 from "../../assets/images/image-amenities-5.jpg";
import imageAmenities6 from "../../assets/images/image-amenities-6.png";
import imageAmenities7 from "../../assets/images/image-amenities-7.png";
import { useQuery } from "../../helper/use-query";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import amenityApi from '../../apis/api/amenities';

const AmenitiesV2 = ({isPresentation}) => {
  const { t } = useTranslation();
  const [isShowServicesModal, setShowServicesModal] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const query = useQuery();

  const pageRef = useRef(null);

  useEffect(async () => {
    getAmenitiesList();
  }, []);

  const getAmenitiesList = async () => {
    try {
      const res = await amenityApi.getAmenitiesList();
      setAmenities(res.data);
    } catch(err) {
    }
  }

  const formatedAmenities = useMemo(() => {
    return amenities.map((el) => {
      if (el && el.description) {
        return {
          ...el,
          description: el.description?.toString().split('\n') || []
        }
      }
      return el;
    });
  }, [amenities]);
  
  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            const ratio = content.data.scrollHeight / pageRef.current.scrollHeight;
            const passedHalf = pageRef.current.scrollTop > pageRef.current.scrollHeight / 2
            pageRef.current.scrollTop = content.data.scrollTop * ratio + (passedHalf ? 500 * ratio : 0);
            // pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      })
    }
  }, [isPresentation])

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        // scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
        scrollTop: event.currentTarget.scrollTop,
        scrollHeight: event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf amenities-page ${isPresentation && "presentation"}`}>
      <TopNavTitle title={t("AMENITIES")} />
      <div className="main-content">
        <div className="content-1">
          {!!query.has("goBack") && (
            <a href="#" className="aw-link">
              <ChevronLeft
                style={{ height: 15, margin: "auto" }}
                fill={"#6B8FA3"}
              />
              <span style={{ marginLeft: 10, marginTop: 4 }}>
                {t("AmenitiesV2.goBack")}
              </span>
            </a>
          )}
          <div className="header">{t("AmenitiesV2.header")}</div>
        </div>
        <div className="content-2">
          <div className="ct-image-1">
            <img
              className="ct-img"
              alt="imageAmenities1"
              src={imageAmenities1}
            />
          </div>
          <div className="residence-info">
            <div className="residence-header">
              <div>{t("AmenitiesV2.residenceHeader")}</div>
            </div>
            <div className="residence-para">{t("AmenitiesV2.residencePara")}</div>
            <div className="residence-para">{t("AmenitiesV2.residencePara2")}</div>
          </div>
        </div>
        <div className="content-3">
          <div className="dining-destinations">
            <div className="dining-destinations-header">
              <div>{t("AmenitiesV2.diningDestinationsHeader")}</div>
            </div>
            <div className="dining-destinations-para">{t("AmenitiesV2.diningDestinationsPara1")}</div>
            <div className="dining-destinations-para">{t("AmenitiesV2.diningDestinationsPara2")}</div>
            <img 
              className="ct-img-1"
              alt="imageAmenities"
              src={imageAmenities3}
            />
          </div>
          <div className="ct-image-3">
            <img
              className="ct-img"
              alt="imageAmenities3"
              src={imageAmenities2}
            />
          </div>
        </div>
        <div className="content-2 blue-bg">
            <div className="ct-image-1">
              <img
                className="ct-img"
                alt="imageAmenities6"
                src={imageAmenities6}
              />
            </div>
            <div className="residence-info">
              <div className="residence-header health-header">
                  <div>{t("AmenitiesV2.infoHeader")}</div>
              </div>
              <div className="residence-para">{t("AmenitiesV2.infoPara1")}</div>
              <div className="residence-para">{t("AmenitiesV2.infoPara2")}</div>
              <div className="residence-para">{t("AmenitiesV2.infoPara2_1")}</div>

            </div>
        </div>
        <div className="content-5">
          {/* <img
            className="ct-img"
            alt="imageAmenities5"
            src={imageAmenities5}
          /> */}
        </div>
        <div className="content-2">
          <div className="ct-image-1">
            <img
              className="ct-img"
              alt="imageAmenities4"
              src={imageAmenities7}
            />
          </div>
          <div className="residence-info">
              <div className="residence-header">
              <div>{t("AmenitiesV2.infoHeader2")}</div>
            </div>
            <div className="residence-para">{t("AmenitiesV2.infoPara3")}</div>
            <div className="residence-para">{t("AmenitiesV2.infoPara3_1")}</div>
          </div>
        </div>
      </div>
      {
        !isPresentation && <div className="bottom-top" />
      }
      <ServicesModal
        isShowServicesModal={isShowServicesModal}
        onHide={() => setShowServicesModal(false)}
      />
    </div>
  );
};

export default AmenitiesV2;
