import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { useTranslation } from "react-i18next";

const MultiRangeSlider = ({ min, max, onChange, currentMin, currentMax }) => {
  const range = useRef(null);
  const { t } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(true);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(currentMin);
    const maxPercent = getPercent(currentMax);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [currentMin, currentMax, getPercent]);

  return (
    <div className="multi-range-slider-container">
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{t("MIN")}</div>
        <div className="slider__right-value">{t("MAX")}</div>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        value={Number(currentMin)}
        onChange={(event) => {
          if (firstLoad) {
            setFirstLoad(false);
          }
          const value = Math.min(Number(event.target.value), currentMax - 1);
          onChange({ min: value, max: currentMax });
        }}
        className="thumb thumb--left"
        style={{ zIndex: currentMin > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={Number(currentMax)}
        onChange={(event) => {
          if (firstLoad) {
            setFirstLoad(false);
          }
          const value = Math.max(Number(event.target.value), currentMin + 1);
          onChange({ min: currentMin, max: value });
        }}
        className="thumb thumb--right"
      />
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
