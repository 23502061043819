import React from 'react';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './index.scss';

const LeftNav = () => {
  const { url } = useRouteMatch();
  const [activeMenu, setActiveMenu] = useState(`${url}/customers`);
  const getActiveClass = (path) => (activeMenu === path ? 'active' : '');

  return (
    <div className="wrap-left-nav">
      <div className="card">
        <ul className="list-group list-group-flush">
          <li
            className={`list-group-item ${getActiveClass(`${url}/customers`)}`}
            onClick={() => setActiveMenu(`${url}/customers`)}
          >
            <Link to={`${url}/customers`}>CUSTOMERS</Link>
          </li>
          <li
            className={`list-group-item ${getActiveClass(`${url}/sessions`)}`}
            onClick={() => setActiveMenu(`${url}/sessions`)}
          >
            <Link to={`${url}/sessions`}>SESSIONS</Link>
          </li>
          <li
            className={`list-group-item ${getActiveClass(`${url}/inventory`)}`}
            onClick={() => setActiveMenu(`${url}/inventory`)}
          >
            <Link to={`${url}/inventory`}>INVENTORY</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LeftNav;
