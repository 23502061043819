import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import Select from 'react-select';
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from '../../../apis/api/customer';
import { CountryOptions, getSafeCountryCallingCode } from "../../../components/phone-input/phone-input";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Please enter a valid email").required("Email is a required field"),
	mobile: yup.string().trim().min(8).required("Phone number is a required field"),
	countryCode: yup.string().trim().required("Country code is a required field"),
});

const AddCustomerModal = ({ show, setShow, onCreateSuccess }) => {
	const {t, i18n} = useTranslation();
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [address, setAddress] = useState('');
	const [countryCode, setCountryCode] = useState('');
  	const [errors, setErrors] = useState({});

	useEffect(() => {
		setCountryCode("az");
	}, [])

	const handleAddNewCustomer = async () => {		
		try {
			const data = {
				userGroup: "610a4a3351400773f94bfa89", //tenant
				firstname,
				surname,
				email,
				mobile,
				address,
				countryCode,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.createCustomer(result);
			
			if (res.data) {
				toast.success(t("Customer created successfully!")); 
				handleClose && handleClose();
				onCreateSuccess && onCreateSuccess();
			} else {
				toast.error(res.message)
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal
			className="wrap-create-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{t("CREATE NEW CUSTOMER")}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("First Name")}*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder={t("First Name")}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<span className="error">{t(errors?.firstname)}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("Last Name")}*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder={t("Last Name")}
							onChange={(e) => setSurName(e.target.value)}
						/>
						<span className="error">{t(errors?.surname)}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Address")}</Form.Label>
						<FormControl
							id="address"
							name="address"
							type="input"
							value={address}
							placeholder={t("Address")}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</Form.Group>
				</div>				
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Phone Number")}*</Form.Label>
						<div className="phone">
							<div className="phone-input">
								<div className="flag">
									<div className={`country ${countryCode || 'hidden'}`} />
								</div>
							</div>
							<Select
								className="mobile-code"
								classNamePrefix="select"
								value={{ value: countryCode, label: `+${+getSafeCountryCallingCode(countryCode)}`}}
								options={CountryOptions(i18n.language)}
								name="countryCode"
								isSearchable={false}
								menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
								styles={{
									menuPortal: base => ({...base, zIndex: 9999})
								}}
								onChange={(item) => setCountryCode(item?.value)}
							/>
							<FormControl
								className="mobile-input"
								id="mobile"
								name="mobile"
								type="input"
								value={mobile}
								placeholder={t("Phone")}
								onChange={(e) => setMobile(e.target.value)}
							/>
						</div>
						<span className="error">{t(errors?.countryCode || errors.mobile)}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>{t("Email")}*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder={t("Email")}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="error">{t(errors?.email)}</span>
					</Form.Group>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="finish" onClick={handleAddNewCustomer}>{t("FINISH")}</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCustomerModal;
