import React, {useRef, useState} from 'react';
import * as PANOLENS from 'panolens';
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { WEBSOCKET_CHANNEL } from '../../constants/options';
import { ImageLoader } from 'three';

const Panorama = (props) => {
  const {  names, images } = props;
  const authUser = useSelector((state) => state.user.data);
  const isLoading = true;
  const refCurrentIndex = useRef(0);
  const btnDay = document.getElementById("btnDay");
  const btnNight = document.getElementById("btnNight");

  let currentIndex = 0;

  if (authUser == null || authUser == undefined) {
    return (
      <>
        <div id="wrap-panorama" />
      </>
    );
  }

  let viewer;

  let panos = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined];

  const loader = new ImageLoader();

  const onInitPanorama = () => {
    panos = [];
    const length = images.length;
    if (length <= 0) {
      return;
    }
    for (let index = 0; index < length; index++) {
      panos.push(undefined);
    }

    const image1stURL = images[0];

    loader.load(image1stURL, function ( image ) {

      let pano = new PANOLENS.ImagePanorama(image);

      panos[0] = pano;
      viewer.add(pano);
      viewer.setPanorama(pano);

      for (let index = 1; index < images.length; index++) {
        const imageURL = images[index];

        loader.load(imageURL, function ( image ) {
          let pano = new PANOLENS.ImagePanorama(image);

          panos[index] = pano;
          viewer.add(pano);

        }, undefined, function (error) {
          console.error( 'An error happened.' , error);
        });
      };

    }, undefined, function (error) {
      console.error( 'An error happened.' , error);
    });

    viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama-view")
    });
    let cameraPos = {};

    if (viewer.camera != null && authUser != null) {
      viewer.camera.userData.id = authUser.id;
    }
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    let control = viewer.getControl();
    control.noZoom = true;
    viewer.onChange = () => {
      viewer.update();
      viewer.render();

      let position = {
        x: viewer.camera.position.x,
        y: viewer.camera.position.y,
        z: viewer.camera.position.z
      };

      let quaternion = {
        x: viewer.camera.quaternion.x,
        y: viewer.camera.quaternion.y,
        z: viewer.camera.quaternion.z,
        w: viewer.camera.quaternion.w,
      }
      let content = {
        position: position,
        quaternion: quaternion,
        zoom: viewer.camera.zoom,
        fov: viewer.camera.fov,
        authUserId: viewer.camera.userData.id,
        index: currentIndex
      };
      if (cameraPos !== JSON.stringify(content)) {
        cameraPos = JSON.stringify(content);
      }
    }
  }

  onInitPanorama();


  const renderListPanorama = () => {
    let i = 0;
    const loading = () => {
      if (i == 0) {
        i = 1;
        let bar = document.getElementById('myBar');
        let progress = document.getElementById('myProgress');
        bar.style.display = 'block';
        progress.style.display = 'block';
        let width = 1;
        let id = setInterval(frame, 10);
        function frame() {
          if (width >= 100) {
            bar.style.display = 'none';
            progress.style.display = 'none';
            clearInterval(id);
            i = 0;
          } else {
            width++;
            bar.style.width = width + '%';
          }
        }
      }
    };
    const select = () => {
      let item = document.getElementsByClassName('item-name');
      for (let i = 0; i < item.length; i++) {
        item[i].addEventListener('click', function () {
          let current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active', '');
          this.className += ' active';
        });
      }
    }
    return (
      <div className="panorama-side-bar">
        <div className="list-panorama">
          {names.map((item, index) => (
            <div key={index} className="list-panorama-item">
              <span
                id={`e${index}`}
                className={`item-name ${index === 0 ? 'active' : ''}`}
                onClick={() => {
                  loading();
                  select();
                  refCurrentIndex.current = index;
                  if (panos[index] == undefined) {
                    return;
                  }
                  changeDayNight(true)
                }}
              >
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const changeDayNight = (val) => {
    if(val){
      btnDay.classList.add("isActive");
      btnNight.classList.remove("isActive");
      viewer.setPanorama(panos[refCurrentIndex.current]);
    }else {
      btnDay.classList.remove("isActive");
      btnNight.classList.add("isActive");
      viewer.setPanorama(panos[refCurrentIndex.current + 8]);
    }
  }

  const renderBtnDayNight = () => {
    return(
      <div className='day-night'>
        <button id='btnDay' className="isActive" onClick={() => changeDayNight(true)}>
          <span>
              Day
          </span>
        </button>
        <button id='btnNight' onClick={() => changeDayNight(false)}>
          <span>
              Night
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      {isLoading &&
        <div className="position-absolute top-50 start-50 translate-middle intro-content pt-3">
          <div className='loading-virtual'>
            <span>
              VIRTUAL TOUR LOADING ...
            </span>
          </div>
        </div>
      }
      <div id="wrap-panorama-view" />
      {renderBtnDayNight()}
      {renderListPanorama()}
      <div id="myProgress">
        <div id="myBar" />
      </div>
    </>
  );
};

export default Panorama;
