import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUploadedMediaUrl, getUploadedServerMediaUrl } from "../../helper/media";
import { ACTION_NAME, GALLERY_TYPE, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useDispatch } from "react-redux";
import {
  reqSetActivePatourId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import socket from "../../helper/socket";
import { reqGetListGalleries } from '../../reduxs/cms/action';

const Gallery = ({isPresentation}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const galleries = useSelector((state) => state.cms.galleries);
  const media = galleries?.map((gallery) => gallery.media)?.flat();
  const galleryRef = useRef();
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    afterChange: (index) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }

      // Display limited dots
      const activeDotEl = document.querySelector('.slick-dots li.slick-active');
      activeDotEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    },
  };
  const isTransparent = useSelector((state) => state.home.isTransparent);

  const HOTSPOT_TYPES = {
    PANO_TOUR: "360_pano_tour",
    URL: "url",
    VIDEO: "video",
  };

  const getListGalleries = () => {
    dispatch(reqGetListGalleries({
      "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
      sortBy: JSON.stringify({
        name: 1,
      }),
    }));
  }

  useEffect(() => {
    getListGalleries();
  }, []);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          enableTransparent();
        }
        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          showFilter();
        }
        if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
          onGoToSlideIndex(content.data.index);
        }
        if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
          hideGallery()
        }
      })
    }
  }, [isPresentation]);

  const handleClickHotspot = async (data) => {
    const hotpotData = data;

    if (hotpotData.link_type == HOTSPOT_TYPES.PANO_TOUR) {
      dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
      dispatch(reqSetActivePatourId(hotpotData.link));
    }
  };

  const showFilter = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideGallery = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY)
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(true))
  }

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  return (
    <>
      <div className="wrap-gallery-image">
        <Slider ref={galleryRef} {...settings}>
          {(media || []).map((item, key) => {
            return (
              <div key={key}>
                <div  style={{height: '100svh'}}>
                <div
                  className={`gallery-item ${!isTransparent ? 'with-panel' : ''}`}
                  style={{
                    backgroundImage: `url(${getUploadedServerMediaUrl(item.path)})`,
                  }}
                >
                  {(item.hotspots || []).map((item, index) => {
                    if (!item) {
                      return null;
                    }
                    return (
                      <img
                        onClick={() => handleClickHotspot(item)}
                        key={index}
                        style={{
                          left: `${item.position?.x}%`,
                          top: `${item.position?.y}%`,
                        }}
                        className="gallery-hotspot"
                        src={getUploadedMediaUrl(item.image_path)}
                      />
                    );
                  })}
                </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <span className={`btn-gallery prev-slide ${!isTransparent ? 'with-panel' : ''}`}>
          <img
            width="50"
            height="50"
            src="/icons/left-arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickPrev()}
          />
        </span>
        <span className={`btn-gallery next-slide ${!isTransparent ? 'with-panel' : ''}`}>
          <img
            width="50"
            height="50"
            src="/icons/right-arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickNext()}
          />
        </span>
      </div>
      {!isTransparent && isShowGallery && (
          <div className="wrap-close-btn">
            <div onClick={enableTransparent} className="reopen">
              <span>{t("CLOSE PANELS")}</span>
              <img className="rotate-img" src="/icons/arrow.svg" alt="" />
            </div>
        </div>
      )}
      {isTransparent && isShowGallery && (
        <>
          {/* <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={hideGallery} />
          </div> */}
          <div className="wrap-close-btn elementToFadeInAndOut">
            <div onClick={hideGallery} className="close">
              <img src="/icons/arrow.svg" alt="" />
              <span>{t("CLOSE GALLERY")}</span>
            </div>
            <div onClick={showFilter} className="reopen">
              <span>{t("REOPEN PANELS")}</span>
              <img className="rotate-img" src="/icons/arrow.svg" alt="" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Gallery;
