import React, { useState, useEffect, useRef } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import closeIcon from "../../../assets/images/close-white.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import mediaAPI from "../../../apis/api/media";
import galleryApi from "../../../apis/api/gallery";
import { getUploadedServerMediaUrl } from "../../../helper/media";
import { CONTENT_TYPE, ContentSectionOptions, MEDIA_TYPE } from ".";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GALLERY_TYPE } from "../../../constants/options";

const validationSchema = yup.object().shape({
  order: yup.number().required("Order is a required field"),
  contentTitle: yup.string().trim().required("Content title is a required field"),
  contentSection: yup.string().trim().required("Content section is a required field"),
});

const floorplanYupObj = {
  contentTitle: yup.string().trim().required("Content title is a required field"),
  contentSection: yup.string().trim().required("Content section is a required field"),
  unitId: yup.string().trim().required("Associated Residence is a required field"),
};
const floorplanValidationSchema = yup.object().shape(floorplanYupObj);

const EditContentModal = ({
  content,
  thumbnailContent,
  gallery,
  show,
  setShow,
  onSaveSuccess,
  contentType,
  mediaType,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [order, setOrder] = useState(content?.order || "");
  const [contentTitle, setContentTitle] = useState(content?.name || "");
  const [contentSection, setContentSection] = useState(
    ContentSectionOptions.find((v) => v.label === contentType)?.label
  );
  const [isShowDeleteContentModal, setIsShowDeleteContentModal] = useState(false);
  const [isShowReplaceContentModal, setIsShowReplaceContentModal] = useState(false);
  const [replaceContentType, setReplaceContentType] = useState("");
  const [errors, setErrors] = useState({});
  const [unitId, setUnitId] = useState(gallery?.unitId);
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);
  const units = useSelector((state) => state.cms.units);
  const fileRef = useRef();
  const thumbnailRef = useRef();

  useEffect(() => {
    if (!unitId) {
      return;
    }
    const unit = units?.find((unit) => unit.id === unitId);

    if (unit?.floorPlan?.id && unit.floorPlan.id !== gallery.id) {
      setErrors({unitId: "Associated Residence already had a floorplan"})
      return;
    }
    
    setErrors({unitId: undefined});
  }, [unitId]);

  // useEffect(() => {
  //   if (content) {
  //     setOrder(content?.order);
  //   }
  // }, [content]);

  useEffect(() => {
    if (file) {
      setContentTitle(file.name);
    }
  }, [file]);

  const handleUpdateContent = () => {
    if (!enableBtnSubmit) {
      toast.info(t("Please wait, media file uploading!"));
      return;
    }
    switch (contentSection) {
      case CONTENT_TYPE.IMAGES:
        handleUpdateContentImage();
        return;
      case CONTENT_TYPE.FILMS:
        handleUpdateContentFilm();
        return;
      case CONTENT_TYPE.FLOORPLANS:
        handleUpdateContentFloorplan();
        return;
      default:
        return;
    }
  };

  const handleUpdateContentImage = async () => {
    if (!content) return;

    try {
      const data = {
        order,
        contentTitle,
        contentSection,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");

      if (file) {
        formData.append("file", file);
      }

      toast.info(t("Please wait, media file uploading!"));
      const updated = await mediaAPI.updateMedia(content.id, formData);
      if (updated?.data) {
        toast.success(t("Content updated successfully!"));
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleUpdateContentFilm = async () => {
    if (!content || !thumbnailContent) return;

    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        thumbnail,
      };
      setErrors({});
      const result = await validationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "video");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_videos/films");
      if (file) formData.append("file", file);

      toast.info(t("Please wait, media file uploading!"));
      const formDataThumbnail = new FormData();
      formDataThumbnail.append("type", "image");
      formDataThumbnail.append("name", `Thumbnail ${result?.contentTitle}`);
      formDataThumbnail.append("path", "media/media_videos/thumbnails");
      if (thumbnail) formDataThumbnail.append("file", thumbnail, `thumbnail_${Date.now()}`);

      const updatedContent = await mediaAPI.updateMedia(content.id, formData);
      const updatedThumbnail = await mediaAPI.updateMedia(thumbnailContent.id, formDataThumbnail);
      if (updatedContent?.data && updatedThumbnail?.data) {
        toast.success(t("Content updated successfully!"));
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleUpdateContentFloorplan = async () => {
    if (!content) return;
    if (errors.unitId === "Associated Residence already had a floorplan") {
      return;
    }

    try {
      const data = {
        unitId,
        contentTitle,
        contentSection,
      };
      setErrors({});
      const result = await floorplanValidationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/floorplans");

      if (file) {
        formData.append("file", file);
      }

      toast.info(t("Please wait, media file uploading!"));
      const updated = await mediaAPI.updateMedia(content.id, formData);
      if (updated?.data) {
        const galleryFormData = new FormData();
        galleryFormData.append("unitId", result.unitId);
        galleryFormData.append("type", GALLERY_TYPE.FLOORPLAN);
        galleryFormData.append("media[]", updated?.data?.id);

        await galleryApi.updateGallery(gallery.id, galleryFormData);
        toast.success(t("Content updated successfully!"));
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      console.log(err);
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleDeleteContent = async () => {
    if (!content) return;

    try {
      const deleted = await mediaAPI.deleteMedia(content.id);
      if (thumbnailContent) await mediaAPI.deleteMedia(thumbnailContent.id);
      if (deleted.data) {
        await galleryApi.deleteGallery(gallery?.id);

        toast.success(t("Content deleted successfully!"));
        handleOpenDeleteContentModal();
        handleClose && handleClose();
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleOpenDeleteContentModal = () => {
    setIsShowDeleteContentModal(true);
  };

  const handleOpenReplaceContentModal = (type) => () => {
    setIsShowReplaceContentModal(true);
    setReplaceContentType(type);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseDeleteContentModal = () => {
    setIsShowDeleteContentModal(false);
  };

  const handleCloseReplaceContentModal = () => {
    setIsShowReplaceContentModal(false);
    setReplaceContentType("");
  };

  const onOpenFileInput = () => {
    fileRef.current.click();
  };

  const onOpenThumbnailInput = () => {
    thumbnailRef.current.click();
  };

  const handleBrowserFile = (e) => {
    setFile(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleBrowseThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    if (content && content.path) {
      return getUploadedServerMediaUrl(content.path);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    if (thumbnailContent && thumbnailContent.path) {
      return getUploadedServerMediaUrl(thumbnailContent.path);
    }

    return "/images/image.png";
  };

  if (isShowReplaceContentModal) {
    return (
      <Modal
        className="wrap-replace-content-modal"
        show={isShowReplaceContentModal}
        onHide={handleCloseReplaceContentModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("REPLACE CONTENT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={handleCloseReplaceContentModal} />
          </div>
          <div
            className="browser-file"
            onClick={replaceContentType === "thumbnail" ? onOpenThumbnailInput : onOpenFileInput}
          >
            {t("BROWSE FILES")}
            <input
              id="file"
              type="file"
              ref={replaceContentType === "thumbnail" ? thumbnailRef : fileRef}
              className="hidden"
              accept={`${replaceContentType === "video" ? "video" : "image"}/*`}
              onChange={(e) => (replaceContentType === "thumbnail" ? handleBrowseThumbnail(e) : handleBrowserFile(e))}
            />
          </div>
          {mediaType !== MEDIA_TYPE.VIDEO &&
            <div className="import" onClick={() => { }}>
              <span className="import-title">{t("Or import from a Matterport URL")}</span>
              <div className="import-form">
                <input type="text" placeholder={t("Add a Matterport URL")} />
                <span className="import-button">{t("IMPORT")}</span>
              </div>
            </div>
          }
        </Modal.Body>
      </Modal>
    );
  }

  if (isShowDeleteContentModal) {
    return (
      <Modal
        className="wrap-delete-content-modal"
        show={isShowDeleteContentModal}
        onHide={handleCloseDeleteContentModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("DELETE CONTENT")}?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={handleCloseDeleteContentModal} />
          </div>
          <div className="confirm">
            {t("Are you sure you want to delete")} {content?.name}?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div onClick={handleDeleteContent}>{t("YES, DELETE")}</div>
        </Modal.Footer>
      </Modal>
    );
  }

  const isAbleSave = order.toString().trim().length > 0 && contentTitle?.trim().length > 0;

  return (
    <Modal className="wrap-update-content-modal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("UPDATE CONTENT")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wrap-modal-body">
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <div className="title">{t("CONTENT")}</div>
          <div div className='upload-input'>
            {mediaType === MEDIA_TYPE.IMAGE && <img className="img-fluid" src={renderImagePreview()} alt="" />}
            {mediaType === MEDIA_TYPE.VIDEO && (
              <video className="img-fluid">
                <source src={renderImagePreview()} type="video/mp4" />
              </video>
            )}
            <div onClick={handleOpenReplaceContentModal(mediaType)} className={`upload-btn ${mediaType}`}>
              <img className="upload-icon" src={uploadIcon} alt="upload-icon" />
            </div>
          </div>
        </div>
        {mediaType === MEDIA_TYPE.VIDEO && (
          <div className="content thumbnail">
            <div className="title">{t("THUMBNAIL")}</div>
            <div className='upload-input'>
              <img className="img-fluid cursor-pointer" src={renderThumbnailPreview()} alt="" />
              <div
                onClick={handleOpenReplaceContentModal("thumbnail")}
                className={`upload-btn thumbnail ${content || file ? "has-file" : ""}`}
              >
                <img className="upload-icon" src={uploadIcon} alt="upload-icon" />
              </div>
            </div>
            <span className="error">{t(errors?.thumbnail)}</span>
          </div>
        )}
        <div className="info">
          <div className="title">{t("INFORMATION")}</div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>{t("Content Title")}*</Form.Label>
              <FormControl
                id="contentTitle"
                name="contentTitle"
                type="input"
                value={contentTitle}
                placeholder={t("Content Title")}
                onChange={(e) => setContentTitle(e.target.value)}
              />
              <span className="error">{t(errors?.contentTitle)}</span>
            </Form.Group>
          </div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>{t("Content Section")}*</Form.Label>
              <Select
                className="content-section"
                classNamePrefix="select"
                value={{ value: contentSection, label: t(contentSection) }}
                options={ContentSectionOptions.map((item) => ({
                  label: t(item.label),
                  value: item.value,
                }))}
                name="contentSection"
                isSearchable={false}
                isDisabled={true}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                onChange={(item) => setContentSection(item?.value)}
              />
              <span className="error">{t(errors?.contentSection)}</span>
            </Form.Group>
          </div>
          {contentType !== CONTENT_TYPE.FLOORPLANS && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>{t("Content Order")}*</Form.Label>
                <FormControl
                  id="order"
                  name="order"
                  type="number"
                  value={order}
                  onChange={(e) => setOrder(e.target?.value)}
                />
                <span className="error">{t(errors?.order)}</span>
              </Form.Group>
            </div>
          )}
          {contentType === CONTENT_TYPE.FLOORPLANS && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>{t("Associated Residence")}*</Form.Label>
                <Select
                  className={`unit-id ${unitId ? "selected" : ""}`}
                  classNamePrefix="select"
                  value={{
                    value: unitId || "",
                    label: `${t("Residence")} ${units.find((unit) => unit.id === unitId)?.name || ""}`,
                  }}
                  options={units.map((unit) => ({
                    label: `${t("Residence")} ${unit.name}`,
                    value: unit.id,
                  }))}
                  name="unitId"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(item) => setUnitId(item?.value)}
                />
                <span className="error">{t(errors?.unitId)}</span>
              </Form.Group>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className={`save ${enableBtnSubmit && isAbleSave ? '' : 'disabled'}`} onClick={handleUpdateContent}>
          {t("SAVE")}
        </span>
        <span className="delete" onClick={handleOpenDeleteContentModal}>
          {t("DELETE CONTENT")}
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContentModal;
