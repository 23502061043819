import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getTransportOptions = (data) =>
  client.get(endPoint.TRANSPORT_OPTIONS, data);
const getTransportOptionDistricts = (data) =>
  client.get(endPoint.TRANSPORT_OPTION_DISTRICTS, data);
const getAmenitiesOptions = (data) =>
  client.get(endPoint.AMENITIES_OPTIONS, data);

export default {
  getTransportOptions,
  getTransportOptionDistricts,
  getAmenitiesOptions,
};
