import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const defaultClient = new ApiClient(defaultFetcher);

const createAppointment = (data) =>
  defaultClient.post(endPoint.APPOINTMENT_CREATE, data);
const getListAppointment = (data) =>
  defaultClient.get(endPoint.APPOINTMENT_LIST, data);

export default {
  createAppointment,
  getListAppointment,
};
