import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import closeIcon from "../../assets/images/close-white.svg";

const ErrorModal = (props) => {
  const {t} = useTranslation();
  const { onClose, isShow, message = {} } = props;

  return (
    <Modal
      backdropClassName="opacity0"
      className="wrap-not-found"
      show={isShow}
      onHide={onClose}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={onClose} />
        </div>
        <div className="modal-not-found">
          <span className="title">{message?.title ?? t('CUSTOMER_NOT_FOUND')}</span>
          <span className="sub">
            {message?.content ?? t('COMBINATION_UNMATCHED')}
          </span>
          <button onClick={onClose}>{t('CLOSE')}</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
