import React, { useEffect, useRef } from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import closeIcon from "../../../assets/images/close-white.svg";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const PopupGallery = ({ listImage, onClose, isPresentation }) => {
  const galleryRef = useRef();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
    afterChange: (index) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
          onGoToSlideIndex(content.data.index);
        }
      })
    }
  }, [isPresentation]);

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  }

  return (
    <>
      <div className="views-wrap-gallery-image">
        <Slider ref={galleryRef} {...settings}>
          {(listImage || []).map((item, key) => {
            return (
              <div key={key}>
                <div
                  className="gallery-item"
                  style={{
                    backgroundImage: `url(.${item.virtual_link})`
                  }}
                />
              </div>
            );
          })}
        </Slider>
        <span className="btn-gallery prev-slide">
          <img
            width="50"
            height="50"
            src="/icons/left-arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickPrev()}
          />
        </span>
        <span className="btn-gallery next-slide">
          <img
            width="50"
            height="50"
            src="/icons/right-arrow.svg"
            alt=""
            onClick={() => galleryRef.current.slickNext()}
          />
        </span>
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={onClose} />
        </div>
      </div>
    </>
  );
};

export default PopupGallery;
