import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getAmenityV2List = (data) => client.get(endPoint.AMENITY_V2_GET_LIST, data);
const getAmenityV2Detail = (amenityId, data) =>
  client.get(
    endPoint.AMENITY_V2_GET_DETAIL.replace(':amenityId', amenityId),
    data
  );
const updateAmenityV2 = (amenityId, data) =>
  defaultClient.put(endPoint.AMENITY_V2_UPDATE.replace(':amenityId', amenityId), data);

export default {
  getAmenityV2List,
  getAmenityV2Detail,
  updateAmenityV2
};
