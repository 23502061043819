import React from 'react';
import TramPath from './tram-path';
import UnitDetail from './unit-detail';
import Parking from './parking';

const FavoritesOfCustomer = ({ items }) => {

    const renderUnitDetail = () => {
        const units = items || [];
        let style = 1;

        return units.map((unit, index) => {
            if (style === 1) {
                style = style < 2 ? style + 1 : 1;

                return (
                    <div key={index}>
                        <UnitDetail unit={unit} />
                        <TramPath />
                    </div>
                )
            }

            if (style === 2) {
                style = style < 2 ? style + 1 : 1;

                return (
                    <div key={index}>
                        <UnitDetail unit={unit} />
                        <Parking />
                    </div>
                )
            }
        });
    }

    return renderUnitDetail()
}

export default FavoritesOfCustomer;
