import * as Types from './type';

const actSetActivePrecinctID = (data) => ({
  type: Types.SET_ACTIVE_PRECINCT_ID,
  data,
});

export const reqSetActivePrecinctID = (data) => (dispatch) => {
  return dispatch(actSetActivePrecinctID(data));
};
