import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getSubPrecincts = (data) => client.get(endPoint.SUB_PRECINCTS, data);

export default {
  getSubPrecincts,
};
