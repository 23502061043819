import React, { useRef, useState, useEffect } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import closeIcon from "../../../assets/images/close-white.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import mediaAPI from "../../../apis/api/media";
import galleryApi from "../../../apis/api/gallery";
import { CONTENT_TYPE, ContentSectionOptions, MEDIA_TYPE } from ".";
import { useTranslation } from "react-i18next";
import { GALLERY_TYPE } from "../../../constants/options";
import { useSelector } from "react-redux";

const yupObj = {
  file: yup.mixed().required("No file chosen"),
  order: yup.number().required("Order is a required field"),
  contentTitle: yup.string().trim().required("Content title is a required field"),
  contentSection: yup.string().trim().required("Content section is a required field"),
};

const validationSchema = yup.object().shape(yupObj);
const filmValidationSchema = yup.object().shape({
  ...yupObj,
  thumbnail: yup.mixed().required("No thumbnail chosen"),
});

const floorplanYupObj = {
  file: yup.mixed().required("No file chosen"),
  contentTitle: yup.string().trim().required("Content title is a required field"),
  contentSection: yup.string().trim().required("Content section is a required field"),
  unitId: yup.string().trim().required("Associated Residence is a required field"),
};
const floorplanValidationSchema = yup.object().shape(floorplanYupObj);

const AddContentModal = ({ show, setShow, onCreateSuccess, mediaType, contentType }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [order, setOrder] = useState();
  const [contentTitle, setContentTitle] = useState("");
  const [contentSection, setContentSection] = useState("");
  const [isShowReplaceContentModal, setIsShowReplaceContentModal] = useState(false);
  const [replaceContentType, setReplaceContentType] = useState("");
  const [errors, setErrors] = useState({});
  const fileRef = useRef();
  const thumbnailRef = useRef();
  const units = useSelector((state) => state.cms.units);
  const [unitId, setUnitId] = useState('');
  const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);

  useEffect(() => {
    const unit = units?.find((unit) => unit.id === unitId);

    unit?.floorPlan ? setErrors({unitId: "Associated Residence already had a floorplan"}) : setErrors({unitId: undefined});
  }, [unitId]);

  useEffect(() => {
    if (file) {
      setContentTitle(file.name);
    }
  }, [file]);

  const handleAddNewContent = () => {
    if (!enableBtnSubmit) {
      toast.info(t("Please wait, media file uploading!"));
      return;
    }
    switch (contentType) {
      case CONTENT_TYPE.IMAGES:
        handleAddNewContentImage();
        return;
      case CONTENT_TYPE.FILMS:
        handleAddNewContentFilm();
        return;
      case CONTENT_TYPE.FLOORPLANS:
        handleAddNewContentFloorplan();
        return;
      default:
        return;
    }
  };

  const handleAddNewContentImage = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
      };
      setErrors({});
      const result = await validationSchema.validate(data, { abortEarly: false });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_images/image_gallery");
      formData.append("file", file);

      toast.info(t("Please wait, media file uploading!"));
      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.MEDIA_IMAGES,
          media: [uploaded?.data?.id],
        });
        toast.success(t("Add new content successfully!"));
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleAddNewContentFilm = async () => {
    try {
      const data = {
        order,
        contentTitle,
        contentSection,
        file,
        thumbnail,
      };
      setErrors({});
      const result = await filmValidationSchema.validate(data, { abortEarly: false });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "video");
      formData.append("order", result?.order);
      formData.append("name", result?.contentTitle);
      formData.append("path", "media/media_videos/films");
      formData.append("file", file);

      const formDataThumbnail = new FormData();
      formDataThumbnail.append("type", "image");
      formDataThumbnail.append("name", `Thumbnail ${result?.contentTitle}`);
      formDataThumbnail.append("path", "media/media_videos/thumbnails");
      formDataThumbnail.append("file", thumbnail, `thumbnail_${Date.now()}`);

      toast.info(t("Please wait, media file uploading!"));
      const uploaded = await mediaAPI.uploadMedia(formData);
      const uploadedThumbnail = await mediaAPI.uploadMedia(formDataThumbnail);

      if (uploaded?.data && uploadedThumbnail?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.BAKU_FILMS,
          media: [uploaded?.data?.id, uploadedThumbnail?.data?.id],
        });
        toast.success(t("Add new content successfully!"));
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleAddNewContentFloorplan = async () => {
    if (errors.unitId === "Associated Residence already had a floorplan") {
      return;
    }

    try {
      const data = {
        contentTitle,
        contentSection,
        file,
        unitId,
      };
      setErrors({});
      const result = await floorplanValidationSchema.validate(data, {
        abortEarly: false,
      });

      setEnableBtnSubmit(false);
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("name", result.contentTitle);
      formData.append("path", "media/floorplans");
      formData.append("file", file);

      toast.info(t("Please wait, media file uploading!"));
      const uploaded = await mediaAPI.uploadMedia(formData);
      if (uploaded?.data) {
        await galleryApi.createGallery({
          type: GALLERY_TYPE.FLOORPLAN,
          media: [uploaded?.data?.id],
          unitId,
        });
        toast.success(t("Add new content successfully!"));
        handleClose && handleClose();
        onCreateSuccess && onCreateSuccess();
      }
    } catch (err) {
      if (err.inner) {
        const errs = {};
        err.inner.forEach((err) => {
          errs[err.path] = err.message;
        });
        setErrors(errs);
      }
      toast.error(t("Upload media failed!"));
      setEnableBtnSubmit(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onOpenFileInput = () => {
    if (!file || isShowReplaceContentModal) {
      fileRef.current.click();
    } else {
      handleOpenReplaceContentModal(mediaType);
    }
  };

  const onOpenThumbnailInput = () => {
    if (!thumbnail || isShowReplaceContentModal) {
      thumbnailRef.current.click();
    } else {
      handleOpenReplaceContentModal("thumbnail");
    }
  };

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return "/images/image.png";
  };

  const renderThumbnailPreview = () => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }

    return "/images/image.png";
  };

  const handleBrowserFile = (e) => {
    setFile(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleBrowseThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    handleCloseReplaceContentModal();
  };

  const handleOpenReplaceContentModal = (type) => {
    setIsShowReplaceContentModal(true);
    setReplaceContentType(type);
  };

  const handleCloseReplaceContentModal = () => {
    setIsShowReplaceContentModal(false);
    setReplaceContentType("");
  };

  if (isShowReplaceContentModal) {
    return (
      <Modal
        className="wrap-replace-content-modal"
        show={isShowReplaceContentModal}
        onHide={handleCloseReplaceContentModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("REPLACE CONTENT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={handleCloseReplaceContentModal} />
          </div>
          <div
            className="browser-file"
            onClick={replaceContentType === "thumbnail" ? onOpenThumbnailInput : onOpenFileInput}
          >
            {t("BROWSE FILES")}
            <input
              id="file"
              type="file"
              ref={replaceContentType === "thumbnail" ? thumbnailRef : fileRef}
              className="hidden"
              accept={`${replaceContentType === "video" ? "video" : "image"}/*`}
              onChange={(e) => (replaceContentType === "thumbnail" ? handleBrowseThumbnail(e) : handleBrowserFile(e))}
            />
          </div>
          {mediaType !== MEDIA_TYPE.VIDEO && 
            <div className="import" onClick={() => { }}>
              <span className="import-title">{t("Or import from a Matterport URL")}</span>
              <div className="import-form">
                <input type="text" placeholder={t("Add a Matterport URL")} />
                <span className="import-button">{t("IMPORT")}</span>
              </div>
            </div>
          }
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal className="wrap-create-content-modal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("ADD NEW CONTENT")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wrap-modal-body">
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <div className="title">{t("CONTENT")}</div>
          {file ? (
            <>
              <div className='upload-input cursor-pointer'>
                {mediaType === MEDIA_TYPE.IMAGE && <img className="img-fluid" src={renderImagePreview()} alt="" />}
                {mediaType === MEDIA_TYPE.VIDEO && (
                  <video className="img-fluid">
                    <source src={renderImagePreview()} type="video/mp4" />
                  </video>
                )}
                <div onClick={onOpenFileInput} className={`upload-btn ${mediaType}`}>
                  <img className="upload-icon" src={uploadIcon} alt="upload-icon" />
                </div>
              </div>
              <input
                ref={fileRef}
                accept={`${mediaType}/*`}
                id="file"
                className="hidden"
                type="file"
                onChange={(e) => handleBrowserFile(e)}
              />
            </>
          ) : (
            <>
              <div className="browser-file" onClick={onOpenFileInput}>
                {t("BROWSE FILES")}
                <input
                  ref={fileRef}
                  accept={`${mediaType}/*`}
                  id="file"
                  className="hidden"
                  type="file"
                  onChange={(e) => handleBrowserFile(e)}
                />
              </div>
              {mediaType !== MEDIA_TYPE.VIDEO &&
                <div className="import" onClick={() => { }}>
                  <span className="import-title">{t("Or import from a Matterport URL")}</span>
                  <div className="import-form">
                    <input type="text" placeholder={t("Add a Matterport URL")} />
                    <span className="import-button">{t("IMPORT")}</span>
                  </div>
                </div>
              }
            </>
          )}
          <span className="error">{t(errors?.file)}</span>
        </div>
        {mediaType === MEDIA_TYPE.VIDEO && (
          <div className="content thumbnail">
            <div className="title">{t("THUMBNAIL")}</div>
            <div div className='upload-input'>
            {thumbnail ? (
              <>
                <img className="img-fluid cursor-pointer" src={renderThumbnailPreview()} alt="" />
                <input
                  ref={thumbnailRef}
                  accept={`image/*`}
                  id="file"
                  className="hidden"
                  type="file"
                  onChange={(e) => handleBrowseThumbnail(e)}
                />
                <div onClick={onOpenThumbnailInput} className={`upload-btn thumbnail ${file ? "has-file" : ""}`}>
                  <img className="upload-icon" src={uploadIcon} alt="upload-icon" />
                </div>
              </>
            ) : (
              <>
                <div className="browser-file" onClick={onOpenThumbnailInput}>
                  {t("BROWSE FILES")}
                  <input
                    ref={thumbnailRef}
                    accept={`image/*`}
                    id="file"
                    className="hidden"
                    type="file"
                    onChange={(e) => handleBrowseThumbnail(e)}
                  />
                </div>
              </>
            )}
            </div>
            <span className="error">{t(errors?.thumbnail)}</span>
          </div>
        )}
        <div className="info">
          <div className="title">{t("INFORMATION")}</div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>{t("Content Title")}*</Form.Label>
              <FormControl
                id="contentTitle"
                name="contentTitle"
                type="input"
                value={contentTitle}
                placeholder={t("Content Title")}
                onChange={(e) => setContentTitle(e.target.value)}
              />
              <span className="error">{t(errors?.contentTitle)}</span>
            </Form.Group>
          </div>
          <div className="wrap-form-group">
            <Form.Group>
              <Form.Label>{t("Content Section")}*</Form.Label>
              <Select
                className={`content-section ${contentSection ? "selected" : ""}`}
                classNamePrefix="select"
                value={{
                  value: contentSection || "",
                  label: t(contentSection) || t("Select a content section"),
                }}
                options={ContentSectionOptions.map((item) => ({
                  label: t(item.label),
                  value: item.value,
                }))}
                name="contentSection"
                isSearchable={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                onChange={(item) => setContentSection(item?.value)}
              />
              <span className="error">{t(errors?.contentSection)}</span>
            </Form.Group>
          </div>

          {contentType !== CONTENT_TYPE.FLOORPLANS && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>{t("Content Order")}*</Form.Label>
                <FormControl
                  id="order"
                  name="order"
                  type="number"
                  value={order}
                  onChange={(e) => setOrder(e.target?.value)}
                />
                <span className="error">{t(errors?.order)}</span>
              </Form.Group>
            </div>
          )}
          {contentType === CONTENT_TYPE.FLOORPLANS && (
            <div className="wrap-form-group">
              <Form.Group>
                <Form.Label>{t("Associated Residence")}*</Form.Label>
                <Select
                  className={`unit-id ${unitId ? "selected" : ""}`}
                  classNamePrefix="select"
                  value={{
                    value: unitId || "",
                    label: `${t("Residence")} ${units.find((unit) => unit.id === unitId)?.name || ""}`,
                  }}
                  options={units.map((unit) => ({
                    label: `${t("Residence")} ${unit.name}`,
                    value: unit.id,
                  }))}
                  name="unitId"
                  isSearchable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onChange={(item) => setUnitId(item?.value)}
                />
                <span className="error">{t(errors?.unitId)}</span>
              </Form.Group>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={`submit ${enableBtnSubmit ? '' : 'disabled'}`} onClick={handleAddNewContent}>
          {t("SUBMIT")}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContentModal;
