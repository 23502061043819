import "./index.scss";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import CreateIcon from "../components/Create";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import RequestSupportModal from "./RequestSupportModal";
import { reqGetListFAQs } from "../../../reduxs/cms/action";
import { CollapsibleContent } from '../../../components/collapsable-content';

const tabs = [
  { type: 'app', title: 'APP', eventKey: 'content-app' },
  // { type: 'ipad', title: 'IPAD', eventKey: 'content-ipad' },
  // { type: 'physical_model', title: 'PHYSICAL MODEL', eventKey: 'content-model' },
  { type: 'hardware', title: 'SALES GALLERY HARDWARE', eventKey: 'content-hardware' },
  { type: 'tv_display', title: 'TV DISPLAY', eventKey: 'content-display' },
]

const CMSFrequentlyAQS = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const faqList = useSelector((state) => state.cms.faqs);
  const authUser = useSelector((state) => state.user.data);
  
  const [isShowForm, setIsShowForm] = useState(false);

  useEffect(() => {
    dispatch(reqGetListFAQs());
  }, []);

  const getListFaqByType = (type) => (faqList || []).filter(item => item.type === type);

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">FAQS</h1>
        {i18n?.language !== 'en' && <span>{t('only_available_en')}</span>}
      </div>
      <div className="page-body content-page-body">
        <Tabs
          defaultActiveKey="content-app"
          className=""
        >
          {tabs.map((tab, i) => {
            return <Tab key={i} eventKey={tab.eventKey} title={t(tab.title)}>
              <div className='scroll-view'>
                {
                  getListFaqByType(tab.type)?.length
                    ? getListFaqByType(tab.type)?.map((item, i) => {
                      return <CollapsibleContent
                        key={i}
                        title={item?.question}
                        content={item?.answer}
                      />
                    })
                    : <div>No Content</div>
                }
              </div>
            </Tab>
          })}
        </Tabs>
      </div>
      {
        isShowForm &&
        <RequestSupportModal
          show={isShowForm}
          setShow={setIsShowForm}
          user={authUser}
        />
      }
      <div onClick={() => setIsShowForm(true)}>
        <CreateIcon />
      </div>
    </div>
  );
};
export default CMSFrequentlyAQS;
