import React, { useState, useEffect } from "react";

import VirtualTour from "../virtual-tour";
import "./index.scss";

const ViewsLevels = () => {
  return (
    <div className="views-levels">
      <VirtualTour isHidden={true} />
    </div>
  );
};

export default ViewsLevels;
