import * as type from './type';

const initialState = {
  isShowTransportDetail: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_IS_SHOW_TRANSPORT_DETAIL:
      return {
        ...state,
        isShowTransportDetail: action.data,
      };
    default:
      return state;
  }
};
