import React, { useEffect, useMemo, useRef } from "react";
import SideNav from "../sidenav";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent
} from "../../reduxs/home/action";
import { useTranslation } from "react-i18next";
import AccordionSidebar from "../accordion-sidebar";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { formatMoney } from "../../helper/unit";

const UnitDetail = ({ isPresentation }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const roomAreas = useMemo(() => unit?.roomAreas?.[i18n.language].split(',') ?? [], [unit, i18n.language]);
  const activeKeys = useSelector((state) => state.unitExplore.activeDetailKeys)
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
          handleShowFloorplan();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
          handleCloseFloorplan();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
          handleShowGallery();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
          handleCloseGallery();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_VIEWLINE) {
          handleShowVirtualTour();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
          handleCloseVirtualTour();
        }

        if (content.action === ACTION_NAME.CLICK_UNIT_DETAIL_INFO) {
          handleClickUnitDetail(content.data.currentActiveKeys, content.data.key)
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      });
    }
  }, [isPresentation])

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_FLOORPLAN)
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    let timeout = setTimeout(() => {
      dispatch(unitExploreAct.reqIsShowFloorplan(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN)
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));

  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_GALLERY)
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
      dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleShowVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
      dispatch(unitExploreAct.reqIsShowVirtualTour(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
  }

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY)
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const handleClickUnitDetail = (currentActiveKeys, key) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_DETAIL_INFO, {
        currentActiveKeys,
        key
      })
    }

    if (currentActiveKeys.includes(key)) {
      dispatch(unitExploreAct.reqCloseUnitDetailInfo(key))
    } else {
      dispatch(unitExploreAct.reqShowUnitDetailInfo(key))
    }
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li
          onClick={() => handleCloseFloorplan()}
          className="list-group-item text-uppercase text-decoration-underline"
        >
          {t("CLOSE FLOORPLAN")}
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowFloorplan()}
        className="list-group-item text-uppercase"
      >
        {t("VIEW FLOORPLAN")}
      </li>
    );
  };

  const renderBtnGallery = () => {
    if (isShowGallery) {
      return (
        <li
          onClick={() => handleCloseGallery()}
          className="list-group-item text-uppercase"
        >
          {t("CLOSE GALLERY")}
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowGallery()}
        className="list-group-item text-uppercase"
      >
        {t("VIEW GALLERY")}
      </li>
    );
  };

  const renderBtnVirtualTour = () => {
    if (isShowVirtualTour) {
      return (
        <li
          onClick={() => handleCloseVirtualTour()}
          className="list-group-item text-uppercase"
        >
          {t("CLOSE VIEWLINES")}
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowVirtualTour()}
        className="list-group-item text-uppercase"
      >
        {t("VIEW VIEWLINES")}
      </li>
    );
  };

  const renderAspect = (value) => {
    if (value === "sea_view" || value === "city_view") return t(`UnitDetail.${value}`)
    return value
  }

  return (
    <SideNav
      position="right"
      isDark={false}
      id="sidenavDetail"
      heading={t("UnitDetail.header")}
      icon={unit?.name}
      footer={
        <div className="wrap-btn-detail mt-auto">
          {/*<img src={upgrade} alt="upgrade" className="wrap-btn-detail-img" />*/}
          <ul className="list-group mb-0 py-2">
            {renderBtnFloorplan()}
            {renderBtnGallery()}
            {unit?.virtualTour?.id && renderBtnVirtualTour()}
          </ul>
        </div>
      }
    >
      <div ref={pageRef} onScroll={onScroll} className={"sidenav__wrapper accordion-sidebar scroll-detail disable-scroll-bar"}>
        <div className="details">
          <AccordionSidebar
            id="unit-detail-detail"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.DetailTitle"),
                items: [
                  {
                    title: t("UnitDetail.Availability"),
                    value: <span>{t(`AVAILABILITY_OPTIONS.${unit?.availabilityStatus}`)}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle1"),
                    value: <span>{unit?.totalLot}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle2"),
                    value: <span>{unit?.bedrooms}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle3"),
                    value: <span>{renderAspect(unit?.aspect)}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle4"),
                    value: <span>{formatMoney(unit?.price, true)}</span>
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="room-area">
          <AccordionSidebar
            id="unit-detail-room-area"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.DetailedAreaTitle"),
                items: [
                  {
                    title: "",
                    value: <ul className="room-area-list">
                      {roomAreas?.map((room, ind) =>
                        <li key={ind + room}>
                          <div className="content">
                            <span>{`${room.split(':')[0]}:`}</span>
                            <span>{room.split(':')[1]}</span>
                          </div>
                        </li>)}
                    </ul>
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="service-charge">
          <AccordionSidebar
            id="unit-detail-service-charge"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.ServiceChargeTitle"),
                items: [
                  {
                    title: "",
                    value: <span>{formatMoney(unit?.serviceCharge, true)} {t('UnitDetail.per_annum')}</span>
                  }
                ]
              }
            ]}
          />
        </div>
      </div>
    </SideNav>
  );
};

export default UnitDetail;
