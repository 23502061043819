import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reqSetIsShowLanguageModal } from "../../reduxs/guide-session/action";
import "./index.scss";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const LanguageModal = ({ isPresentation }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isShowLanguageModal = useSelector(
    (state) => state.guideSession.isShowLanguageModal
  );
  const root = document.getElementsByTagName('body')[0];

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLOSE_LANGUAGE) {
          handleClose();
        }
        if (content.action === ACTION_NAME.SELECT_LANGUAGE) {
          selectLanguage(content.data);
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    root.classList.add(`lang-${i18n.language}`);
  }, []);

  const handleClose = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_LANGUAGE);
    }

    dispatch(reqSetIsShowLanguageModal(false));
  };

  const selectLanguage = (lang) => {
    root.classList.remove("lang-en", "lang-az", "lang-ru");
    root.classList.add(`lang-${lang}`);
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_LANGUAGE, lang);
    }
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <>
      <Modal
        className="wrap-user-language-modal"
        show={isShowLanguageModal}
        onHide={() => selectLanguage("en")}
        centered
        size="md"
      >
        <Modal.Body className="wrap-modal-body">
          <div
            className="modal-form__title">
            <span className="title">{t('LanguageModal.title')}</span>
            <span className="description">
              {t('LanguageModal.description')}
            </span>
          </div>
          <div className="language-buttons d-flex-row">
            <button onClick={() => selectLanguage("en")}>
              <span>{t('EN')}</span>
            </button>
            <button onClick={() => selectLanguage("az")}>
              <span>{t('AZ')}</span>
            </button>
            <button onClick={() => selectLanguage("ru")}>
              <span>{t('RU')}</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageModal;
