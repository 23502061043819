import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import "./index.scss";
import { useSelector } from 'react-redux';

export const EnableAutoPlayModal = () => {
  const pageClicked = useSelector((state) => state.home.pageClicked);

  return (
    <Modal
      className="wrap-user-type-modal enable-audio-modal"
      show={!pageClicked}
      onHide={() => {}}
      centered
      size="md"
    >
      <ModalBody>
        <button className="enable-audio-button text-uppercase">
          Click to enable audio
        </button>
      </ModalBody>
    </Modal>
  );
};
