import { Accordion } from "react-bootstrap";
import React, { Fragment } from "react";
import "./accordion.scss";
const AccordionSidebar = ({ data, activeKeys, onToggle, id }) => {
  return (
    <>
      <Accordion activeKey={activeKeys.includes(id) ? id : ""}>
        {data.map((section, key) => (
          <Fragment key={"list-group-" + key}>
            <Accordion.Toggle
              className={"accordion-button accordion-sidebar-button txt-style"}
              eventKey={id}
              onClick={() => onToggle(activeKeys, id)}
            >
              {section.title}
              <svg
                className={
                  "accordion-sidebar-button-icon accordion-sidebar-button-icon-" +
                  (activeKeys.includes(id) ? "up" : "down")
                }
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="#ffffff"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={id}>
              <ul className="list-group">
                {(section?.items || []).map((item, key2) => (
                  <li
                    key={"list-group-item-" + key2}
                    className="list-group-item"
                  >
                    <div className="list-group-item-title">
                      <span className="me-auto">{item.title}</span>
                    </div>
                    <div className="list-group-item-body">{item.value}</div>
                  </li>
                ))}
              </ul>
            </Accordion.Collapse>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
};
export default AccordionSidebar;
