import React from "react";
import './index.scss'

const CreateIcon = ({onClick}) => {
    return(
        <div className='create-component' onClick={onClick}>
            <img className="img-fluid" src="/icons/icCreate.svg" alt="" />
        </div>
    )
};

export default CreateIcon;
