/* eslint-disable no-unused-vars */
import React from 'react';
import './reset-password.scss';
import { useState, useEffect } from 'react';
import {  useHistory, useLocation } from 'react-router-dom';
import { validatePassword } from '../../helper/validation';
import authApi from '../../apis/api/auth';
import icBaku from "../../assets/images/baku_logo.svg";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const {t} = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});
  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if(!dirty.password) return;
    validatePasswordFunc()
  }, [password, dirty.password]);

  useEffect(() => {
    if(!dirty.passwordConfirmation) return;
    validatePasswordConfirmFunc()
  }, [passwordConfirmation, dirty.passwordConfirmation]);

  const validatePasswordFunc = () => {
    if (validatePassword(password) && dirty.password) {
      delete message.password;
      setMessage({ ...message });
      return true;
    } else {
      message.password =
        'invalidPassword';
      setMessage({ ...message });
      return false;
    }
  }

  const validatePasswordConfirmFunc = () => {
    if (password === passwordConfirmation && dirty.passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
      return true;
    } else {
      message.passwordConfirmation = 'passwordNotMatch';
      setMessage({ ...message });
      return false;
    }
  }

  const handleResetPassword = async () => {
    if(!validatePasswordFunc() || !validatePasswordConfirmFunc()) return;
    try {
      const res = await authApi.resetPassword({
        password: password,
        password_confirmation: passwordConfirmation,
        token: query.get('token'),
      });
      if (res.data) {
        toast.success(t("noti.Password reset successfully"));
        history.push('/holding');
      } else {
        toast.error(t("noti.Link is expired"))
      }
    } catch (error) {
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className="icon-cube">
        <img
          src={icBaku}
          alt="icon-cube"
        />
      </div>
      <div className="wrap-modal-form">
        <div className={`modal-form`}>
          <h4 className="modal-form__title mb-0">
            {t('WELCOME TO THE BAKU')}
            <br />
            {t('RITZ-CARLTON RESIDENCES APP')}
          </h4>
          <div className="modal-form__body">
            <span className='label-form'>
              {t('Password')}
            </span>
            <div className="form-group">
              <input
                type="password"
                onChange={(e) => {
                  setDirty({...dirty, password: true})
                  setPassword(e.target.value)
                }}
                className="form-control"
                id="password"
                placeholder={t('Password')}
              />
            </div>
            <span id="passwordHelp" className="text-danger mt-1 mb-4">
              {message.password && t(`errors.${message.password}`)}
            </span>
            <span className='label-form'>
              {t('Password Confirmation')}
            </span>
            <div className="form-group">
              <input
                type="password"
                onChange={(e) => {
                  setDirty({...dirty, passwordConfirmation: true })
                  setPasswordConfirmation(e.target.value)
                }}
                className="form-control"
                id="passwordConfirmation"
                aria-describedby="resetPasswordHelp"
                placeholder={t('Password Confirmation')}
              />
            </div>
            <span id="resetPasswordHelp" className="text-danger mt-1 mb-4">
              {message.passwordConfirmation && t(`errors.${message.passwordConfirmation}`)}
            </span>
            <button
              type="submit"
              onClick={handleResetPassword}
              className="btn-holding-login"
            >
              <span className="txt-login">{t('Reset Password')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
