import React from "react";
import "./top-nav-title.scss";

export const TopNavTitle = ({title}) => {
  return (
    <div className="top-nav-title">
      <span>{title}</span>
    </div>
  )
};
