import * as THREE from 'three';

export const threePosition = (data) => {
  return new THREE.Vector3(data.x, data.z, -data.y);
};

export const setColor = (color, alpha, object3d, activeEmissive, activeEmissiveIntensity) => {

  const newColor = new THREE.Color(color != null ? color : '#999999');
  const newAlpha = alpha != null ? alpha / 100.0 : 1.0;

  object3d.traverse(function (child) {
    child.material.emissive = activeEmissive;
    child.material.emissiveIntensity = activeEmissiveIntensity;
    if (child instanceof THREE.Material) {
      child.color = newColor;
    } else if (child.material != null) {
      if (child.material instanceof Array) {
        for (var i = 0; i < child.material.length; i++) {
          child.material[i].color = newColor;
        }
      } else {
        child.material.color = newColor;
      }
    }
  });

  object3d.userData.newAlpha = newAlpha;
};

export const setColor2 = (color, object3d) => {
  object3d.traverse(function (child) {
    if (child instanceof THREE.Material) {
      child.color.set(color);
    } else if (child.material != null) {
      if (child.material instanceof Array) {
        for (var i = 0; i < child.material.length; i++) {
          child.material[i].color.set(color);
        }
      } else {
        child.material.color.set(color);
      }
    }
  });
};
