export const toLocalDateTime = (date) => {
  if (!date) return;

  return new Date(date).toLocaleString();
};

export const toLocalDate = (date) => {
  if (!date) return;

  return new Date(date).toLocaleString();
};

export const toDateFormat = (date) => {
  if (!date) return;
  const time = new Date(date);
  const year = time.getFullYear();
  const month =
    time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
  const day = time.getDate();
  return `${year}-${month}-${day}`;
};

export const toDateString = (date) => {
  if (!date) return;
  const time = new Date(date);
  const year = time.getFullYear();
  const month =
    time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : time.getMonth() + 1;
  const day = time.getDate();
  return `${day}/${month}/${year}`;
};
