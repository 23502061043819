import React, { useEffect } from 'react';
import './index.scss';
import TopNav from '../../components/top-nav';
import LeftNav from '../../components/left-nav';
import Customers from '../../components/customers';
import Sessions from '../../components/sessions';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//import { reqGetUserProfile } from '../../reduxs/user/action';
import Inventory from '../units/index';

const Dashboard = (props) => {
  const { roles, authMiddleware } = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-dashboard-page">
      <TopNav isShow={false} handleClickCube={() => {}} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-xs-4 col-sm-4">
            <LeftNav />
          </div>
          <div className="col-lg-9 col-md-9 col-xs-8 col-sm-8">
            <Switch>
              <Route exact path={path}>
                <Redirect to={`${path}/customers`} />
              </Route>
              <Route path={`${path}/customers`}>
                <Customers />
              </Route>
              <Route path={`${path}/sessions`}>
                <Sessions />
              </Route>
              <Route path={`${path}/inventory`}>
                <Inventory authMiddleware={authMiddleware} roles={roles} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
