import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { GALLERY_TYPE } from "../../../constants/options";
import galleryApi from "../../../apis/api/gallery";
import AddContentModal from "./AddContentModal";
import EditContentModal from "./EditContentModal";
import SearchIcon from "../components/Search";
import { getUploadedServerMediaUrl } from "../../../helper/media";
import CreateIcon from "../components/Create";
import mediaAPI from '../../../apis/api/media';
import { useTranslation } from "react-i18next";
import { CONTENT_TYPE } from ".";

const ContentFilms = () => {
  const {t} = useTranslation();
  const [search, setSearch] = useState('');
  const [galleries, setGalleries] = useState([]);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);

  const mediaType = 'video';

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [search]);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.BAKU_FILMS,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res && res.data) {
        let result = res.data;
        if (search) {
          result = result.filter(
            gallery => gallery.media && gallery.media[0]?.name?.toLowerCase().includes(search)
          )  
        }
        result.sort((a, b) => (a.media?.[0]?.order ?? 999) - (b.media?.[0]?.order ?? 999));

        setGalleries(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateSuccess = () => {
    loadData();
  }

  const onSaveSuccess = () => {
    loadData();
  }

  const onEditContent = (gallery) => {
    setSelectedGallery(gallery);
    setIsShowEditContentModal(true);
  }

  const handleUpdateActive = async (e, selectedItem) => {
    if (!selectedItem) return;

    try {			
			const formData = new FormData();
			formData.append('type', mediaType);
			formData.append('isActive', e.target.checked);
			const updated = await mediaAPI.updateMedia(selectedItem.id, formData);
			if (updated?.data) {
				toast.success(t('Content updated successfully!'));
			}
		} catch (err) {
			toast.error('Content updated failed!');
		}
  }

  const onSearch = (e) => {
    setSearch(e.target?.value?.toLowerCase());
  } 

  const renderListMedia = () => {
    if (!galleries?.length) {
      return (
        <div className="d-flex justify-content-center">
          {t("NO FILMS")}
        </div>
      )
    }
    return galleries?.map((g, index) => {
      if (!g?.media?.length) return null;

      const item = g?.media?.find(m => m.type === 'video');
      const thumbnail = g?.media?.find(m => m.type === 'image');
      return (
        <div key={index} className="wrap-media-item" >
          <video
            className="content-film"
            controls="controls"
            poster={thumbnail ? getUploadedServerMediaUrl(thumbnail.path) : ''}
          >
            <source src={getUploadedServerMediaUrl(item?.path)} type="video/mp4" />
          </video>
          <div className="content-title-row">
            <span className="content-title">{item?.name || ''}</span>
            <div className="content-action">
              <div className="status">
                <div className="form-check form-switch my-n1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="content-state-1"
                    defaultChecked={item?.isActive}
                    onChange={(e) => handleUpdateActive(e, item)}
                  />
                  <label className="form-check-label" htmlFor="content-state-1" />
                </div>
              </div>
              <div onClick={() => onEditContent(g)}>
                <div className="action-edit">
                  <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
                </div>
              </div>
            </div>  
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="wrap-media-list">
        {renderListMedia()}
      </div>
      {
        isShowAddContentModal &&
        <AddContentModal
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
          mediaType={mediaType}
          contentType={CONTENT_TYPE.FILMS}
        />
      }
      {
        isShowEditContentModal &&
        <EditContentModal
          show={isShowEditContentModal}
          setShow={setIsShowEditContentModal}
          onSaveSuccess={onSaveSuccess}
          content={selectedGallery?.media?.find(m => m.type === mediaType)}
          thumbnailContent={selectedGallery?.media?.find(m => m.type === 'image')}
          gallery={selectedGallery}
          contentType={CONTENT_TYPE.FILMS}
          mediaType={mediaType}
        />
      }
      <div onClick={() => setIsShowAddContentModal(true)}>
        <CreateIcon />
      </div>
      <SearchIcon handleInputChange={onSearch}/>
    </>
  )
}

export default ContentFilms;
