export const groupBy = (array, key, condition = () => true) => {
  const result = [];
  (array || []).map((item) => {
    if (!result.includes(item[key]) && condition(item)) {
      result.push(item[key]);
    }
  });
  return result;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const formatMoney = (price, showUsd = false) => {
  if (!price) return 0;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const fraction = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let value = ""

  if(Number(price) % 1 === 0) {
    value =  formatter.format(price)
  } else {
    value = fraction.format(price);
  }

  return showUsd ? value.substring(1) + ' USD': value;
  
};

export const sqmtToSqft = (sqMeters) => +((sqMeters * 10.7639).toFixed(2));
