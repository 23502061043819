import React from "react";
import "./index.scss";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   reqSetIsShowExploreModal,
//   reqSetExploreModal,
// } from "../../reduxs/explore-modal/action";
// import amenityApi from "../../apis/api/amenities";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";
// import { actSetActiveAmenity } from "../../reduxs/amenities/action";
import { useTranslation } from "react-i18next";
const Amenities = ({ gotoAmenityPageV2 }) => {
  const {t} = useTranslation();
  // const [amenities, setAmenities] = useState([]);
  // useEffect(async () => {
  //   const amenityRes = await amenityApi.getAmenitiesList();
  //   setAmenities(amenityRes.data);
  // }, []);
  // const activeAmenity = useSelector((state) => state.amenities.activeAmenity);
  // const dispatch = useDispatch();

  // const handleAmenityItemClick = (item) => {
  //   dispatch(actSetActiveAmenity(item));
  //   dispatch(reqSetExploreModal(item.modal));
  //   dispatch(reqSetIsShowExploreModal(true));
  // };

  return (
    <div className="wrap-amenities-bottom">
      {/* <div>
        {amenities.map((i) => {
          return (
            <span
              key={i.id}
              onClick={() => handleAmenityItemClick(i)}
              className="item-amenity"
            >
              <svg
                width="47"
                height="47"
                viewBox="0 0 47 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                dangerouslySetInnerHTML={{
                  __html:
                    activeAmenity.id === i.id ? i.svgPathActive : i.svgPath,
                }}
              />
            </span>
          );
        })}
      </div> */}
      <div className="txt-bottom" onClick={gotoAmenityPageV2}>
        <span>{t("JUMPAMENITIES")}</span>
        <Arrow fill={"#ffffff"} />
      </div>
    </div>
  );

  // return (
  //   <div className="wrap-amenities card side-card left-card">
  //     <h2 className="card-title">{activeAmenityArea?.name}</h2>
  //     <div className="card-body">
  //       <div className="wrap-list-group">
  //         <ul className="list-group">
  //           {(activeAmenityArea?.amenities || []).map((item, key) => {
  //             return (
  //               <li
  //                 className={`list-group-item ${
  //                   activeAmenity?.id == item.id ? "active" : ""
  //                 }`}
  //                 onClick={() => handleAmenityItemClick(item)}
  //                 key={key}
  //               >
  //                 {key + 1}&nbsp;&nbsp;{item.name}
  //               </li>
  //             );
  //           })}
  //         </ul>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default Amenities;
