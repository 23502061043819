import React from "react";

const UnitDetail = (props) => {
  const { unit } = props;

  return (
    <div className='wrap-unit-detail mb-3'>
      <div className='row'>
        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 p-5'>
          <div className='unit-floorplan'>
            <img className='img-fluid w-100' src={unit?.floor_plan?.media[0]?.path} alt="" />
          </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex justify-content-between flex-column p-5'>
          <div className='unit-description'>
            <h4 className='text-uppercase mb-3 fw-bold'>{unit?.unit}</h4>
            <h6>CENTRE: <span>{unit?.centre}</span></h6>
            <h6>LEVEL: <span>{unit?.level}</span></h6>
            <h6>AREA: <span>{unit?.areaSqm} Sqm</span></h6>
          </div>
          <div className='unit-status'>
            <button className='text-uppercase btn btn-info btn-status btn-available'>available</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnitDetail;
