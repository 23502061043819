import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SessionList from './list';
import SessionCreate from './create';
import AgentSession from './agent-session';
import './index.scss';

const Sessions = () => {
  let { path } = useRouteMatch();

  return (
    <div className="wrap-customer-page">
      <Switch>
        <Route exact path={path}>
          <SessionList />
        </Route>
        <Route path={`/dashboard/sessions/create`}>
          <SessionCreate />
        </Route>
        <Route path={`/dashboard/sessions/:customerId/start`}>
          <AgentSession />
        </Route>
      </Switch>
    </div>
  );
};

export default Sessions;
