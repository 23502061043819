import React from "react";
import "./index.scss";
import { IconCloseButton } from "../svgs/icons";

const Modal = ({
                 open,
                 onHide,
                 isShowBtnClose = true,
                 children,
                 classNames,
                 isOutSideClose = true,
                 currentColor = "white",
               }) => {
  return (
    <>
      {open && (
        <div
          className={`modal-container ${classNames}`}
          onClick={isOutSideClose ? onHide : () => {}}
        >
          {isShowBtnClose && (
            <div className="btn-close-modal" onClick={onHide}>
              <IconCloseButton style={{width: 26}} fill={currentColor} />
            </div>
          )}
          <div className="modal-base-content">
            <div className="modal-base-body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
