export const SET_UNIT_QUERY = 'SET_QUERY_UNIT';
export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export const GET_UNIT_AREA_LIST = 'GET_UNIT_AREA';

export const FILTER_UNIT_ASPECT = 'FILTER_UNIT_ASPECT';
export const FILTER_UNIT_BEDROOM = 'FILTER_UNIT_BEDROOM';
export const FILTER_UNIT_PRICE = 'FILTER_UNIT_PRICE';
export const FILTER_UNIT_LOT_SIZE = 'FILTER_UNIT_LOT_SIZE';
export const FILTER_UNIT_AVAILABILITY = 'FILTER_UNIT_AVAILABILITY';
export const FILTER_UNIT_ROOM_TYPE = 'FILTER_UNIT_ROOM_TYPE';

export const SET_IS_SHOW_FLOORPLAN = 'IS_SHOW_FLOORPLAN';
export const SET_IS_SHOW_GALLERY = 'IS_SHOW_GALLERY';
export const SET_IS_SHOW_UNIT_DETAIL = 'IS_SHOW_UNIT_DETAIL';
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export const SET_IS_SHOW_FILTER = 'SET_IS_SHOW_FILTER';
export const SET_IS_TRANSPARENT = 'SET_IS_TRANSPARENT';
export const SET_IS_SHOW_PRECINCT_DETAIL = 'SET_IS_SHOW_PRECINCT_DETAIL';
export const SET_IS_SHOW_VIRTUAL_TOUR = 'IS_SHOW_VIRTUAL_TOUR';
export const SET_IS_SHOW_UNIT_LIST = 'SET_IS_SHOW_UNIT_LIST'
export const SHOW_UNIT_DETAIL_INFO = "SHOW_UNIT_DETAIL_INFO"
export const CLOSE_UNIT_DETAIL_INFO = "CLOSE_UNIT_DETAIL_INFO"
export const SET_FILTERED_UNIT = "SET_FILTERED_UNIT"
