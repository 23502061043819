import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CustomerList from '../../components/customers/list';
import CustomerCreate from '../../components/customers/create';
import CustomerDetail from '../../components/customers/detail';
import CustomerEdit from '../../components/customers/edit';
import './index.scss';

const Customer = () => {
  let { path } = useRouteMatch();
  const notify = (message) => toast.info(message);

  return (
    <div className="wrap-customer-page">
      <Switch>
        <Route exact path={path}>
          <CustomerList />
        </Route>
        <Route path={`/dashboard/customers/create`}>
          <CustomerCreate notify={notify} />
        </Route>
        <Route path={`/dashboard/customers/:customerId/profile`}>
          <CustomerDetail />
        </Route>
        <Route path={`/dashboard/customers/:customerId/edit`}>
          <CustomerEdit notify={notify} />
        </Route>
      </Switch>
    </div>
  );
};

export default Customer;
