import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getHotspotsList = (data) => client.get(endPoint.HOTSPOT_GET_LIST, data);
const getHotspotDetail = (hotspotId, data) =>
  client.get(
    endPoint.HOTSPOT_GET_DETAIL.replace(':hotspotId', hotspotId),
    data
  );

export default {
  getHotspotsList,
  getHotspotDetail,
};
