import * as Types from './type';
import unitApi from '../../apis/api/unit';

const actGetUnitList = (data) => ({
  type: Types.GET_UNIT_LIST,
  data,
});

const actLoading = (data) => ({
  type: Types.UNIT_LOADING,
  data,
});

const actUpdate = (data) => ({
  type: Types.UNIT_IS_UPDATE,
  data,
});

export const reqGetUnitList = (query) => (dispatch) => {
  dispatch(actLoading(true));
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actGetUnitList(data));
      dispatch(actLoading(false));
    })
    .catch(() => {
      dispatch(actLoading(false));
    });
};

export const reqPutUnitUpdate = (id, data) => (dispatch) => {
  dispatch(actUpdate(false));
  return unitApi
    .putUnitUpdate(id, data)
    .then(() => {
      dispatch(actUpdate(true));
    })
    .catch(() => {
      dispatch(actUpdate(false));
    });
};

export default reqGetUnitList;
