import React, { useEffect, useRef, useState } from "react";
import CanvasBox from "../components/3d-scene/CanvasBox";
import { connect, useSelector } from "react-redux";
import { setColor } from "../helper/threeHeper";
import { Box3, DoubleSide, Plane, Vector3 } from "three";
import ReactUI from "./reactui";
import { useHistory } from "react-router-dom";
import LanguageModal from "../components/guide-sesions/language-modal";
import { gsap } from "gsap";
import { useMemo } from "react";

const RefCanvasBox = React.forwardRef((props, ref) => (
  <CanvasBox ref={ref} {...props} />
));
RefCanvasBox.displayName = "RefCanvasBox";

let activeObjectIds = [];

const Index = (props) => {
  const { roles, authMiddleware } = props;
  const [setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();
  const history = useHistory();

  const TOPMOST_VERTEX_Y = 140;
  const clipPlanes = useMemo(() => [
    new Plane(new Vector3(0, -1, 0), TOPMOST_VERTEX_Y),
    new Plane(new Vector3(0, +1, 0), TOPMOST_VERTEX_Y),
  ]);

  const page = useSelector((state) => state.home.page);
  const unitIds = useSelector((state) => state.unitExplore.units).map((item) => item["3d_asset"]?.id);

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  let activeColorModel = {};

  function setActiveObjectIds(ids) {
    let prevIds = [];
    for (const index in activeObjectIds) {
      if (!ids.includes(activeObjectIds[index])) {
        prevIds.push(activeObjectIds[index]);
      }
    }
    handleResetObjectColor(prevIds);
    activeObjectIds = ids;
    handleSetActiveObjectColor();
    handleClippingPlanesAnim(ids);
  }

  function resetActiveColorModel(model) {
    activeColorModel = model;
  }

  const handleResetObjectColor = async (prevIds = []) => {
    refScene.current &&
      (prevIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.color,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.activeEmissive,
            0
          );
          objectSelector.userData.isActive = false;
        }
      });
  };

  const handleSetActiveObjectColor = () => {
    refScene.current &&
      (activeObjectIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          let activeColor =
            activeColorModel["" + item]?.colorCode ??
            objectSelector.userData.active_color;
          setColor(
            activeColor,
            objectSelector.userData.active_alpha,
            objectSelector,
            objectSelector.userData.activeEmissive,
            objectSelector.userData.activeEmissiveIntensity
          );
          objectSelector.userData.isActive = true;
        }
      });
  };

  const handleClippingPlanesAnim = (ids) => {
    let animTarget;
    let animStart = clipPlanes[0].constant;
    if (ids.length == 1 && refScene.current) {
      let objectSelector = refScene.current.getObjectByName(ids[0]);
      if (objectSelector) {
        const box = new Box3();
        box
          .copy(objectSelector.geometry.boundingBox)
          .applyMatrix4(objectSelector.matrixWorld);
        let target =
          box.max.y + 0.01; /* small bias to avoid z-clipping artifacts */

        animTarget = target;
      }
    } else {
      animTarget = TOPMOST_VERTEX_Y;
    }

    const animV = new Vector3(0, animStart, 0);
    gsap.to(animV, {
      duration: 0.5,
      x: 0.0,
      y: animTarget,
      z: 0.0,
      onUpdate: function () {
        clipPlanes[0].constant = animV.y;
        clipPlanes[1].constant = animV.y;
      },
      onComplete: function () {
        clipPlanes[0].constant = animV.y;
        clipPlanes[1].constant = animV.y;
      },
    });
  };

  const renderCanvasBox = () => {
    // if (
    //   ![
    //     PAGES.ONBOARD_PAGE,
    //     PAGES.UNIT_EXPLORER_PAGE,
    //     PAGES.LANDING_PAGE,
    //     PAGES.AMENITIES_OPTION_PAGE,
    //     PAGES.AMENITIES_PAGE,
    //   ].includes(page)
    // ) {
    //   return <div />;
    // }
    if (
      !(
        !!props.assets.length &&
        !!props.hotspots.length &&
        Object.keys(props.settings).length &&
        props.fbxs.length &&
        props.pagesSettings != undefined
      )
    )
      return <div />;

    if (props.isLoading) {
      return <div />;
    }

    let ids = [];

    props.fbxs.forEach((group) => {
      const mesh = group.children[0];
      const material = mesh.material;
      const geometry = mesh.geometry;
      geometry.computeBoundingBox();
      material.clippingPlanes = clipPlanes;
      material.side = DoubleSide;
    });

    return (
      <CanvasBox
        isPresentation={false}
        ref={refScene}
        controls={controls}
        objects={props.assets}
        hotspots={props.hotspots}
        activeObjectIds={ids}
        setActiveObjectIds={setActiveObjectIds}
        _3dSetting={props.settings}
        fbxs={props.fbxs}
        pagesSettings={props.pagesSettings}
        isIntroduction={true}
        targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
      />
    );
  };

  return (
    <>
      {renderCanvasBox()}
      <ReactUI
        isPresentation={false}
        setIsIntroduction={setIsIntroduction}
        controls={controls}
        refScene={refScene}
        setActiveObjectIds={setActiveObjectIds}
        resetActiveColorModel={resetActiveColorModel}
        activeObjectIds={activeObjectIds}
      />
      <LanguageModal isPresentation={false} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    assets: scene.assets,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
  };
};
export default connect(mapStateToProps)(Index);
