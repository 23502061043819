import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import customerApi from "../../apis/api/customer";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
} from "../../reduxs/guide-session/action";
import { PhoneInput } from "../phone-input/phone-input";
import closeIcon from "../../assets/images/close-white.svg";
import "./index.scss";
import ErrorModal from "./error-modal";
import { validateEmail } from "../../helper/validation";

const CreateUserProfileModal = (props) => {
  const { startDiscover } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [emailAddress, setEmailAddress] = useState();
  const [address, setAddress] = useState();

  const [mobile, setMobile] = useState({
    value: "",
    country: "az",
  });
  const [error, setError] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const notify = (message) => toast.info(message);
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);

  const isShowCreateInvestorProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateInvestorProfileModal
  );
  const isShowCreateTenantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateTenantProfileModal
  );
  const isShowCreateConsultantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateConsultantProfileModal
  );

  const handleCreateCustomer = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      if (isShowCreateTenantProfileModal) {
        userGroup = "610a4a3351400773f94bfa89";
      }

      if (isShowCreateConsultantProfileModal) {
        userGroup = "63030d2f94bc7bf551da79e3";
      }

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        address: address,
        mobile: mobile?.value || undefined,
        countryCode: mobile?.country || undefined,
      };

      const missingFields = []

      if(!data.firstname) missingFields.push(t("CreateUserProfileModal.fn"))
      if(!data.surname) missingFields.push(t("CreateUserProfileModal.ln"))
      if(!data.email) missingFields.push(t("CreateUserProfileModal.Email"))

      if(missingFields.length) {
        return setError({
          title: t('errors.customer_information_missing'),
          content: `${t('errors.missing_field')}: ${missingFields.join(", ")}`
        })
      }

      if(!validateEmail(data.email)) {
        return setError({
          title: t('errors.customer_information'),
          content: t(`errors.invalid_email`)
        })
      }

      if(data.mobile && data.mobile.length < 8) {
        return setError({
          title: t('errors.customer_information'),
          content: t(`errors.wrong_phone_format`),
        })
      }

      const res = await customerApi.createCustomer(data);
      if(!res.data) {
        return setError({
          title: t('errors.customer_exists'),
          content: t('errors.customer_email_exists')
        })
      }

      analytics.track("Agent Created Client", {
        agentId: authUser.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      history.push({
        search: `?customer=${res?.data?.id}`,
      });

      if (isShowCreateInvestorProfileModal) {
        dispatch(reqSetIsShowCreateInvestorProfileModal(false));
        notify(t("noti.customer_created"));
      }
      if (isShowCreateTenantProfileModal) {
        dispatch(reqSetIsShowCreateTenantProfileModal(false));
        notify(t("noti.customer_created"));
      }
      if (isShowCreateConsultantProfileModal) {
        dispatch(reqSetIsShowCreateConsultantProfileModal(false));
        notify(t("noti.customer_created"));
      }
      handleResetData();
      startDiscover();
    } catch (err) {
      setError(err);
    }
  };

  const handleClose = () => {
    history.push({
      search: "",
    });
    if (isShowCreateInvestorProfileModal) {
      dispatch(reqSetIsShowCreateInvestorProfileModal(false));
      dispatch(reqSetIsShowListInvestorModal(true));
    }
    if (isShowCreateTenantProfileModal) {
      dispatch(reqSetIsShowCreateTenantProfileModal(false));
      dispatch(reqSetIsShowListTenantModal(true));
    }
    if (isShowCreateConsultantProfileModal) {
      dispatch(reqSetIsShowCreateConsultantProfileModal(false));
      dispatch(reqSetIsShowListConsultantModal(true));
    }
    handleResetData();
  };

  const handCloseAllModal = () => {
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    handleResetData();
  };

  const handleResetData = () => {
    setEmailAddress("");
    setMobile({ value: "", country: "az" });
  };
  return (
    <Modal
      className="wrap-list-user-modal"
      show={
        isShowCreateInvestorProfileModal ||
        isShowCreateTenantProfileModal ||
        isShowCreateConsultantProfileModal
      }
      onHide={handCloseAllModal}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
        <div className="modal-form__title" style={{ fontSize: "28px" }}>
          {t("CreateUserProfileModal.title")}
        </div>
        <p className="mb-4 modal-form__sub-title">
          {t("CreateUserProfileModal.subTitle")}
        </p>
        <div className="wrapper-list-user">
          <div className="first-last-name">
            <div className="form-info">
              <label htmlFor="email">{t("CreateUserProfileModal.fn")}</label>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                id="firsName"
                placeholder={t("CreateUserProfileModal.fn")}
              />
            </div>
            <div className="form-info">
              <label htmlFor="email">{t("CreateUserProfileModal.ln")}</label>
              <input
                onChange={(e) => setLastName(e.target.value)}
                id="lastName"
                placeholder={t("CreateUserProfileModal.ln")}
              />
            </div>
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="address">
              {t("CreateUserProfileModal.Address")}
            </label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              id="address"
              placeholder={t("CreateUserProfileModal.Address")}
            />
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="mobile">{t("CreateUserProfileModal.Phone")}</label>
            <PhoneInput
              id="mobile"
              placeholder={t("CreateUserProfileModal.Phone")}
              mobile={mobile}
              setMobile={setMobile}
            />
          </div>
          <div className="form-info w-100 mt-4">
            <label htmlFor="email">{t("CreateUserProfileModal.Email")}</label>
            <input
              onChange={(e) => setEmailAddress(e.target.value)}
              id="email"
              placeholder={t("CreateUserProfileModal.Email")}
            />
          </div>
        </div>
        <div className="wrap-button-list-user d-flex justify-content-between">
          <button className="text-uppercase" onClick={handleCreateCustomer}>
            {t("common.Submit")}
          </button>
          <button className="text-uppercase" onClick={handleClose}>
            {t("common.Cancel")}
          </button>
        </div>
      </Modal.Body>
      <ErrorModal
        isShow={!!error}
        onClose={() => setError(undefined)}
        message={error}
      />
    </Modal>
  );
};

export default CreateUserProfileModal;
