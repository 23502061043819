import React  from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import icHome from "../../assets/images/icHome.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { cmsItems } from ".";

const TopNavCMS = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const {t} = useTranslation();
  const authUser = useSelector((state) => state.user.data);

  const getActiveClass = (path) => {
    return path === pathname ? "active" : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };
  return (
    <div className="wrap-top-nav show">
      <div className="nav-menu nav-menu-cms">
        <ul className="left">
          {cmsItems.filter(item => item.position === 'left').map((item, i) => {
            if (!item.roles.includes(authUser?.userGroup?.name) || !item.navTitle) return <></>;
            return (
              <li key={i} className={getActiveClass(item.path)}>
                <Link to={item.path} className="text-decoration-none">
                  <div>{t(item.navTitle)}</div>
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className="nav-cube">
          <div className="cube-scene-wrapper">
            <div className="cube-scene" onClick={() => history.push("/")}>
              {/*<div className="cube">*/}
              {/*  <div className="cube__face cube__face--front" />*/}
              {/*  <div className="cube__face cube__face--back" />*/}
              {/*  <div className="cube__face cube__face--right" />*/}
              {/*  <div className="cube__face cube__face--left" />*/}
              {/*  <div className="cube__face cube__face--top" />*/}
              {/*  <div className="cube__face cube__face--bottom" />*/}
              {/*</div>*/}
              <img src={icHome} alt="icon-cube" />
            </div>
          </div>
        </ul>
        <ul className="right">
          {cmsItems.filter(item => item.position === 'right').map((item, i) => {
            if (!item.roles.includes(authUser?.userGroup?.name) || !item.navTitle) return <></>;
            return (
              <li key={i} className={getActiveClass(item.path)} ref={item.ref}>
                <Link to={item.path} className="text-decoration-none">
                  <div className={`${item.divClassName || ''}`}>{t(item.navTitle)}</div>
                </Link>
              </li>
            );
          })}
          <li onClick={() => logout()}>
            <div>{t("Log out")}</div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
