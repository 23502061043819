import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icClose from "../../../assets/images/icClose.svg";
import "./index.scss";

const dataProjectName = [0, 1, 2, 3, 4, 5, 6, 7, 8];

const InFo = ({ title, content }) => {
  return (
    <div className="info-contact">
      <span className="title-contact">{title}</span>
      <span className="sub-contact">{content}</span>
    </div>
  );
};

const ProjectTeamModal = ({ show, onHide }) => {
  const [step, setStep] = useState(0); // 0: project team; 1: company name; 2: contact
  const handleClose = () => {
    setStep(0);
    onHide();
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="project-name">
              <span className="title-project">PROJECT TEAM</span>
              <div className="row">
                {dataProjectName.map((i, idx) => {
                  return (
                    <div key={`project-${idx}`} className="col-md-3 col-4 mb-4">
                      <div className={`card-project`}>
                        <div className="image-project" />
                        <span className="content-project">
                          {`EMPLOYEE NAME`}
                        </span>
                        <span className="content-project">
                          {`EMPLOYEE TITLE`}
                        </span>
                        <span className="content-project">{`OFFICE`}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-bottom" onClick={() => setStep(1)}>
              <span>COMPANY INFO & CONTACT</span>
            </div>
          </>
        );
      case 1:
        return (
          <div className="company">
            <div className="img-company" />
            <div className="content-company">
              <div className="header-company">
                <span className="title">COMPANY NAME</span>
                <span className="detail">
                  {`COMPANY CONTACT DETAILS \nCOMPANY CONTACT DETAILS`}
                </span>
                <span className="contact-company">www.binyanstudios.com</span>
              </div>
              <div className="bottom-company">
                <span onClick={() => setStep(2)}>CONTACT</span>
                <span onClick={() => setStep(0)}>PROJECT TEAM</span>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="contact">
            <span className="title">CONTACT</span>
            <div className="info">
              <InFo title="NAME" content="{USER NAME}" />
              <InFo title="EMAIL" content="{USER EMAIL}" />
              <InFo title="SUBJECT" content="Subject" />
            </div>
            <div className="detail">
              <span>
                {`Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Neque gravida in fermentum et sollicitudin ac orci
                phasellus egestas. Eleifend mi in nulla posuere sollicitudin
                aliquam ultrices sagittis orci. Tempus iaculis urna id volutpat.
                Aliquet enim tortor at auctor urna nunc id cursus metus. Erat
                nam at lectus urna duis convallis. Etiam erat velit scelerisque
                in dictum. Neque sodales ut etiam sit amet nisl purus. Placerat
                vestibulum lectus mauris ultrices eros in cursus turpis. Id
                faucibus nisl tincidunt eget nullam non nisi. Amet facilisis
                magna etiam tempor orci eu lobortis. Ultrices neque ornare
                aenean euismod elementum nisi quis eleifend quam. Libero enim
                sed faucibus turpis. Libero id faucibus nisl tincidunt eget
                nullam. Eu tincidunt tortor aliquam nulla.`}
              </span>
              <span>
                Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames. Justo nec
                ultrices dui sapien eget mi. Nulla pellentesque dignissim enim
                sit. Diam in arcu cursus euismod quis viverra nibh cras. Nisi
                quis eleifend quam adipiscing vitae proin. Venenatis lectus
                magna fringilla urna. Tristique senectus et netus et malesuada
                fames ac turpis. Dapibus ultrices in iaculis nunc.
              </span>
            </div>
            <div className="contact-bottom" onClick={handleClose}>
              <span>SEND</span>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      backdrop={false}
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="content-team-modal"
    >
      <Modal.Body>
        <div className="bg-close">
          <img src={icClose} alt="close-modal" onClick={handleClose} />
        </div>
        {renderContent()}
      </Modal.Body>
    </Modal>
  );
};

export default ProjectTeamModal;
