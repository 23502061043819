import MultiRangeSlider from "../multi-range-slider";
import { formatMoney } from "../../helper/unit";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef
} from "react";
import { throttle } from "lodash";

const FilterGroupRange = (
  { onChange, label, filterValue, icon = "$$$$", max },
  ref
) => {
  const isFirstLoad = useRef(true);
  const [value, setValue] = useState(filterValue);
  const [keyReset, setKeyReset] = useState(0);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    }
  }, [keyReset]);

  useImperativeHandle(ref, () => ({
    reset() {
      isFirstLoad.current = true;
      setKeyReset(keyReset + 1);
      setValue({ min: 0, max });
    }
  }));

  const onChangePrice = throttle((data) => {
    if (!isFirstLoad.current) {
      onChange && onChange(data);
    }
  }, 150)

  return (
    <div className="filter-group">
      <h2 className="heading">{label.title}</h2>
      <MultiRangeSlider
        key={keyReset}
        min={0}
        max={max}
        currentMin={value.min ?? 0}
        currentMax={value.max ?? max}
        onChange={onChangePrice}
      />
      <div className="d-flex justify-content-between flex-col gap-2">
        <div className="input-with-label">
          <small className="input-label">{label.min}</small>
          <input
            value={
              value?.min != null
                ? icon === "$$$$"
                  ? formatMoney(value.min)
                  : value.min
                : 0
            }
            onChange={() => {}}
            className="form-control rounded-0"
            type="text"
            placeholder={icon}
            disabled
          />
        </div>
        <div className="input-with-label">
          <small className="input-label">{label.max}</small>
          <input
            value={
              value?.max != null
                ? icon === "$$$$"
                  ? formatMoney(value.max)
                  : value.max
                : icon === "$$$$"
                ? formatMoney(max)
                : max
            }
            onChange={() => {}}
            className="form-control rounded-0"
            type="text"
            placeholder={icon}
            disabled
          />
        </div>
      </div>
    </div>
  );
};
export default forwardRef(FilterGroupRange);
