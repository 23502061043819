export const SET_PAGE = 'SET_PAGE';
export const SET_IS_SHOW_BOTTOM_NAV = 'SET_IS_SHOW_BOTTOM_NAV';
export const SET_IS_SHOW_IMMERSE = 'SET_IS_SHOW_IMMERSE';
export const SET_IS_EXPAND_NAV = 'SET_IS_EXPAND_NAV';
export const SET_ACTIVE_PANTOUR_ID = 'SET_ACTIVE_PANTOUR_ID';
export const SET_IS_SHOW_EXPLORE_MODAL = 'SET_IS_SHOW_EXPLORE_MODAL';
export const SET_IS_TRANSPARENT = 'SET_IS_TRANSPARENT';
export const SET_ACTIVE_GALLERY_ID = 'SET_ACTIVE_GALLERY_ID';
export const SET_ACTIVE_AREA_ID = 'SET_ACTIVE_AREA_ID';
export const SET_IS_SHOW_FUTURE_DETAIL = 'SET_IS_SHOW_FUTURE_DETAIL';
export const SET_IS_SHOW_SUB_PRECINCT_DETAIL =
  'SET_IS_SHOW_SUB_PRECINCT_DETAIL';
export const SET_IS_SHOW_TRANSPORT_DETAIL = 'SET_IS_SHOW_TRANSPORT_DETAIL';
export const SET_ACTIVE_DISTRICT_YEAR = 'SET_ACTIVE_DISTRICT_YEAR';
export const SET_IS_SHOW_LOGIN_MODAL = 'SET_IS_SHOW_LOGIN_MODAL';
export const SET_IS_SHOW_CITY_TRANSPORT_DETAIL =
  'SET_IS_SHOW_CITY_TRANSPORT_DETAIL';
export const SET_TRANSPORT_OPTIONS = 'SET_TRANSPORT_OPTIONS';
export const SET_TRANSPORT_OPTION_DISTRICTS = 'SET_TRANSPORT_OPTION_DISTRICTS';
export const SET_SUB_PRECINCTS = 'SET_SUB_PRECINCTS';
export const SET_ACTIVE_TRANSPORT_OPTION = 'SET_ACTIVE_TRANSPORT_OPTION';
export const SET_ACTIVE_TRANSPORT_OPTION_DISTRICTS =
  'SET_ACTIVE_TRANSPORT_OPTION_DISTRICTS';
export const SET_IS_SHOW_END_GUIDE_TENANT_SESSION =
  'SET_IS_SHOW_END_GUIDE_TENANT_SESSION';
export const SET_ACTIVE_END_GUIDE_TENANT_SESSION_ID =
  'SET_ACTIVE_END_GUIDE_TENANT_SESSION_ID';
export const SET_IS_SHOW_BOOKING_APPOINTMENT_FORM =
  'SET_IS_SHOW_BOOKING_APPOINTMENT_FORM';
export const SET_IS_PRESENTATION = "SET_IS_PRESENTATION";
export const SET_PAGE_CLICKED = "SET_PAGE_CLICKED";
