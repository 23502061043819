import * as type from "./type";

const initialState = {
  units: [],
  filteredUnits: [],
  unitAreas: [],
  selectedUnit: "",
  filterUnitAspect: "",
  filterUnitBedroom: [],
  filterUnitGrossRent: "",
  filterUnitCentre: "",
  filterUnitLevel: "",
  filterUnitArea: "",
  filterUnitPrecinct: "",
  filterUnitPrice: "",
  filterUnitLotSize: "",
  filterUnitRoomType: [],
  filterUnitAvailability: [],
  isShowFloorplan: false,
  isShowGallery: false,
  isShowUnitDetail: false,
  unitQuery: {},
  isShowFilter: true,
  isShowPrecinctDetail: false,
  isShowVirtualTour: false,
  isShowUnitList: false,
  activeDetailKeys: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_UNIT_LIST:
      return {
        ...state,
        units: action.data.data
      };
    case type.SET_FILTERED_UNIT: 
      return {
        ...state,
        filteredUnits: action.data
      }
    case type.GET_UNIT_AREA_LIST:
      return {
        ...state,
        unitAreas: action.data.data
      };
    case type.SET_IS_SHOW_FLOORPLAN:
      return {
        ...state,
        isShowFloorplan: action.data
      };
    case type.SET_IS_SHOW_GALLERY:
      return {
        ...state,
        isShowGallery: action.data
      };
    case type.SET_IS_SHOW_UNIT_DETAIL:
      return {
        ...state,
        isShowUnitDetail: action.data
      };
    case type.SET_SELECTED_UNIT:
      return {
        ...state,
        selectedUnit: action?.data?.data
      };
    case type.SET_UNIT_QUERY:
      return {
        ...state,
        unitQuery: action.data
      };
    case type.SET_IS_SHOW_FILTER:
      return {
        ...state,
        isShowFilter: action.data
      };
    case type.SET_IS_SHOW_PRECINCT_DETAIL:
      return {
        ...state,
        isShowPrecinctDetail: action.data
      };
    case type.FILTER_UNIT_ASPECT:
      return {
        ...state,
        filterUnitAspect: action.data
      };
    case type.FILTER_UNIT_PRICE:
      return {
        ...state,
        filterUnitPrice: action.data
      };
    case type.FILTER_UNIT_LOT_SIZE:
      return {
        ...state,
        filterUnitLotSize: action.data
      };
    case type.FILTER_UNIT_BEDROOM:
      return {
        ...state,
        filterUnitBedroom: action.data
      };
    case type.FILTER_UNIT_ROOM_TYPE:
      return {
        ...state,
        filterUnitRoomType: action.data
      };
    case type.FILTER_UNIT_AVAILABILITY:
      return {
        ...state,
        filterUnitAvailability: [...action.data]
      };
    case type.SET_IS_SHOW_VIRTUAL_TOUR:
      return {
        ...state,
        isShowVirtualTour: action.data
      };
    case type.SET_IS_SHOW_UNIT_LIST:
      return {
        ...state,
        isShowUnitList: action.data
      };
    case type.SHOW_UNIT_DETAIL_INFO:
      return {
        ...state,
        activeDetailKeys: [...state.activeDetailKeys, action.data]
      };
    case type.CLOSE_UNIT_DETAIL_INFO:
      const temp = state.activeDetailKeys.filter(x => x !== action.data)
      return {
        ...state,
        activeDetailKeys: temp
      };
    default:
      return state;
  }
};
