import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";

import "./virtual-modal.scss";
import { reqSetIsShowVirtualModal } from "../../reduxs/gallery/action";
import { getImagePath } from "../../helper/media";

const VirtualModal = (props) => {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.gallery.activeGallery);

  const loader = new ImageLoader();
  const [panos, setPanos] = useState([]);
  const [viewer, setViewer] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    onInitPanorama();

    return () => {
      // viewer?.dispose();
    };
  }, [modal]);

  const handleClickCancel = () => {
    viewer?.dispose();
    dispatch(reqSetIsShowVirtualModal(false));
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    let testPanos = [];
    if (modal == undefined || modal.media == undefined) {
      return;
    }
    const length = modal.media.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      testPanos.push(undefined);
    }
    setPanos(testPanos);

    const image1stURL = getImagePath(modal.media[0].path);
    loader.load(
      image1stURL,
      function (image) {
        let pano = new PANOLENS.ImagePanorama(image);

        panos[0] = pano;
        viewer.add(pano);
        viewer.setPanorama(pano);
        setPanos(panos);

        // for (let j = 0; j < firstChild.infospots.length; j++) {
        //   let spot = firstChild.infospots[j];
        //   let infospot = new PANOLENS.Infospot(
        //     spot.scale,
        //     spot.image
        //   );

        //   infospot.position.set( spot.position[0], spot.position[1], spot.position[2] );
        //   infospot.addEventListener( "click", () => {
        //     console.log(spot.targetIndex);
        //     setActiveIndex(spot.targetIndex);
        //     if (panos[spot.targetIndex] != undefined) {
        //       viewer.setPanorama(panos[spot.targetIndex]);
        //     }
        //   });

        //   pano.add(infospot);
        // }

        for (let index = 1; index < modal.media.length; index++) {
          const imageURL = getImagePath(modal.media[index].path);

          loader.load(
            imageURL,
            function (image) {
              let pano = new PANOLENS.ImagePanorama(image);

              panos[index] = pano;
              viewer.add(pano);
              setPanos(panos);

              // for (let j = 0; j < firstChild.infospots.length; j++) {
              //   let spot = child.infospots[j];
              //   let infospot = new PANOLENS.Infospot(
              //     spot.scale,
              //     spot.image
              //   );

              //   infospot.position.set( spot.position[0], spot.position[1], spot.position[2] );
              //   infospot.addEventListener( "click", function() {
              //     if (panos[spot.targetIndex] != undefined) {
              //       viewer.setPanorama(panos[spot.targetIndex]);
              //       setActiveIndex(spot?.targetIndex);
              //     }
              //   });
              //   infospot.show();

              //   pano.add(infospot);
              // }
            },
            undefined,
            function (error) {
              console.error("An error happened.", error);
            }
          );
        }
      },
      undefined,
      function (error) {
        console.error("An error happened.", error);
      }
    );

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoHideInfospot: false,
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);
  };

  const onClickDotItem = (index) => {
    if (panos[index] == undefined) return;

    const pano = panos[index];
    setActiveIndex(index);
    viewer.setPanorama(pano);
  };

  const renderDotted = () => {
    return (
      <div className="dots-container">
        <div className="wrap-dots">
          {(modal?.media || []).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onClickDotItem(index)}
                className={`dot-item ${activeIndex === index ? "active" : ""}`}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-modal">
        <div className="bg-image">
          <div id="wrap-panorama" className="img-panorama" />
          <button onClick={handleClickCancel} className="btn-square">
            <img className="img-fluid" src="/icons/close.svg" alt="" />
          </button>
          {renderDotted()}
        </div>
        <div className="black-content" />
      </div>
    </>
  );
};

export default VirtualModal;
