import UnitPage from '../pages/units';
import PATH from './path';
import Index from '../pages/Index';
import TestScene from '../components/3d-scene/TestScene';
import LoginPage from '../pages/auth/login';
import DashboardPage from '../pages/dashboard';
import NotFoundPage from '../pages/404';
import ForgotPassword from '../pages/auth/forgot-password';
import ResetPassword from '../pages/auth/reset-password';
import CustomerSession from '../pages/customer-session';
import LocationPage from '../components/location';
import HoldingPage from '../pages/holding';
import LoginEmailPage from '../pages/auth/login-email';
import CMS from '../pages/cms';
import Panorama from '../pages/360-pano';
import Presentation from "../pages/presentation";
import Session from '../components/session';

export const routeOptions = [
  {
    path: PATH.ROOT,
    name: 'Home',
    component: Index,
    roles: ['admin', 'investor', 'consultant', 'agent'],
    exact: true,
  },
  {
    path: PATH.LOGIN_SCREEN,
    name: 'Sign in',
    component: LoginPage,
    roles: [],
  },
  {
    path: PATH.FORGOT_PASSWORD,
    name: 'Forgot password',
    component: ForgotPassword,
    roles: [],
  },
  {
    path: PATH.RESET_PASSWORD,
    name: 'Reset password',
    component: ResetPassword,
    roles: [],
  },
  {
    path: PATH.UNIT_LIST_PAGE,
    name: 'Unit list',
    component: UnitPage,
    roles: ['admin'],
  },
  {
    path: PATH.TEST_PAGE,
    name: 'Test Page',
    component: TestScene,
    roles: [],
  },
  {
    path: PATH.DASHBOARD_PAGE,
    name: 'Dashboard',
    component: DashboardPage,
    roles: ['admin'],
  },
  {
    path: PATH.LOCATION,
    name: 'Location',
    component: LocationPage,
    roles: ['admin'],
  },
  {
    path: PATH.NOTFOUND_PAGE,
    name: 'Not found',
    component: NotFoundPage,
    roles: [],
  },
  {
    path: PATH.HODLDING_PAGE,
    name: 'holding',
    component: HoldingPage,
    roles: [],
  },
  {
    path: PATH.EMAIL_LOGIN_PAGE,
    name: 'Email Login',
    component: LoginEmailPage,
    roles: [],
  },
  {
    path: PATH.CMS_PAGE,
    name: 'cms',
    component: CMS,
    roles: ['admin', 'agent'],
  },
  {
    path: PATH.PANORAMA_PAGE,
    name: 'panorama',
    component: Panorama,
    roles: ['admin'],
  },
  {
    path: PATH.PRESENTATION_PAGE,
    name: 'Presentation Page',
    component: Presentation,
    roles: ['admin', 'agent'],
  },
  {
    path: PATH.CUSTOMER_SESSION_PAGE,
    name: 'customer session',
    component: Session,
    roles: [],
  },
];

export default routeOptions;
