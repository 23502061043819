import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PAGES } from "../../constants/options";
import { reqSetIsShowPostSessionPopup } from "../../reduxs/guide-session/action";
import { reqSetPage } from "../../reduxs/home/action";
import { actIntroduction } from "../../reduxs/scene/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import "./index.scss";

const PostSessionPopup = () => {
  const dispatch = useDispatch();
  const isShowPostSessionPopup = useSelector((state) => state.guideSession.isShowPostSessionPopup);
  const history = useHistory();

  const handleClose = () => {
    dispatch(reqSetIsShowPostSessionPopup(isShowPostSessionPopup));
  }

  const handleClickSendMail = () => {
    dispatch(reqSetIsShowPostSessionPopup(false));
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push('/');
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(''));
  }

  return (<Modal
    className="wrap-user-type-modal"
    show={isShowPostSessionPopup}
    onHide={handleClose}
    centered
    size="lg"
  >
    <Modal.Body className="wrap-modal-body">
      <div className="modal-form__title underline-sm mb-4" style={{fontSize : '25px'}}>
        POST SESSION URL
      </div>
      <p style={{fontSize : '18px'}} className="mb-0">A unique URL has been created for you: tdd.app/xWjf83Hu</p>
      <p style={{fontSize : '18px'}} className="mb-5">The email it will be sent to is: district@docklands.com</p>
      <div className="d-flex justify-content-between">
        <button style={{fontSize : '15px', height: '54px'}} className="btn btn-outline text-uppercase">
          <strong>EDIT EMAIL</strong>
        </button>
        <button onClick={handleClickSendMail} style={{fontSize : '15px'}} className="btn btn-outline text-uppercase">
          <strong>SEND TO EMAIL</strong>
        </button>
      </div>
    </Modal.Body>
  </Modal>)
}

export default PostSessionPopup;
