import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
  reqSetUserSelectTypeModal,
  reqSetIsShowListConsultantModal,
} from "../../reduxs/guide-session/action";
import closeIcon from "../../assets/images/close-white.svg";

import "./index.scss";

const UserTypeModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowUserTypeModal = useSelector(
    (state) => state.guideSession.isShowUserTypeModal
  );

  const handleClose = () => {
    dispatch(reqSetIsShowUserTypeModal(false));
  };

  const showListConsultantModal = () => {
    dispatch(reqSetIsShowListConsultantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("consultant"));
  };

  const showListInvestorModal = () => {
    dispatch(reqSetIsShowListInvestorModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("investor"));
  };

  const showListTenantModal = () => {
    dispatch(reqSetIsShowListTenantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("tenant"));
  };

  return (
    <>
      <Modal
        className="wrap-user-type-modal"
        show={isShowUserTypeModal}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="modal-form__title">{t("UserTypeModal.title1")}</div>
          <div className="modal-form__title">{t("UserTypeModal.title2")}</div>
          <p
            style={{
              fontSize: "14px",
              marginBottom: "40px",
              color: "#2D2927",
              fontFamily: "Proxima Nova",
            }}
          >
            {t("UserTypeModal.subTitle")}
          </p>
          <div className="btn-bottom-user-type">
            <button
              onClick={showListConsultantModal}
              className="text-uppercase"
            >
              <span>{t("UserTypeModal.btn1")}</span>
            </button>
            <button onClick={showListInvestorModal} className="text-uppercase">
              <span>{t("UserTypeModal.btn2")}</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserTypeModal;
