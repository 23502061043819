import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./login-email.scss";
import authApi from '../../apis/api/auth';
import { validatePassword } from '../../helper/validation';
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import jwtAuth from "../../apis/utils/jwtAuth";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginEmailPage = () => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});
  const token = useQuery().get('token');
  const userInfo = jwtDecode(token);

  useEffect(() => {
    if (validatePassword(password)) {
      delete message.password;
      setMessage({ ...message });
    } else {
      message.password =
        'The password must be minimum eight characters, at least one letter and one number.';
      setMessage({ ...message });
    }
  }, [password]);

  useEffect(() => {
    if (password === passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
    } else {
      message.passwordConfirmation = 'The password confirmation does not match.';
      setMessage({ ...message });
    }
  }, [passwordConfirmation]);

  const onSaveAndStartSession = async () => {
    try {
      await handleResetPassword();
      await handleLogin();
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleResetPassword = async () => {
    try {
      const res = await authApi.resetPassword({
        password: password,
        password_confirmation: passwordConfirmation,
        token: token,
      });
    } catch (error) {
      message.passwordConfirmation = res.message;
      setMessage({ ...message });
    }
  }

  const handleLogin = async () => {
    try {
      const data = {
        email: userInfo.email,
        password: password,
      };
      jwtAuth.removeToken();
      const res = await authApi.signIn(data);
      jwtAuth.setAccessToken(res.data.token);
      history.push('/');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="wrap-email-login-page h-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="modal-form">
          <h4 className="modal-form__title mb-0 text-uppercase">
            Welcome to the District
          </h4>
          <h4 className="modal-form__title mb-4 text-uppercase underline-sm">
            Docklands Experience App
          </h4>
          <p className="underline-text">
            Create a password to access your account
          </p>
          <div className="modal-form__body">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                value={userInfo.email}
                disabled={true}
                placeholder="{user's email address from link - non editable}"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Create New Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <small className="form-text text-danger">{message.password}</small>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Confirm Password"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <small className="form-text text-danger">{message.passwordConfirmation}</small>
            </div>
          </div>
          <div className="button-group d-flex align-items-center justify-content-between">
            <button
              type="submit"
              onClick={() => history.push("/holding")}
              className="btn btn-link text-uppercase"
            >
              returning user login here
            </button>
            <div>
              <button
                type="submit"
                className="cancel-button btn btn-link text-uppercase"
              >
                cancel
              </button>
              <button onClick={onSaveAndStartSession} type="submit" className="btn btn-link text-uppercase">
                save &amp; start session
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginEmailPage;
