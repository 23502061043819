import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faTimesCircle,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  reqSetIsShowBookingAppointmentForm,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { USER_GROUP } from "../../constants/master-data";
import {
  reqGetCustomerProfile,
  reqGetUserProfile,
} from "../../reduxs/user/action";
import preferenceApi from "../../apis/api/preference";
import { toast } from "react-toastify";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import jwtAuth from "../../apis/utils/jwtAuth";
import {
  reqSetIsShowLanguageModal,
} from "../../reduxs/guide-session/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { ReactComponent as Language } from "../../assets/images/language.svg";
import { ReactComponent as Logout } from "../../assets/images/icLogout.svg";
import { ReactComponent as Person } from "../../assets/images/iconPerson.svg";
import { classNames, clearFilterUnit } from "../../helper/utils";
import { StarIcon } from "../svgs/icons";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

library.add(
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faCalendarCheck,
  faTimesCircle
);
const BottomNavigation = ({
  handleClickEndGuideTenantSession,
  handleClickTenantEndSession,
  isPresentation,
  setActiveObjectIds
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const customerId = useQuery().get("customer");
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const preference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const authUser = useSelector((state) => state.user.data);
  const isShowEndGuideTenantSession = useSelector(
    (state) => state.home.isShowEndGuideTenantSession
  );
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.CLICK_END_SESSION) {
          handleShowPostSessionPopup();
        }
        if (content.action == ACTION_NAME.CLICK_LANGUAGE_BUTTON) {
          handleLanguages();
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId]);

  const onClickUserIcon = () => {
    window.location.href = "/cms";
  };

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleShowPostSessionPopup = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION)
    }
    setActiveObjectIds([])
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(PAGES.SESSION_PAGE));
    // dispatch(reqSetIsShowPostSessionPopup(true));
  };

  const sendToastMessage = (message, type) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type
      })
    }
    toast[type](message, {
      toastId: "socket-toast"
    });
  }

  const handleSavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage(t("errors.please_select_unit"), "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToastMessage("Saved Preference successfully!", "success");
          getCustomerPreference();
          analytics.track("Agent Saved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.mobile,
            propertyId: selectedUnit?.id,
          });
        })
      // .then((res) => {
      //   // dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      // });
    }
  };

  const handleUnsavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage(t("errors.please_select_unit"), "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToastMessage("Unsaved Preference successfully!", "success");
          getCustomerPreference();
          analytics.track("Agent Unsaved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.phone,
            propertyId: selectedUnit?.id,
          });
        })
      // .then((res) => {
      //   // dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      // });
    }
  };

  const getUnitIds = () => {
    const units = preference?.units || [];

    return units.map((item) => item.id);
  };

  const handleLogout = () => {
    clearFilterUnit()
    setActiveObjectIds([])
    jwtAuth.removeToken();
    history.push("/holding");
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
  };

  const handleLanguages = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LANGUAGE_BUTTON);
    }
    dispatch(reqSetIsShowLanguageModal(true));
  };

  const handleShowMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(true));
  };

  const handleCloseMakeAppointmentForm = () => {
    dispatch(reqSetIsShowBookingAppointmentForm(false));
  };

  const darkPage = [
    PAGES.ONBOARD_PAGE,
    PAGES.UNIT_EXPLORER_PAGE,
    PAGES.AMENITIES_PAGE,
    PAGES.LANDING_PAGE,
  ].includes(page);
  const renderLanguageBtn = () => {
    return (
      <div onClick={handleLanguages} className="locale-btn">
        <Language fill={darkPage && !isShowFloorplan ? "white" : "#01344B"} />
      </div>
    );
  };

  const handleClickEyeIcon = (isTransparent) => {
    dispatch(reqSetIsTransparent(!isTransparent));
  };

  const renderEyeIcon = () => {
    if (page !== PAGES.ONBOARD_PAGE) {
      return (
        <div>
          <span
            className="btn-item"
            onClick={() => handleClickEyeIcon(isTransparent)}
          >
            {!isTransparent ? (
              <img src="/icons/eye-open.svg" alt="" />
            ) : (
              <img src="/icons/eye-close.svg" alt="" />
            )}
          </span>
        </div>
      );
    }
  };

  const renderLogoutBtn = () => {
    if (!authUser) return;
    const fill = isShowFloorplan ? "#01344B"
      : darkPage ? "white"
        : "#01344B";

    return (
      <div
        onClick={handleLogout}
        className="cursor-pointer logout-btn"
        style={{ fontSize: "20px" }}
      >
        <Logout fill={fill} />
      </div>
    );
  };

  const renderMakeAppointmentBtn = () => {
    if (!authUser) return;

    if (isShowBookingAppointmentForm) {
      return (
        <span
          onClick={handleCloseMakeAppointmentForm}
          className="me-2 cursor-pointer"
          style={{ fontSize: "20px" }}
        >
          <FontAwesomeIcon icon={["fas", "times-circle"]} />
        </span>
      );
    }

    return (
      <span
        onClick={handleShowMakeAppointmentForm}
        className="me-2 cursor-pointer"
        style={{ fontSize: "20px" }}
      >
        <FontAwesomeIcon icon={["fas", "calendar-check"]} />
      </span>
    );
  };

  const renderCustomerName = () => {
    if (page !== PAGES.ONBOARD_PAGE) {
      return (
        <span className="text-customer" style={
          darkPage && !isShowFloorplan
            ? { color: "#ffffff" }
            : { color: "#01344B" }
        }>
          {customer ? `${customer?.firstname} ${customer?.surname}` : t('CUSTOMER NAME')}
        </span>
      );
    }
  };

  const renderUserBtn = () => {
    if (page === PAGES.ONBOARD_PAGE && !isShowReplayVideo) {
      return (
        <span className="btn-item" onClick={() => onClickUserIcon()}>
          <Person fill={page === PAGES.ONBOARD_PAGE ? "white" : "black"} />
        </span>
      );
    }
  };

  const renderStarBtn = () => {
    if (page === PAGES.ONBOARD_PAGE) return null;
    const unitIds = getUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span className="btn-item" onClick={() => handleUnsavePreference()}>
          <StarIcon style={{ opacity: 1 }} fill="#59899D" />
        </span>
      );
    }

    return (
      <span className="btn-item" onClick={() => handleSavePreference()}>
        <StarIcon style={{ opacity: selectedUnit ? 1 : 0.25 }} />
      </span>
    );
  };

  const renderEndSessionBtn = () => {
    if (!customer) return;

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      !isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="btn-end"
          style={
            darkPage && !isShowFloorplan
              ? { color: "#ffffff" }
              : { color: "#01344B" }
          }
          onClick={() => handleClickEndGuideTenantSession(customerId)}
        >
          {t("common.endGuided")}
        </span>
      );
    }

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="btn-end"
          style={
            darkPage && !isShowFloorplan
              ? { color: "#ffffff" }
              : { color: "#01344B" }
          }
          onClick={() => handleClickTenantEndSession()}
        >
          {t("common.endGuided")}
        </span>
      );
    }

    return (
      <span
        className="btn-end"
        style={
          darkPage && !isShowFloorplan
            ? { color: "#ffffff" }
            : { color: "#01344B" }
        }
        onClick={() => handleShowPostSessionPopup()}
      >
        {t("common.endGuided")}
      </span>
    );
  };

  if (isPresentation) {
    return null
  }

  return (
    <>
      {isShowFloorplan && <div className="outline" />}
      <div
        className={classNames(
          "wrap-bottom-navigation",
          page === PAGES.VIEWS_LEVEL ? "linear-bottom" : "",
          darkPage ? "" : "light",
          page === PAGES.SESSION_PAGE && "hide-bottom",
          isShowFloorplan && "is-show-floorplan",
        )}
        style={
          page === PAGES.ONBOARD_PAGE ? { backgroundColor: "transparent" } : {}
        }
      >
        <div className="left-group-btn">
          {/*{false && (*/}
          {/*  <span*/}
          {/*    className="btn-item"*/}
          {/*    onClick={() => dispatch(reqSetIsTransparent(!isTransparent))}*/}
          {/*  >*/}
          {/*    {isTransparent ? (*/}
          {/*      <FontAwesomeIcon icon={["fas", "eye-slash"]} size="2x" />*/}
          {/*    ) : (*/}
          {/*      <FontAwesomeIcon icon={["fas", "eye"]} size="2x" />*/}
          {/*    )}*/}
          {/*  </span>*/}
          {/*)}*/}
          {renderLanguageBtn()}
          {/*{renderEyeIcon()}*/}
          {!customer && renderLogoutBtn()}
          {/*{false && renderMakeAppointmentBtn()}*/}
          {renderEndSessionBtn()}
        </div>
        <div className="right-group-btn">
          {renderCustomerName()}
          {renderStarBtn()}
          {renderUserBtn()}
        </div>
      </div>
    </>
  );
};

export default BottomNavigation;
