import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { reqSetIsShowLoginModal } from '../../reduxs/home/action';

const Login = (props) => {
  const dispatch = useDispatch();
  const isShowLoginModal = useSelector((state) => state.home.isShowLoginModal);

  const handleClose = () => {
    dispatch(reqSetIsShowLoginModal(false))
  };

  return (
  <Modal show={isShowLoginModal} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title>Login</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email address</label>
        <input type="email" className="form-control" id="email" placeholder="name@example.com" />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">Password</label>
        <input type="password" className="form-control" id="password" />
      </div>
    </Modal.Body>

    <Modal.Footer>
      <button className="btn btn-secondary" onClick={handleClose}>Close</button>
      <button className="btn btn-primary" >Login</button>
    </Modal.Footer>
  </Modal>
  )
}

export default Login;
