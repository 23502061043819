import React from "react";
import './index.scss';
import { useTranslation } from "react-i18next";


const CloseIcon = ({ onClick }) => {
  const { t } = useTranslation();
	return(
    <div className='close-component' onClick={onClick}>
      <img className="img-fluid" src="/icons/icClose.svg" alt="" /> <span>{t('CLOSE')}</span>
    </div>
	)
};

export default CloseIcon;
