import React from "react";
import "./phone-input.scss";
import Select from "react-select";
import enLang from "./lang/en.json";
import azLang from "./lang/az.json";
import ruLang from "./lang/ru.json";
import { getCountryCallingCode } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const phoneNumberLanguageMapping = {
  'en': enLang,
  'az': azLang,
  'ru':ruLang
};

export const getSafeCountryCallingCode = (data = '') => {
  try {
    return getCountryCallingCode(data?.toUpperCase())
  } catch (e) {
    console.warn(e)
  }
  return ''
}

export const CountryOptions = (lang = 'en') => Object.entries(phoneNumberLanguageMapping[lang])
  ?.filter(([k]) => !!getSafeCountryCallingCode(k))
  ?.map(([k, v]) => ({
    value: k, label: `${v} (+${getSafeCountryCallingCode(k)})`,
  }));

export const PhoneInput = ({mobile, setMobile, ...rest}) => {
  const [menuIsOpen, setMenuOpen] = React.useState(false)
  const { i18n } = useTranslation();
  const onChangeText = (e) => setMobile({...mobile, value: e.target.value})

  return (
    <div className='phone-input'>
      <div className="flag">
        <Select
          classNamePrefix="select"
          menuIsOpen={menuIsOpen}
          isDisabled={false}
          isSearchable={true}
          name="type"
          options={CountryOptions(i18n.language)}
          onChange={(item) => {
            setMenuOpen(false)
            setMobile({...mobile, country: item?.value})
          }}
        />
      </div>
      <div className="flag" onClick={() => setMenuOpen(!menuIsOpen)}>
        <div className={`country ${mobile?.country || 'hidden'}`}/>
        <div className="country-code">+{getSafeCountryCallingCode(mobile?.country)}</div>
        <div className="arrow"/>
      </div>
      <input
        type={'number'}
        defaultValue={mobile?.value ?? '' }
        onChange={onChangeText}
        {...rest}
      />
    </div>
  );
};
