import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  reqGetListUnits,
} from "../../../reduxs/cms/action";
import Row from "./row";
import unitApi from "../../../apis/api/unit";
import { toast } from "react-toastify";
import { useIsMount } from "../../../helper/use-is-mount";
import SearchIcon from "../components/Search";
import UpdateResidenceModal from "./UpdateResidenceModal";
import hubspotSettingsApi from "../../../apis/api/hubspotSettings";

const CMSResidence = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedData, setSelectedData] = useState()
  const [hubspotSettings, setHubspotSettings] = useState({});

  const isMount = useIsMount();

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    if (!isMount) getListProperties();
  }, [search, isSortAsc])

  useEffect(() => {
    getHubspotSettings();
  }, []);

  const getHubspotSettings = async () => {
    try {
      const res = await hubspotSettingsApi.getHubspotSettings();
      if (res && res.data) {
        setHubspotSettings(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const renderListProperty = () => {
    return (units || []).map((item, index) => {
      return (
          <Row
            key={index}
            data={item}
            index={index}
            onClickDetail={() => setSelectedData(item)}
          />
      );
    });
  };

  const handleUpdate = async (data) => {
    try {
      await unitApi.putUnitUpdate(data.id, data)
      await getListProperties()
      toast.success("Unit updated successfully")
    } catch (error) {
      
    }
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  } 

  return (
    <>
      <UpdateResidenceModal 
        show={!!selectedData} 
        data={selectedData} 
        onSave={handleUpdate} 
        onHide={() => setSelectedData(undefined)}
      />
      <div className="page-container">
        <div className="page-header d-flex align-items-center">
          <h1 className="f-title mb-0">{t("RESIDENCES CMS")}</h1>
        </div>
        <div className="text-center p-2">Last Synced timestamp: {hubspotSettings.lastSyncedUnitsTimestamp}</div>
        <div className="page-body residence">
          <div className="table-responsive-custom">
            <table className="table-custom">
              <thead>
                <tr
                  style={{
                    display: "table",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <th className="col-1" />
                  <th className="col-3">{t("Residence")}</th>
                  <th className="col-2">
                    {t("Level")}
                  </th>
                  <th className="col-4">
                    {t("Availability")}
                  </th>
                  <th className="col-4">
                    {t("Lot Size")}
                  </th>
                  <th className="col-2">{t("Beds")}</th>
                  <th className="col-3">{t("Aspect")}</th>
                  <th className="col-3">
                    {t("Price")}
                  </th>
                  <th className="col-3">
                    {t("Service")}
                  </th>
                  <th className="col-3">
                    {t("Description")}
                  </th>
                  <th className="col-3">
                    HubSpot ID
                  </th>
                  <th className="col-2" />
                </tr>
              </thead>
              <tbody
                style={{
                  display: "block",
                  maxHeight: "calc(100vh - 400px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                  {renderListProperty()}
              </tbody>
            </table>
          </div>
        </div>
        <SearchIcon handleInputChange={onSearch} />
      </div>
    </>
  );
};

export default CMSResidence;
