import * as Types from './type';
import galleryApi from '../../apis/api/gallery';

const actSetIsShowFutureDetail = (data) => ({
  type: Types.SET_IS_SHOW_FUTURE_DETAIL,
  data,
});

const actSetIsShowGalleryModal = (data) => ({
  type: Types.SET_IS_SHOW_GALLERY_MODAL,
  data,
});

const actSetActiveGalleryModal = (data) => ({
  type: Types.SET_ACTIVE_GALLERY_MODAL,
  data,
});

export const reqSetIsShowFutureDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowFutureDetail(data));
};

export const reqSetIsShowGalleryModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowGalleryModal(data));
};

export const reqSetActiveGalleryModal = (id) => (dispatch) => {
  if (!id) return;

  return galleryApi.getGalleryDetail(id).then((data) => {
    dispatch(actSetActiveGalleryModal(data));
  });
};
