import React from "react";
import { useState } from "react";
import "./index.scss";
import { ACTION_NAME, PAGES } from "../../constants/options";
import { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import iconSearch from "../../assets/images/search.svg";
import iconGallerySearch from "../../assets/images/gallery_search.svg";
import icBaku from "../../assets/images/baku_logo.svg";
import icBakuSmall from "../../assets/images/baku_logo_small.png";
import icBakuSmallBlack from "../../assets/images/baku_logo_small_black.png";
import { classNames } from "../../helper/utils";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    handleClickCube,
    handleClickGallery,
    handleTeam,
    handleSearch,
    handleAmenities,
    handleServices,
    handleClickResidences,
    handleClickImmerse,
    isPresentation,
  } = props;
  const { t } = useTranslation();
  const [isCollapse, setIsCollapse] = useState(false);
  const [firstShow, setFirstShow] = useState(true);
  const [isDragDown, setIsDragDown] = useState(false);
  const [isHideCubeOnDrag, setIsHideCubeOnDrag] = useState(true);
  const pageActive = useSelector((state) => state.home.page);
  const [linksDisabled, setLinksDisabled] = useState(false);
  const dispatch = useDispatch();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );

  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const page = useSelector((state) => state.home.page);

  const onClickCube = () => {
    handleClickCube();
    setIsCollapse(false);
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  const startDrag = () => {
    setIsHideCubeOnDrag(false);
  };

  const endDrag = () => {
    setIsHideCubeOnDrag(true);
    setIsDragDown(true);
    setFirstShow(false);
    setIsCollapse(!isCollapse);
  };

  const isActive = (menu) => {
    return pageActive === menu ? "active" : "";
  };

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    const resetEvent = new CustomEvent("REST_STATE");
    document.dispatchEvent(resetEvent);
    setLinksDisabled(false);
  };

  const PageOnboardTopNav = () => (
    <div className="nav-cube-onboard">
      <img src={icBaku} alt="icon-cube" onClick={onClickCube} />
    </div>
  );

  if(isPresentation) {
    return null
  }

  if (page === PAGES.ONBOARD_PAGE && !isShow && !isShowReplayVideo) {
    return <PageOnboardTopNav />;
  }
  return (
    <div
      className={classNames(
        "wrap-top-nav body-container",
        isCollapse ? "hide" : "show",
        page === PAGES.VIEWS_LEVEL ? "linear-top" : "",
        isActive(PAGES.UNIT_EXPLORER_PAGE) ||
          isActive(PAGES.LANDING_PAGE) ||
          isActive(PAGES.AMENITIES_PAGE)
          ? isShowFloorplan
            ? "gallery-main-canvas"
            : "main-canvas"
          : ""
      )}
      style={
        page === PAGES.ONBOARD_PAGE ? { backgroundColor: "transparent" } : {}
      }
    >
      {(isShow || isShowReplayVideo) && (
        <>
          <img
            className="icon-cube-small"
            src={isShowFloorplan ? icBakuSmallBlack : icBakuSmall}
            alt="icon-cube-small"
            onClick={onClickCube}
          />
          <ul className="nav-right">
            <li
              className={isActive(PAGES.UNIT_EXPLORER_PAGE)}
              onClick={handleClickResidences}
            >
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.RESIDENCES")}
              </span>
            </li>
            <li className={isActive(PAGES.TEAM_PAGE)} onClick={handleTeam}>
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.FEATURE")}
              </span>
            </li>
            <li
              className={isActive(PAGES.AMENITIES_PAGE_V2)}
              onClick={handleAmenities}
            >
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.AMENITIES")}
              </span>
            </li>
            {/* <li
              className={isActive(PAGES.VIEWS_PAGE)}
              onClick={handleClickImmerse}
            >
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.VIEWS")}
              </span>
            </li> */}
            <li
              className={isActive(PAGES.SERVICES_PAGE)}
              onClick={handleServices}
            >
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.SERVICES")}
              </span>
            </li>
            <li
              className={isActive(PAGES.GALLERY_LANDING_PAGE)}
              onClick={handleClickGallery}
            >
              <span className={classNames(isShowFloorplan ? "black" : "white")}>
                {t("common.MEDIA")}
              </span>
            </li>
            {/* <li onClick={handleSearch}>
              <img
                src={isShowFloorplan ? iconGallerySearch : iconSearch}
                alt="search-icon"
                style={{ width: "28px" }}
              />
            </li> */}
          </ul>
        </>
      )}
    </div>
  );
};

export default TopNav;
