import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import customerApi from '../../apis/api/customer';
import { toLocalDateTime } from '../../helper/date';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";

const CustomerList = () => {
  let { url } = useRouteMatch();
  const [customers, setCustomers] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    getListCustomers();
  }, []);

  const getListCustomers = async () => {
    const result = await customerApi.getCustomerList();
    setCustomers(result?.data || []);
  };

  const deleteCustomer = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this customer info!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await customerApi.deleteCustomer(id);
        swal('Poof! Your customer has been deleted!', {
          icon: 'success',
        }).then(() => getListCustomers());
      }
    });
  };

  return (
    <div className="card wrap-list-customers">
      <div className="card-header d-flex">
        <h6 className="title">LIST CUSTOMERS</h6>{' '}
        <Link to={`${url}/create`} className="btn-text">
          CREATE
        </Link>
      </div>
      <div className="card-body">
        <input type="text" className="form-control" placeholder="Search..." />
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Firstname</th>
                <th scope="col">Surname</th>
                <th scope="col">Mobile</th>
                <th scope="col">{t('Email')}</th>
                <th scope="col">Agent</th>
                <th scope="col">Note</th>
                <th scope="col">Created By</th>
                <th scope="col">Created Date/Time</th>
                <th scope="col">Modified</th>
                <th scope="col">Last login</th>
                <th scope="col" />
                <th scope="col" />
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                return (
                  <tr key={index}>
                    <td>{customer.firstname}</td>
                    <td>{customer.surname}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>
                      {(customer.agents || []).map((agent, index) => (
                        <span key={index} className="badge bg-secondary">
                          {agent?.name}
                        </span>
                      ))}
                    </td>
                    <td>{customer?.note}</td>
                    <td>{customer?.created_by}</td>
                    <td>{toLocalDateTime(customer?.createdAt)}</td>
                    <td>{toLocalDateTime(customer?.updatedAt)}</td>
                    <td>{customer?.lastLogin}</td>
                    <td>
                      <span className="btn-text">Session</span>
                    </td>
                    <td>
                      <Link to={`${url}/${customer.id}/profile`} className="btn-text">
                        View
                      </Link>
                    </td>
                    <td>
                      <Link
                        to="#"
                        onClick={() => deleteCustomer(customer?.id)}
                        className="btn-text"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
