import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getImagePath } from "../../helper/media";
import { reqGetGalleryList, reqSetActiveGallery, reqSetIsShowVirtualModal } from "../../reduxs/gallery/action";
import Fancybox from "./fancy-box";
import './index.scss';

const HomeGallery = () => {
  const dispatch = useDispatch();
  const galleries = useSelector((state) => state.gallery.galleries);
  const [isShowFancyBox, setIsShowFancyBox] = useState(false);

  useEffect(() => {
    dispatch(reqGetGalleryList());
  }, []);

  useEffect(() => {
    if (isShowFancyBox) {
      const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
      fancyBoxRef.on('destroy', (e) => setIsShowFancyBox(false));
    }
  }, [isShowFancyBox]);

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.next();
  }

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.prev();
  }

  const onCloseFancyBox = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    setIsShowFancyBox(false);
    fancyBoxRef?.close();
  }

  const onShowVirtualModal = (galleryId) => {
    dispatch(reqSetIsShowVirtualModal(true));
    dispatch(reqSetActiveGallery(galleryId))
  }

  const renderGalleryList = () => {
    return (galleries || []).map((item, index) => {
      if (item.type === 'image') {
        return (
          <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-4 mb-4">
            <Fancybox key={index} setIsShowFancyBox={setIsShowFancyBox} options={{ infinite: true, mainClass: 'wrap-fancy-box', showNavArrows: false }}>
              <div className="gallery-item">
                <img className="gallery-thumbnail img-fluid" src={getImagePath(item.thumbnail)} alt="" data-fancybox="gallery" />
                <div className="gallery-name">{item.name}</div>
                <div className="wrap-list-image">
                  {renderMedia(item.media)}
                </div>
                <div className="gallery-type">
                  <img className="img-fluid" src="/icons/image-solid.svg" alt="" />
                </div>
              </div>
            </Fancybox>
          </div>
        )
      }

      if (item.type === 'virtual_tour') {
        return (
          <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-4 mb-4">
            <div className="gallery-item" onClick={() => onShowVirtualModal(item.id)}>
              <img className="gallery-thumbnail img-fluid" src={getImagePath(item.thumbnail)} alt="" />
              <div className="gallery-name">{item.name}</div>
              <div className="gallery-type">
                <img className="img-fluid" src="/icons/virtual-solid.svg" alt="" />
              </div>
            </div>
          </div>
        )
      }

      return (
        <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-4 mb-4">
          <div className="gallery-item">
            <img className="gallery-thumbnail img-fluid" src={getImagePath(item.thumbnail)} alt="" />
            <div className="gallery-name">{item.name}</div>
            <div className="gallery-type">
              <img className="img-fluid" src="/icons/video-solid.svg" alt="" />
            </div>
          </div>
        </div>
      )
    });
  }

  const renderMedia = (items) => {
    return (items || []).map((item, index) => {
      return (
        <div key={index} onClick={() => setIsShowFancyBox(true)} className={`card border-0`} style={{ backgroundImage: `url(${getImagePath(item.path)})` }} data-fancybox="gallery" data-src={getImagePath(item.path)} />
      )
    })
  }

  return (
    <>
      <div className="wrap-home-gallery body-container">
        <div className="wrap-gallery-content">
          <div className="gallery-header">
            <div className="title">GALLERY</div>
          </div>
          <div className="gallery-body">
            <div className="row">
              {renderGalleryList()}
            </div>
          </div>
        </div>
      </div>
      {
        isShowFancyBox && (
          <>
            <div className="wrap-btn-fancy">
              <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
                <img className="img-fluid" src="./icons/left-arrow.svg" alt="" />
              </div>
              <div className="btn-next cursor-pointer" onClick={onNextImage}>
                <img className="img-fluid" src="./icons/right-arrow.svg" alt="" />
              </div>
            </div>
            <div className="btn-close-fancy cursor-pointer" onClick={onCloseFancyBox}>
              <img className="img-fluid" src="./icons/close.svg" alt="" />
            </div>
          </>
        )
      }
    </>
  )
}

export default HomeGallery;
