import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

import ServicesModal from "../amenitiesV2/component/services-modal";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { ChevronDown, ChevronUp } from "../svgs/icons";
import { handleCollapse } from "../../helper/utils";

import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import imageServices1 from "../../assets/images/image-services-1.jpg";
import imageServices2 from "../../assets/images/image-services-2.jpg";
import imageServices3 from "../../assets/images/image-services-3.jpg";

const getSectionList = (t) => ({
  hotel: { header: t("Services.hotel"), id: "id_hotel" },
  exclusive: { header: t("Services.exclusive"), id: "id_exclusive" },
  privileged: { header: t("Services.privileged"), id: "id_privileged" },
});

const getStandardServicesItems = (t) => ({
  standardServicesCol1: {
    item1: t("Services.standardServicesCol1.item1"),
    item2: t("Services.standardServicesCol1.item2"),
    item3: t("Services.standardServicesCol1.item3"),
    item4: t("Services.standardServicesCol1.item4"),
    item5: t("Services.standardServicesCol1.item5"),
    item6: t("Services.standardServicesCol1.item6"),
    item7: t("Services.standardServicesCol1.item7"),
    item8: t("Services.standardServicesCol1.item8"),
  },
  standardServicesCol2: {
    item1: t("Services.standardServicesCol2.item1"),
    item2: t("Services.standardServicesCol2.item2"),
    item3: t("Services.standardServicesCol2.item3"),
    item4: t("Services.standardServicesCol2.item4"),
    item5: t("Services.standardServicesCol2.item5"),
    item6: t("Services.standardServicesCol2.item6"),
    item7: t("Services.standardServicesCol2.item7"),
  },
  standardServicesCol3: {
    item1: t("Services.standardServicesCol3.item1"),
    item2: t("Services.standardServicesCol3.item2"),
    item3: t("Services.standardServicesCol3.item3"),
    item4: t("Services.standardServicesCol3.item4"),
    item5: t("Services.standardServicesCol3.item5"),
    item6: t("Services.standardServicesCol3.item6"),
  },
});

const getStandardPrivilegedItems = (t) => ({
  standardPrivilegedCol: {
    item1: t("Services.standardPrivilegedCol.item1"),
    item2: t("Services.standardPrivilegedCol.item2"),
    item3: t("Services.standardPrivilegedCol.item3"),
    item4: t("Services.standardPrivilegedCol.item4"),
    item5: t("Services.standardPrivilegedCol.item5"),
    item6: t("Services.standardPrivilegedCol.item6"),
    item7: t("Services.standardPrivilegedCol.item7"),
    item8: t("Services.standardPrivilegedCol.item8"),
  },
});

const getStandardExclusiveItems = (t) => ({
  item1: t("Services.standardExclusiveCol.item1"),
  item2: t("Services.standardExclusiveCol.item2"),
  item3: t("Services.standardExclusiveCol.item3"),
  item4: t("Services.standardExclusiveCol.item4"),
  item5: t("Services.standardExclusiveCol.item5"),
  item6: t("Services.standardExclusiveCol.item6"),
  item7: t("Services.standardExclusiveCol.item7"),
  item8: t("Services.standardExclusiveCol.item8"),
});

const Services = ({isPresentation}) => {
  const [isShowServicesModal, setShowServicesModal] = useState(false);
  const { t } = useTranslation();


  const standardServicesItems = getStandardServicesItems(t);
  const standardPrivilegedItems = getStandardPrivilegedItems(t);
  const standardExclusiveItems = getStandardExclusiveItems(t);
  const itemCountPerColumn = 4;
  const values = Object.values(standardExclusiveItems);
  const columns = Array.from({ length: 2 }, (_, index) =>
    values?.slice(index * itemCountPerColumn, (index + 1) * itemCountPerColumn)
  );

  const [activeKeys, setActiveKeys] = useState([])

  const pageRef = useRef(null)
  
  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if(content.action === ACTION_NAME.OPEN_FEATURE_DETAIL) {
          handleOpenFeature(content.data.currentActiveKeys, content.data.section)
        }
        if(content.action === ACTION_NAME.CLOSE_FEATURE_DETAIL) {
          handleCloseFeature(content.data.currentActiveKeys, content.data.section)
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      })
    }
  }, [isPresentation])

  const handleToggle = (section) => {
    if(activeKeys.includes(section)) {
      handleCloseFeature(activeKeys, section)
    } else {
      handleOpenFeature(activeKeys, section)
    }
  }

  const handleOpenFeature = (currentActiveKeys, section) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.OPEN_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    setActiveKeys([...currentActiveKeys, section])
  }

  const handleCloseFeature = (currentActiveKeys, section) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    const temp = currentActiveKeys.filter(x => x!== section)
    setActiveKeys(temp)
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf services-page ${isPresentation && "presentation"}`}>
      <TopNavTitle title={t("common.SERVICES")} />
      <div className="main-content">
        <div className="content-1">
          <div className="header">{t("Services.header")}</div>
        </div>
        <img
          style={{ width: "100%", height: "100%"}}
          alt="imageServices1"
          src={imageServices1}
        />
        <div className='content-2'>
          <div className='wrapper'>
            <div className='tg-container'>
              <div className='tg-text'>
                <div className='tg-header'>{t('Services.pashaHeader')}</div>
                <div className='tg-body'>{t('Services.pashaBody')}</div>
              </div>
              <img className='img-2' src={imageServices2}/>
            </div>
            <img className='img-3' src={imageServices3}/>
          </div>
        </div>
        <div className="content-4">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardServices")}
            >
              <span >
                {t("Services.header2")}
              </span>
              {
                activeKeys.includes("standardServices") ? 
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                 :     
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div
              className={`tg-body collapse standardServices ${activeKeys.includes("standardServices") && "show"}`}
              id="standardServices"
            >
              <div>{t("Services.paragraph2")}</div>
            </div>
          </div>
        </div>
        <div className={`content-5 standardServices ${activeKeys.includes("standardServices") && "show"}`}>
          {Object.entries(standardServicesItems).map(([groupKey, groupVal]) => (
            <div key={groupKey} className="">
              <ul className="ctg-col">
                {Object.entries(groupVal).map(([itemKey, itemVal]) => (
                  <li key={itemKey}>
                    <div>{itemVal}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="content-8">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardHotel")}
            >
              <span>{t("Services.hotel")}</span>
              {
                activeKeys.includes("standardHotel") ? 
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                 :     
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div className={`tg-body collapse standardHotel ${activeKeys.includes("standardHotel") && "show"}`} id="standardHotel">
              <div>{t("Services.hotelHeading")}</div>
            </div>
          </div>
        </div>
        <div className={`content-9 standardHotel ${activeKeys.includes("standardHotel") && "show"}`}>
          <div className="content">
            <div className="content-message">
              <div className="paragraph">
                {[...Array(6)].map((_, index) => (
                  <div key={`markdown-paragraph-${index}`}>
                    <Markdown>
                      {t(`Services.markdown_paragraph2_${index + 1}`)}
                    </Markdown>
                  </div>
                ))}
              </div>
              <div className="para-note">
                {t(`Services.markdown_paragraph_note`)}
              </div>
            </div>
          </div>
        </div>
        <div className="content-6">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardExclusive")}
            >
              <span>
                {t("Services.exclusive")}
              </span>
              {
                activeKeys.includes("standardExclusive") ? 
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                 :     
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
          </div>
        </div>
        <div className={`content-7 standardExclusive ${activeKeys.includes("standardExclusive") && "show"}`}>
          {columns.map((column, columnIndex) => (
            <ul key={columnIndex} className="column">
              {column?.map((item, itemIndex) => (
                <li key={itemIndex} sx={{ marginTop: "10px", width: "50%" }}>
                  {item}
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="content-4">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardPrivileged")}
            >
              <span>
                {t("Services.privileged")}
              </span>
              {
                activeKeys.includes("standardPrivileged") ? 
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                 :     
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div
              className={`tg-body collapse standardPrivileged ${activeKeys.includes("standardPrivileged") && "show"}`}
              id="standardPrivileged"
            >
              <div className="">{t("Services.paragraph5")}</div>
            </div>
          </div>
        </div>
        <div className={`content-5 standardPrivileged ${activeKeys.includes("standardPrivileged") && "show"}`}>
          {Object.entries(standardPrivilegedItems).map(
            ([groupKey, groupVal]) => (
              <div key={groupKey}>
                <ul className="privileged">
                  {Object.entries(groupVal).map(([itemKey, itemVal]) => (
                    <li key={itemKey}>{itemVal}</li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
      {
        !isPresentation && <div className="bottom-top" />
      }
      <ServicesModal
        isShowServicesModal={isShowServicesModal}
        onHide={() => setShowServicesModal(false)}
      />
    </div>
  );
};

export default Services;
