import React, { useEffect, useState } from "react";
import './index.scss'

const SearchIcon = ({ handleInputChange }) => {
	const [isOpenSearch, setIsOpenSearch] = useState(false);

	useEffect(() => {
		!isOpenSearch && handleInputChange?.("");
	}, [isOpenSearch])

	const handleOpenSearch = () => {
		setIsOpenSearch(!isOpenSearch);
	}

	return(
		<div
			className={`search-component ${isOpenSearch ? "open-search" : ""}`}
		>
			<img className="img-fluid" src="/icons/icSearch.svg" alt="" onClick={handleOpenSearch}/>
			{
				isOpenSearch && <input type="text" onChange={(e) => handleInputChange?.(e)}/>
			}
		</div>
	)
};

export default SearchIcon;
