import * as Types from './type';
import preferenceApi from '../../apis/api/preference';

const actSetIsShowPrecinctExploreDetail = (data) => ({
  type: Types.SET_IS_SHOW_PRECINCT_EXPLORE_DETAIL,
  data,
});

const actSetIsShowReplayVideo = (data) => ({
  type: Types.SET_IS_SHOW_REPLAY_VIDEO,
  data,
});

export const reqSetIsShowPrecinctExploreDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowPrecinctExploreDetail(data));
};

export const reqSetIsShowReplayVideo = (data) => (dispatch) => {
  return dispatch(actSetIsShowReplayVideo(data));
};

const actGetCustomerPreference = (data) => ({
  type: Types.GET_CUSTOMER_PREFERENCE,
  data,
});

export const reqGetCustomerPreference = (id) => (dispatch) => {
  return preferenceApi
    .getCustomerPreference({
      customer: id,
    })
    .then((data) => {
      dispatch(actGetCustomerPreference(data));
    })
    .catch((err) => {
      console.log(err);
    });
};
