import React, { useEffect, useState } from "react";
import "./forgot-password.scss";
import authApi from "../../apis/api/auth";
import { useHistory } from "react-router-dom";
import ROUTE_PATH from "../../routers/path";
import { useTranslation } from "react-i18next";
import icBaku from "../../assets/images/baku_logo.svg";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    if (email) {
      setErrorMessage("")
    }
  }, [email]);

  const handleForgotPassword = async () => {
    const data = {
      email: email,
      lang: i18n.language,
    };

    const res = await authApi.forgotPassword(data);

    if (res?.data) {
      toast.success(t("noti.Reset password email sent"))
    } else {
      setErrorMessage(t("Email not found"));
    }
  };

  const handleLogin = () => {
    history.push(ROUTE_PATH.HODLDING_PAGE);
  }

  return (
    <div className="wrap-holding-page h-100">
      <div className="icon-cube">
        <img
          src={icBaku}
          alt="icon-cube"
        />
      </div>
      <div className="wrap-modal-form">
        <div className={`modal-form`}>
          <h4 className="modal-form__title mb-0">
            {t('WELCOME TO THE BAKU')}
            <br />
            {t('RITZ-CARLTON RESIDENCES APP')}
          </h4>
          <div className="modal-form__body">
            <span className='label-form'>
              {t('Email')}
            </span>
            <div className="form-group">
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                className="form-control"
                id="email"
                placeholder={t('Email')}
              />
            </div>
            <p id="passwordHelp" className="text-danger mt-2 mb-0">
              {errorMessage && t(`errors.${errorMessage}`)}
            </p>
            <button
              type="submit"
              onClick={handleForgotPassword}
              className="btn-holding-forgot"
            >
              <span className="txt-login">{t('Reset Password')}</span>
            </button>
            <button
              type="submit"
              onClick={handleLogin}
              className="btn-forgot"
            >
              <span className="txt-login">{t('Log in')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
