import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getUnitList = (data) => client.get(endPoint.UNITS_GET_LIST, data);

const getUnitDetail = (unitId, data) =>
  client.get(endPoint.UNITS_GET_DETAIL.replace(':unitId', unitId), data);

const putUnitUpdate = (unitId, data) =>
  defaultClient.put(endPoint.UNITS_PUT_UPDATE.replace(':unitId', unitId), data);

export default {
  getUnitList,
  putUnitUpdate,
  getUnitDetail,
};
