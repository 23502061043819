import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./index.scss";

import preferenceApi from '../../apis/api/preference';
import FavoritesOfCustomer from '../../pages/customer-session/favorites-of-customer';

const EndGuideTenantSessionModal = ({ resetState }) => {
    const customerID = useSelector(state => state.home.activeEndGuideTenantSessionId);

    const [preference, setPreference] = useState();

    useEffect(() => {
        getCustomerPreference();
    }, [customerID]);

    const getCustomerPreference = async () => {
        try {
            const res = await preferenceApi.getCustomerPreference({
                customer: customerID,
            });
            setPreference(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="wrap-end-guide-modal"
        >
            <div className="modal-end-guide ps-5 pe-3 py-3">
                <div>
                    <h4 className="fw-bold title-underline">MICHELLE&apos;S FAVORITES</h4>
                    <p>You can access your favorites again through the unique URL that will be sent to your email:
                        <a className="ms-3 btn-text" href={`/${customerID}`}>tdd.app/xWjf83Hu</a>
                        {/* <a href={`/${customerId}`} className='btn-text'>END GUIDED TENANT SESSION</a> */}
                    </p>
                </div>

                <div className="modal-end-guide-body" onScroll={(e) => e.preventDefault()}>
                    <FavoritesOfCustomer items={preference?.units} />
                </div>

                <div className="modal-end-guide-btn">
                    <button
                        className="close-btn"
                        onClick={resetState}
                    >
                        &times;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EndGuideTenantSessionModal;
