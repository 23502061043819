import React, { useEffect } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { reqGetUnitList, reqPutUnitUpdate } from '../../reduxs/unit/action';
import _ from 'lodash';
import { AVAILABILITY_OPTIONS, PROPERTY_TYPE_OPTION } from '../../constants/options';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { useTranslation } from "react-i18next";

const convertCamelcaseToString = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const loadListField = (obj, skipFields = []) => (
  <ul>
    {Object.keys(_.omit(obj, skipFields)).map((key) => (
      <li key={key}>
        <b>{convertCamelcaseToString(key)}</b>: {obj[key]}
      </li>
    ))}
  </ul>
);

const Units = (props) => {
  const { roles, authMiddleware } = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const unitData = useSelector((state) => state.unit.data);
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
    dispatch(reqGetUnitList());
  }, []);

  const setAvailabilityStatus = (id, value) => {
    dispatch(reqPutUnitUpdate(id, { availabilityStatus: value })).then(() =>
      dispatch(reqGetUnitList())
    );
  };

  return (
    <div className={`page wrap-unit-inventory`}>
      <MDBTable striped>
        <MDBTableHead>
          <tr>
            <th>{t('Name')}</th>
            <th>Property Type</th>
            <th>Features</th>
            <th>Building</th>
            <th>Address</th>
            <th>Lot size(sqft)</th>
            <th>Home size(sqft)</th>
            <th>Views</th>
            <th>Price</th>
            <th>Availability Status</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {unitData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>
                {(PROPERTY_TYPE_OPTION.find((option) => option.value == item.propertyType) || {})
                  .title || item.propertyType}
              </td>
              <td>{loadListField(item.features, ['_id'])}</td>
              <td>{item.building}</td>
              <td>{item.address}</td>
              <td>{item.totalLot}</td>
              <td>{item.totalHome}</td>
              <th>{item.views}</th>
              <td>{item.price}</td>
              <td>
                <select
                  className="form-select form-select-sm"
                  onChange={(e) => setAvailabilityStatus(item.id, e.target.value)}
                >
                  {AVAILABILITY_OPTIONS.map((el, index) => {
                    return (
                      <option
                        key={index}
                        value={el.value}
                        selected={item.availabilityStatus == el.value}
                      >
                        {t(`AVAILABILITY_OPTIONS.${el.title}`)}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export default Units;
