import React, { useEffect, useRef, useState } from "react";

import { getUploadedServerMediaUrl } from "../../../helper/media";
import { IconPlay } from "../../svgs/icons";
import { AwModal } from "../../modal-aw/aw-modal";

import "../../home-gallery/index.scss";
import "./index.scss";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const [startVideo, setStartVideo] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if(content.action === ACTION_NAME.PLAY_VIDEO) {
          handlePlayVideo(content.data.url)
        }
        if(content.action === ACTION_NAME.STOP_VIDEO) {
          onStopVideo()
        }
      })
    }
  })

  const handlePlayVideo = (url) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        url
      })
    }
    setShowVideo(true);
    setUrlVideo(url);
  };

  const onStopVideo = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO)
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  return (
    <div className="films-content">
      {data?.map((i, idx) => {
        const thumbnail = i.media?.find(m => m.type === 'image');
        const film = i.media?.find(m => m.type === 'video');
        if (!film?.isActive) return;
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity && "infinity"}`}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${getUploadedServerMediaUrl(
                thumbnail?.path || ''
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "lightgray",
            }}
            onClick={() => handlePlayVideo(film?.path || '')}
          >
            <IconPlay style={{ width: 43, height: 50 }} />
          </div>
        );
      })}
      {isShowVideo && (
        <AwModal show={true} onClose={onStopVideo}>
          <div className="wrap-video-film">
            <video
              loop
              ref={videoRef}
              autoPlay={true}
              preload="auto"
            >
              <source src={getUploadedServerMediaUrl(urlVideo)} type="video/mp4" />
            </video>
          </div>
        </AwModal>
      )}
    </div>
  );
};
export default Films;
