import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import CMSExtraLeft from "../../assets/images/cms-extra-left.svg";
import CMSExtraRight from "../../assets/images/cms-extra-right.svg";
import {
  getUploadedServerMediaUrl,
} from "../../helper/media";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import CloseIcon from "../../pages/cms/components/Close";
import {
  TransformWrapper,
  TransformComponent,
} from "react-zoom-pan-pinch";
const FloorPlanGallery = (props) => {
  const { t } = useTranslation();
  const { floorPlanGalleryRef, isPresentation } = props;
  const [media, setMedia] = useState([]);
  const refs = useRef({});
  const [activeSlide, setActiveSlide] = useState(0);

  const transformComponentRef = useRef(null);

  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const dispatch = useDispatch();

  useEffect(async () => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floorPlan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ZOOM_IN_FLOORPLAN) {
          if(!transformComponentRef.current) return
          if(content.data.state) {
            return transformComponentRef.current.setTransform(
              content.data.state.positionX, 
              content.data.state.positionY, 
              content.data.state.scale
            )
          }
        }

        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          if(!transformComponentRef.current) return
          if(content.data.state) {
            const ratioHeight = window.screen.height / content.data.height; 
            const ratioWidth = window.screen.width * 0.7 / content.data.width;
            const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
            const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
            return transformComponentRef.current.setTransform(
              content.data.state.positionX * ratioWidth * marginWidth, 
              content.data.state.positionY * ratioHeight * marginHeight, 
              content.data.state.scale === 1 ? 1 : content.data.state.scale * (1 / marginWidth)
            )
          }
        }

        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          return enableTransparent();
        }

        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
      })
    }
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(true));
  }

  const hideFloorplan = () => {
    if (!isTransparent) {
      enableTransparent()
    }
    else {
      handleCloseBtn()
    }
  };

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const mediaImages = media.filter((item) => item.isActive);
  
  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1
      }}
      pinch={{
        step: 0.1
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, { state, height: window.screen.height, width: window.screen.width });
        }
      }}
    >  
    {({ zoomIn, zoomOut }) => (
      <>
      {isShowFloorplan && isTransparent && (
        <CloseIcon onClick={handleCloseBtn}/>
      )}
        <div className="wrap-floor-plan-gallery">
          <TransformComponent contentStyle={{height: "100%", width: "100vw"}}>
              {mediaImages?.length ? mediaImages.map((item, key) => {
                return (
                  <img key={key} src={getUploadedServerMediaUrl(item.path)} alt="floorplan" className="floorplan-item"/>
                );
              })
                : <div className="floorplan-item img-not-found">Image Not Found</div>
              }
          </TransformComponent>
        </div>
        <div className="wrap-zoom-btn">
            {isShowFloorplan && (
              <>
                {isTransparent && (
                  <div>
                    <img src={CMSExtraLeft} alt="cms-extra-left" />
                  </div>
                )}
                <div
                  onClick={hideFloorplan}
                  className={classNames({
                    "m-none": !isTransparent,
                  })}
                >
                  <span>
                    {isTransparent ? t("CLOSE FLOORPLAN") : t("CLOSE PANELS")}
                  </span>
                  {/* <img className="rotate-image" src="/icons/arrow.svg" alt="" /> */}
                </div>
                {!isTransparent && (
                  <div>
                    <img
                      src={CMSExtraRight}
                      alt="cms-extra-right"
                      className={classNames({
                        "ml-0": !isTransparent,
                      })}
                    />
                  </div>
                )}
              </>
            )}
            {isTransparent && (
              <>
                <img
                  onClick={() => {
                    zoomOut()
                  }}
                  className="zoom-out"
                  src="/icons/minus.svg"
                  alt=""
                />
                <img
                  className="zoom-in"
                  onClick={() => {
                    zoomIn()
                  }}
                  src="/icons/plus.svg"
                  alt=""
                />
                <div onClick={showFilter}>
                  {/* <img src="/icons/arrow.svg" alt="" /> */}
                  <span>{t("REOPEN PANELS")}</span>
                </div>
                <div>
                  <img src={CMSExtraRight} alt="cms-extra-right" />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </TransformWrapper>

  );
};

export default FloorPlanGallery;
