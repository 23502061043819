import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../components/Search";
import { reqGetListAmenities } from "../../../reduxs/cms/action";
import EditAmenityModal from "./EditAmenityModal";
import { useTranslation } from "react-i18next";

const Row = ({ data, onEditAmenity }) => {
  const {i18n} = useTranslation()
  const [show, setShow] = useState(false)

  return (
    <tr
      key={data?.id}
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
      className={!show ? "collapsed": ""}
    >
      <td style={{ width: "32px"}}>
        <img
          onClick={() => {setShow(!show)}}
          src={`/icons/arrow-${show? "up": "down"}.svg`}
          alt=""
          className="arrow"
        /> 
      </td>
      <td className="col-2 td-name">
          {data?.title[i18n.language]}
      </td>
      <td className="space"></td>
      <td className="col-9 td-description">
        {data?.description[i18n.language]}
      </td>
      <td className="col-1 td-edit" onClick={() => onEditAmenity(data)}>
        <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
      </td>
    </tr>
  );
}

const CMSAmenities = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const [isShowEditAmenityModal, setIsShowEditAmenityModal] = useState(false);
  const [seletedAmanity, setSelectedAmenity] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const amenities = useSelector((state) => state.cms.amenities);

  useEffect(() => {
    getListAmenities();
  }, [search, isSortAsc])

  useEffect(() => {
    getListAmenities();
  }, []);

  const getListAmenities = () => {
    dispatch(reqGetListAmenities({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    getListAmenities();
  }

  const onEditAmenity = (amenity) => {
    setSelectedAmenity(amenity);
    setIsShowEditAmenityModal(true);
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  } 

  const renderListProperty = () => {
    return (amenities || []).map((item, index) => {
      return (
        <CSSTransition key={item.id} timeout={200} classNames="fade-item">
          <Row
            data={item}
            index={index}
            onEditAmenity={onEditAmenity}
          />
        </CSSTransition>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="table-responsive-custom">
        <table className="table-custom">
          <thead>
            <tr
              style={{
                display: "table",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <th style={{ width: "32px"}}></th>
              <th className="col-2">
                {t("Amenity")}
              </th>
              <th className="space"></th>
              <th className="col-9">
                {t("Description")}
              </th>
              <th className="col-1"/>
            </tr>
          </thead>
          <div className="spacing-header" />
          <tbody
            style={{
              display: "block",
              maxHeight: "calc(100vh - 400px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <TransitionGroup>
              {renderListProperty()}
            </TransitionGroup>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">{t("AMENITIES CMS")}</h1>
      </div>
      <div className="page-body amenities-page-body">
        {renderTable()}
      </div>
      {
        isShowEditAmenityModal &&
        <EditAmenityModal 
          show={isShowEditAmenityModal}
          setShow={setIsShowEditAmenityModal}
          amenity={seletedAmanity}
          onSaveSuccess={onSaveSuccess}
        />
      }
      <SearchIcon handleInputChange={onSearch} />
    </div>
  );
};
export default CMSAmenities;
