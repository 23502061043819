import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getHubspotSettings = () => client.get(endPoint.HUBSPOT_SETTINGS_GET);
const updateHubspotSettings = (id, data) =>
    client.put(endPoint.HUBSPOT_SETTINGS_UPDATE.replace(':id', id), data);

export default {
    getHubspotSettings,
    updateHubspotSettings,
};
