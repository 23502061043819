import React from "react";

const data = [
  {
    amenity: 'Amenity Name',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ...',
    isEdit: true,
  },
  {
    amenity: 'Amenity Name',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ...',
    isEdit: true,
  },  {
    amenity: 'Amenity Name',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ...',
    isEdit: true,
  },
]

const CMSAnalytics = () => {
  const renderTable = () => {
    return (
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 wrapper-table">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="py-3.5 pb-8 pl-4 pr-3 text-left ">
              Amenity
            </th>
            <th scope="col" className="px-3 py-3.5 text-left ">
              Description
            </th>
            <th scope="col" className="px-3 py-3.5 text-left "/>
          </tr>
          </thead>
          <div className="spacing-header" />
          <tbody className="divide-y divide-gray-200">
          {data.map((item) => (
            <tr key={item.email}>
              <td className="whitespace-nowrap pl-4 pr-3 text-sm ">
                {item.amenity}
              </td>
              <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                {item.description}
              </td>
              <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">ANALYTICS</h1>
      </div>
      <div className="page-body">
        {renderTable()}
      </div>
    </div>
  );
};
export default CMSAnalytics;

