import * as Types from './type';

export const actSetIsShowUserTypeModal = (data) => ({
  type: Types.SET_IS_SHOW_USER_TYPE_MODAL,
  data,
});

export const actSetIsShowLanguageModal = (data) => ({
  type: Types.SET_IS_SHOW_LANGUAGE_MODAL,
  data,
});

export const actSetIsShowListInvestorModal = (data) => ({
  type: Types.SET_IS_SHOW_LIST_INVESTOR_MODAL,
  data,
});

export const actSetIsShowListTenantModal = (data) => ({
  type: Types.SET_IS_SHOW_LIST_TENANT_MODAL,
  data,
});

export const actSetIsShowListConsultantModal = (data) => ({
  type: Types.SET_IS_SHOW_LIST_CONSULTANT_MODAL,
  data,
});

export const actSetUserSelectTypeModal = (data) => ({
  type: Types.SET_USER_SELECT_TYPE,
  data,
});
export const actSetIsShowCreateInvestorProfileModal = (data) => ({
  type: Types.SET_IS_SHOW_CREATE_INVESTOR_PROFILE_MODAL,
  data,
});
export const actSetIsShowCreateTenantProfileModal = (data) => ({
  type: Types.SET_IS_SHOW_CREATE_TENANT_PROFILE_MODAL,
  data,
});
export const actSetIsShowCreateConsultantProfileModal = (data) => ({
  type: Types.SET_IS_SHOW_CREATE_CONSULTANT_PROFILE_MODAL,
  data,
});
export const actSetIsShowPostSessionPopup = (data) => ({
  type: Types.SET_IS_SHOW_POST_SESSION_POPUP,
  data,
});

export const actSetVideoMuted = (data) => ({
  type: Types.SET_VIDEO_MUTED,
  data,
});

export const reqSetVideoMuted = (data) => (dispatch) => {
  return dispatch(actSetVideoMuted(data));
};

export const reqSetIsShowPostSessionPopup = (data) => (dispatch) => {
  return dispatch(actSetIsShowPostSessionPopup(data));
};

export const reqSetIsShowUserTypeModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowUserTypeModal(data));
};

export const reqSetIsShowLanguageModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowLanguageModal(data));
};

export const reqSetIsShowListInvestorModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowListInvestorModal(data));
};

export const reqSetIsShowListConsultantModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowListConsultantModal(data));
};

export const reqSetIsShowListTenantModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowListTenantModal(data));
};

export const reqSetUserSelectTypeModal = (data) => (dispatch) => {
  return dispatch(actSetUserSelectTypeModal(data));
};

export const reqSetIsShowCreateInvestorProfileModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCreateInvestorProfileModal(data));
};
export const reqSetIsShowCreateTenantProfileModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCreateTenantProfileModal(data));
};
export const reqSetIsShowCreateConsultantProfileModal =
  (data) => (dispatch) => {
    return dispatch(actSetIsShowCreateConsultantProfileModal(data));
  };
