import React from "react";

const Parking = () => {
  return (
    <div className='wrap-transport-option mb-3'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-4'>
          <h4 className='text-uppercase fw-bold title-underline mb-4'>Parking</h4>
          <h6 className='mb-4'>Travel time</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum nunc aliquet bibendum enim facilisis gravida.
          </p>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
          <img className='img-fluid w-100' src="/images/tram.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Parking;
