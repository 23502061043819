import React from "react";
import "./aw-modal.scss";
import { IconCloseButton } from "../svgs/icons";
import { classNames } from "../../helper/utils";

export const AwModal = (
  {
    show, onClose, children, cn = '', isShowBtnClose = true, isOutSideClose = true,
    currentColor = "white",
  }
) => {
  if (!show) return null
  return (
    <div
      className={classNames('aw-modal-container', cn)}
      onClick={isOutSideClose ? onClose : () => null}
    >
      <div className="aw-modal-body">
        {isShowBtnClose && (
          <div className="aw-modal-btn-close" onClick={onClose}>
            <IconCloseButton style={{width: 26}} fill={currentColor}/>
          </div>
        )}
        <div className="aw-modal-base-content">
          {children}
        </div>
      </div>
    </div>
  );
};
