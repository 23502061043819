import React, { useEffect, useState } from "react";
import iconArrow from "../../../../assets/images/arrow.png";
import Modal from "../../../modal-base";
import "../index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImagePath, getUploadedServerMediaUrl } from "../../../../helper/media";
import {
  IconCloseButton,
  IconSwitchLevels,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../svgs/icons";
import Fancybox from "../../../home-gallery/fancy-box";
import { useTranslation } from "react-i18next";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";

export const ContentFloor = ({
  data,
  onClick,
  numberFloorSelected,
  isModal,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="header-content">
        <span className="aw-title">{t("FloorPlans.title")}</span>
      </div>
      <div className="aw-body-content">
        <div className="bottom-top" />
        <div className="aw-row">
          {data.map((i, idx) => (
            <div
              key={`renders-${idx}`}
              onClick={() => onClick(i)}
              className={`car-floor ${
                isModal && numberFloorSelected === i.name ? "isSelected" : ""
              }`}
            >
              <span>{i.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const ContentPlans = ({
  numberFloorSelected,
  setShowFloorModal,
  onShowGalleryData,
  data,
}) => {
  const { t } = useTranslation();
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const selectedItemMedia =
    selectedItem?.media?.length > 0 ? selectedItem?.media[0] : {};
  const _hotspots = selectedItemMedia?.hotspots
    ? Array.isArray(selectedItemMedia?.hotspots)
      ? selectedItemMedia?.hotspots
      : Object.values(selectedItemMedia?.hotspots)
    : [];

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleLoad = (event) => {
    const naturalWidth = event.target?.naturalWidth,
      naturalHeight = event.target?.naturalHeight;
    setImageSize({
      // width: naturalWidth ? (width / naturalWidth) : 0,
      // height: naturalHeight ? (height / naturalHeight) : 0
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <div className="content-plans">
      <div className="header-content">
        <span className="aw-title">{`${t(
          "FloorPlans.level"
        )} ${numberFloorSelected}`}</span>
      </div>
      <div className="plans-body">
        <img
          onLoad={handleLoad}
          src={getImagePath(selectedItemMedia?.path)}
          alt="plans-floor"
          className="cursor-pointer"
        />
        <div className="plans-marker">
          {_hotspots?.map((mk, mki) => {
            const mkx = (
              imageSize?.width
                ? (Number(mk?.x || 0) / imageSize?.width) * 100
                : 0
            ).toFixed(4);
            const mky = (
              imageSize?.height
                ? (Number(mk?.y || 0) / imageSize?.height) * 100
                : 0
            ).toFixed(4);
            return (
              <div
                key={String(mki)}
                onClick={() => onShowGalleryData(`im-${mk?.floorplan}`)}
                style={{
                  position: "absolute",
                  left: `calc(${mkx}% - 20px)`,
                  top: `calc(${mky}% - 25px)`,
                  cursor: "pointer",
                }}
              >
                <img src={getImagePath(mk?.image)} style={{ width: 40 }} />
              </div>
            );
          })}
        </div>
        <div className="btn-floor" onClick={() => setShowFloorModal(true)}>
          <IconSwitchLevels style={{ width: 25 }} />
          <div className="btn-floor-text">{t("FloorPlans.btnFloor")}</div>
        </div>
      </div>
    </div>
  );
};
const ViewPlans = ({ data, numberFloorSelected }) => {
  const idxSelected = data.findIndex((i) => i.name === numberFloorSelected);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={iconArrow} alt="next" className="next-arrow" />,
    prevArrow: <img src={iconArrow} alt="previous" className="pre-arrow" />,
  };
  return (
    <div className="view-plans">
      <Slider {...settings} className="slider-floor-plan">
        {data[idxSelected].media.map((item, key) => {
          return (
            <div key={key} className="img-plans-content">
              <img
                src={getImagePath(item.path)}
                alt="plans-floor"
                className="cursor-pointer"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export const ViewModal = ({
  data,
  isShowFloorModal,
  onHideFloorModal,
  numberFloorSelected,
  onSelectedFloor,
  fill = "#2D2927",
}) => {
  return (
    <div
      className="modal-floor"
      style={{ display: isShowFloorModal ? "flex" : "none" }}
    >
      <div className="modal-body">
        <div className="modal-close">
          <div onClick={onHideFloorModal} className="modal-close-icon">
            <IconCloseButton style={{ width: 26 }} fill={fill} />
          </div>
        </div>
        <div className="modal-floor-container">
          <ContentFloor
            isModal
            numberFloorSelected={numberFloorSelected}
            onClick={onSelectedFloor}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
const FloorPlans = ({ data, isInfinity, isPresentation }) => {
  const [isShowFloorModal, setShowFloorModal] = useState(false);
  const [numberFloorSelected, setNumberFloorSelected] = useState("0");
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [isShowViewPlans, setIsShowViewPlans] = useState(false);

  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if(content.action === ACTION_NAME.SHOW_FLOOR_MODAL) {
          onShowFloorModal()
        }
        if(content.action === ACTION_NAME.HIDE_FLOOR_MODAL) {
          onHideFloorModal()
        }
        if(content.action === ACTION_NAME.SHOW_GALLERY_DATA) {
          onShowGalleryData(content.data.id)
        }
        if(content.action === ACTION_NAME.SELECT_FLOOR) {
          onSelectedFloor(content.data.floor)
        }
        if(content.action === ACTION_NAME.SHOW_VIEW_PLAN) {
          handleShowViewPlan()
        }
        if(content.action === ACTION_NAME.CLOSE_VIEW_PLAN) {
          handleCloseViewPlans()
        }
        if(content.action === ACTION_NAME.CLOSE_RENDER_GALLERY) {
          onCloseImage()
        }
        if(content.action === ACTION_NAME.JUMP_TO_RENDER_IMAGE) {
          onJumpToImage(content.data.index)
        }
      })
    }
  }, [isPresentation])

  const onHideFloorModal = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FLOOR_MODAL)
    }
    setShowFloorModal(false);
  };

  const onShowFloorModal = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FLOOR_MODAL)
    }
    setShowFloorModal(true);
  }

  const [showGalleryData, setShowGalleryData] = useState(null);
  // const firstGallery = Array.isArray(data) && data?.length > 0 ? data[0] : {}
  // const firstGallery = floorDetail;
  // const imgList =
  //   (Array.isArray(firstGallery?.media) ? firstGallery?.media : [])?.reduce(
  //     (previousValue, currentValue) => {
  //       return [
  //         ...previousValue,
  //         {
  //           image: currentValue?.path,
  //           key: `im-${currentValue?.name}`,
  //         },
  //       ];
  //     },
  //     []
  //   ) || [];
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const imgList =
    (Array.isArray(selectedItem?.floorplans)
      ? selectedItem?.floorplans
      : []
    )?.sort((a, b) => Number(a.name?.split(' ')?.[2]) - Number(b.name?.split(' ')?.[2]))?.map((currentValue) => {
      return { image: currentValue.isActive ? currentValue?.path : '', key: `im-${currentValue?.name}` };
    }) || [];

  const onShowGalleryData = (id = "") => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_DATA, {
        id
      })
    }
    const el = document.getElementById(id);
    if (el) {
      el.click();
      setShowGalleryData(id);
    }
  };

  const onSelectedFloor = (floor) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_FLOOR, {
        floor
      })
    }
    setIsFloorSelected(true);
    setNumberFloorSelected(floor.name);
    setShowFloorModal(false);
  };

  const handleShowViewPlan = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_VIEW_PLAN)
    }
    setIsShowViewPlans(true);
  };

  const handleCloseViewPlans = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_VIEW_PLAN)
    }
    setIsShowViewPlans(false);
  };

  const onJumpToImage = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_RENDER_IMAGE, {
        index,
      });
    }
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.jumpTo(index);
  }

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.next();
  };

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.prev();
  };

  const onCloseImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    const fancyBoxRef = document.getElementsByClassName(
      "fancybox__container"
    )[0]?.Fancybox;
    fancyBoxRef?.close();
  };

  return (
    <div className={`floor-plans-content ${isInfinity ? "infinity" : ""}`}>
      {isFloorSelected ? (
        <ContentPlans
          numberFloorSelected={numberFloorSelected}
          handleShowViewPlan={handleShowViewPlan}
          setShowFloorModal={onShowFloorModal}
          onShowGalleryData={onShowGalleryData}
          data={data}
        />
      ) : (
        <ContentFloor onClick={onSelectedFloor} data={data} />
      )}
      <div style={{ display: showGalleryData ? "block" : "none" }}>
        <Fancybox
          options={{
            infinite: true,
            mainClass: "pure-fancy-box",
            showNavArrows: false,
            on: {
              destroy: () => setShowGalleryData(null),
              "Carousel.change": (fancybox) => {
                const el = fancybox.getSlide();
                if (!isPresentation) {
                  socket.emitUIActionEvent(ACTION_NAME.JUMP_TO_RENDER_IMAGE, {
                    index: el.index
                  });
                }
                onShowGalleryData(el.$trigger.id);
              },
              "Carousel.selectSlide": (fn, sc, el) => {
                onShowGalleryData(el.$trigger.id);
              },
            },
          }}
        >
          {imgList?.map((item, index) => (
            <img
              id={item?.key}
              key={String(index)}
              src={getUploadedServerMediaUrl(item.image)}
              alt="normal-img"
              data-fancybox="gallery"
            />
          ))}
        </Fancybox>
      </div>
      {!!showGalleryData && (
        <div>
          <div className="fancy-btn-prev cursor-pointer" onClick={onPrevImage}>
            <LeftArrowIcon style={{ width: 21, height: 35 }} />
          </div>
          <div className="fancy-btn-next cursor-pointer" onClick={onNextImage}>
            <RightArrowIcon style={{ width: 21, height: 35 }} />
          </div>
        </div>
      )}
      {!!showGalleryData && (
        <div className="wrap-fancy-close">
          <div className="cursor-pointer" onClick={onCloseImage}>
            <IconCloseButton style={{ width: 26 }} fill={"#2D2927"} />
          </div>
        </div>
      )}

      <Modal
        classNames="modal-view-plans"
        open={isShowViewPlans}
        onHide={handleCloseViewPlans}
        isOutSideClose={false}
        currentColor="black"
      >
        <ViewPlans data={data} numberFloorSelected={numberFloorSelected} />
      </Modal>
      <ViewModal
        isShowFloorModal={isShowFloorModal}
        numberFloorSelected={numberFloorSelected}
        data={data}
        onSelectedFloor={onSelectedFloor}
        onHideFloorModal={onHideFloorModal}
      />
    </div>
  );
};
export default FloorPlans;
