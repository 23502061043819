/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import socket from '../../helper/socket';
import sessionApi from '../../apis/api/session';

const SessionsList = () => {
  let { path, url } = useRouteMatch();
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    getListSessions();
  }, []);

  const getListSessions = async () => {
    const result = await sessionApi.getSessionList();
    setSessions(result?.data || []);
  };

  useEffect(() => {
    socket.auth = { username: '' };
    socket.connect();
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <div className="card wrap-list-session">
          <div className="card-header d-flex">
            <h6 className="title">SESSIONS LIST</h6>{' '}
          </div>
          <div className="card-body">
            <div className="session-item btn-new-session">
              <Link to={`${url}/create`} className="btn-row">
                + ADD NEW SESSION
              </Link>
            </div>
            {sessions.map((session, index) => {
              return (
                <div className="session-item complete" key={index}>
                  <div>
                    <p className="session-title">
                      {session.agent.name} <span>(COMPLETE)</span>
                    </p>
                    <p className="session-subtitle">
                      {session.customer.firstname} {session.customer.surname}
                    </p>
                  </div>
                  <Link to={`${url}/${session.customer.id}/start`}>START SESSION</Link>
                </div>
              );
            })}
          </div>
        </div>
      </Route>
    </Switch>
  );
};

export default SessionsList;
