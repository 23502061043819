import "./index.scss";
import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ContentImage from "./ContentImage";
import ContentFilms from "./ContentFilms";
import { useTranslation } from "react-i18next";
import ContentFloorplan from './ContentFloorplan';

export const ContentSectionOptions = [
  { label: "Films", value: "Films" },
  { label: "Floorplans", value: "Floorplans" },
  { label: "Images", value: "Images" },
]

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video'
}

export const CONTENT_TYPE = {
  FILMS: 'Films',
  FLOORPLANS: 'Floorplans',
  IMAGES: 'Images'
}

const CMSContent = () => {
  const {t} = useTranslation();
  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">{t("CONTENT CMS")}</h1>
      </div>
      <div className="page-body content-page-body">
        <Tabs
          defaultActiveKey="content-image"
          className=""
        >
          <Tab eventKey="content-film" title={t("FILMS")}>
            <ContentFilms />
          </Tab>
          <Tab eventKey="content-floorplan" title={t("FLOORPLANS")}>
            <ContentFloorplan />
          </Tab>
          <Tab eventKey="content-image" title={t("IMAGES")}>
            <ContentImage />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default CMSContent;
