import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { actIntroduction } from "../../reduxs/scene/action";
import {
  reqGetCustomerProfile,
  reqSetCustomerProfile,
} from "../../reduxs/user/action";
import { useHistory } from "react-router-dom";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { useTranslation } from "react-i18next";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { EmailIcon, LocationIcon, PhoneIcon } from "../svgs/icons";
import socket from "../../helper/socket";
import { clearFilterUnit } from "../../helper/utils";
import { getMediaUrl, getUploadedServerMediaUrl } from "../../helper/media";
import { formatMoney, sqmtToSqft } from "../../helper/unit";
import { isEmpty } from "lodash";

const Session = ({ isPresentation }) => {
  const [counter, setCounter] = useState(5);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const customer = useSelector((state) => state.user.customer);
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const prefUnitGallery = (customerPreference?.units?.find(e => e.gallery?.media?.length))?.gallery?.media || [];

  const paths = history.location.pathname.split("/");
  const isPostEngagementPage = paths[paths.length - 1].length === 24; // mongodb object id length
  const customerId = paths[paths.length - 1];

  useEffect(() => {
    if (!isPresentation) {
      if (!customer) {
        dispatch(reqGetCustomerProfile(customerId));
      } else {
        socket.emitUIActionEvent(ACTION_NAME.SYNC_CUSTOMER_END_SESSION, { customerId: customer.id });
      }
    }
    if (!customerPreference && customer?.id) {
      dispatch(reqGetCustomerPreference(customer?.id));
    }
  }, [customer]);

  const refCount = useRef(5);
  const interValCountDown = useRef(null);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPostEngagementPage || localStorage.getItem("disableSessionCounter")) {
      return;
    }
    // countTime();
    return () => clearInterval(interValCountDown.current);
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
    if (content.action === ACTION_NAME.SYNC_CUSTOMER_END_SESSION) {
      dispatch(reqGetCustomerProfile(content.data.customerId));
    }
    if (content.action === ACTION_NAME.END_POST_SESSION) {
      handleCloseSession();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      if (socket) socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  const countTime = () => {
    interValCountDown.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      refCount.current -= 1;
      if (refCount.current <= 0) {
        handleCloseSession();
        clearInterval(interValCountDown.current);
      }
    }, 1000);
  };

  const handleCloseSession = () => {
    clearFilterUnit()
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.END_POST_SESSION);
    }
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push(isPresentation ? 'presentation' : '/');
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  return (
    <div
      className="static-page-wf sessionPage"
      style={{ marginTop: (isPostEngagementPage || isPresentation) ? 0 : 80 }}
    >
      <div
        className="scroll-view"
        ref={pageRef}
        onScroll={onScroll}
        style={{
          marginBottom: isPostEngagementPage ? 0 : 80,
          height: isPostEngagementPage ? "100svh" : "calc(100svh - 80px)",
        }}
      >
        <div className="session-title-wrapper">
          <div className="session-title-container">
            <div className="session-title-logo-wrapper">
              <img
                className="session-title-logo"
                src="/uploads/media/session-logo.png"
              />
            </div>
            <div className="session-title-text">
              {t("A NEW HOME, HAVEN & HUB IN BAKU")}
            </div>
          </div>
        </div>
        <div className="info-user-outer">
          <div className="info-user-inner">
            <span className="title-name">{`${t("HI")} ${customer?.name || ''},`}</span>
            <span className="title-des">{t("POST_SESSION_SUBTITLE")}</span>
          </div>
        </div>
        <div>
          {customerPreference?.units.map((unit, _index) => {
            const index = _index % 4;
            const indexPosition = index % 2;
            const renderAspect = (value) => {
              if (value === "sea_view" || value === "city_view") return t(`UnitDetail.${value}`)
              return value
            }
            const unitDetails = [
              { key: t('post_session.availability'), value: t(unit?.availabilityStatus || '') },
              { key: t('post_session.unit_size'), value: `${unit?.totalLot || 0} m²` },
              { key: t('post_session.aspect'), value: `${renderAspect(unit.aspect)}` },
              { key: t('post_session.price'), value: formatMoney(unit?.price) },
              ...(unit?.roomAreas?.[i18n.language]
                ?.split(',')
                ?.map(e => ({
                  key: String(e?.split(':')?.[0] || '').trim(),
                  value: String(e?.split(':')?.[1] || '').trim(),
                }))
                ?? []
              ),
            ];

            return (
              <div className={`unit-view-outer position-${index}`} key={_index}>
                <div className={`unit-view-inner pos-${indexPosition}`}>
                  <div className="unit-floorplan-ctn">
                    <img src={getUploadedServerMediaUrl(unit?.floorPlan?.media?.[0]?.path || '')} />
                  </div>
                  <div className={`unit-details index-${index}`}>
                    <span className={`unit-details-title index-${index}`}>
                      {t("Residence")} {unit.name}
                    </span>
                    <div className={`unit-details-des index-${index}`}>
                      <ul className="room-area-list" key={index}>
                        {unitDetails?.map((room, ind) =>
                          <li key={ind + room}>
                            <div className="content">
                              <span style={{ fontWeight: 'bold' }}>{room.key}: </span>
                              <span>{room.value}</span>
                            </div>
                          </li>)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {!isEmpty(prefUnitGallery?.length) && (
          <div className="unit-gallery-ctn-outer">
            <div className="unit-gallery-ctn-inner">
              {prefUnitGallery.map((img, i) => {
                if (i >= 8) return null;
                return (
                  <img
                    key={i}
                    src={getMediaUrl(img.path)}
                    className={`unit-image-${i}`}
                  />
                );
              })}
            </div>
          </div>
        )}

        <div className="post-session-contact-wrapper">
          <div className="post-session-contact">
            <img
              className="post-session-contact-image"
              src="/uploads/media/session-contact.png"
            />
            <div className="post-session-contact-info">
              <div className="post-session-contact-info-details">
                <span className="location-icon">
                  <LocationIcon />
                </span>
                <span>
                  {t('POST SESSION CONTACT')}
                </span>
              </div>
              <div className="post-session-contact-info-details">
                <span className="phone-icon">
                  <PhoneIcon />
                </span>
                <span>+994 51 201 32 32</span>
              </div>
              <div className="post-session-contact-info-details">
                <span className="email-icon">
                  <EmailIcon />
                </span>
                <a href="mailto:sales@rcresidencesbaku.com">sales@rcresidencesbaku.com</a>
              </div>
            </div>
          </div>
          <span className="post-session-copyright-text">
            {t(
              'The Ritz-Carlton Residences, Baku are not owned, developed or sold by The Ritz-Carlton Hotel Company'
            )}
          </span>
        </div>
      </div>
      {!isPostEngagementPage && (
        <div className="close-view" style={{ marginBottom: (isPresentation) ? 0 : 80 }}>
          {/* <span>{`Closing and saving session in ${counter} seconds ...`}</span> */}
          <span onClick={handleCloseSession} style={{ cursor: 'pointer' }}>{t(`CLOSE_SAVE_SESSION`)}</span>
        </div>
      )}
    </div>
  );
};

export default Session;
