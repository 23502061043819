import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { GALLERY_TYPE } from "../../../constants/options";
import galleryApi from "../../../apis/api/gallery";
import AddContentModal from "./AddContentModal";
import EditContentModal from "./EditContentModal";
import SearchIcon from "../components/Search";
import { getUploadedServerMediaUrl } from "../../../helper/media";
import CreateIcon from "../components/Create";
import mediaAPI from "../../../apis/api/media";
import { useTranslation } from "react-i18next";
import { CONTENT_TYPE, MEDIA_TYPE } from ".";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ContentImage = () => {
  const { t } = useTranslation();
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [seletedGallery, setSeletedGallery] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [search, isSortAsc]);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res && res.data) {
        let result = res.data;
        if (search) {
          result = result.filter(
            (gallery) =>
              gallery.media &&
              gallery.media[0]?.name?.toLowerCase().includes(search)
          );
        }
        result.sort(
          (a, b) => (a.media?.[0]?.order ?? 999) - (b.media?.[0]?.order ?? 999)
        );

        setGalleries(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateSuccess = () => {
    loadData();
  };

  const onSaveSuccess = () => {
    loadData();
  };

  const onEditContent = (gallery) => {
    setSeletedGallery(gallery);
    setIsShowEditContentModal(true);
  };

  const handleUpdateActive = async (e, selectedItem) => {
    if (!selectedItem) return;

    try {
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("isActive", e.target.checked);
      const updated = await mediaAPI.updateMedia(selectedItem.id, formData);
      if (updated?.data) {
        toast.success(t("Content updated successfully!"));
      }
    } catch (err) {
      toast.error("Content updated failed!");
    }
  };

  const onSearch = (e) => {
    setSearch(e.target?.value?.toLowerCase());
  };

  const renderListMedia = () => {
    if (!galleries?.length) {
      return (
        <div className="d-flex justify-content-center">{t("NO IMAGE")}</div>
      );
    }
    return galleries?.map((g, index) => {
      const item = g?.media?.[0];
      const pathThumb =
                `${item?.path?.split(".")?.[0]}-thumb.jpeg` || item?.path;
      return (
        <div key={index} className="wrap-media-item">
          <LazyLoadImage
            src={getUploadedServerMediaUrl(pathThumb)} // the source of the image
            alt={item?.name} // alternative text for the image
            effect="blur" // effect used when the image is loading (optional)
            height="auto" // height of the image (optional)
            width="100%" // width of the image (optional)
            className="content-image"
            onError={(e) => {
              e.currentTarget.src = getUploadedServerMediaUrl(item?.path)
            }}
          />
          <div className="content-title-row">
            <span className="content-title">
              {item?.name || t("Content Title")}
            </span>
            <div className="content-action">
              <div className="status">
                <div className="form-check form-switch my-n1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="content-state-1"
                    defaultChecked={item?.isActive}
                    onChange={(e) => handleUpdateActive(e, item)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="content-state-1"
                  />
                </div>
              </div>
              <div onClick={() => onEditContent(g)}>
                <div className="action-edit">
                  <img
                    className="img-fluid"
                    src="/icons/edit-button.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="wrap-media-list">{renderListMedia()}</div>
      {isShowAddContentModal && (
        <AddContentModal
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
          mediaType={MEDIA_TYPE.IMAGE}
          contentType={CONTENT_TYPE.IMAGES}
        />
      )}
      {isShowEditContentModal && (
        <EditContentModal
          show={isShowEditContentModal}
          setShow={setIsShowEditContentModal}
          onSaveSuccess={onSaveSuccess}
          content={seletedGallery?.media?.[0]}
          gallery={seletedGallery}
          contentType={CONTENT_TYPE.IMAGES}
          mediaType={MEDIA_TYPE.IMAGE}
        />
      )}
      <div onClick={() => setIsShowAddContentModal(true)}>
        <CreateIcon />
      </div>
      <SearchIcon handleInputChange={onSearch} />
    </>
  );
};

export default ContentImage;
