import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/images/close-white.svg";
import preferenceApi from '../../../apis/api/preference';
import LoadingIcon from "../../../components/loading-icon";
import { useTranslation } from "react-i18next";

const renderAspect = (value) => {
	if(value === "sea_view") return "Sea View";
	if(value === "city_view") return "City View";
	return value;
}

const Row = ({ data }) => {
	const {t} = useTranslation();
	const [show, setShow] = useState(false);

	return (
		<div className={`content-row ${!show ? "collapsed": ""}`}>
			<div>
				<img
					onClick={() => {setShow(!show)}}
					src={`/icons/arrow-${show? "up": "down"}.svg`}
					alt=""
					className="arrow"
				/>
			</div>
			<div>{t("Residence")} {data?.name}</div>
			<div>{data?.floorplanNumber}</div>
			<div className="availability">{t(data?.availabilityStatus)}</div>
			<div>{data?.fit}</div>
			<div>{data?.totalLot}</div>
			<div>{data?.bedrooms}</div>
			<div>{t(renderAspect(data?.aspect))}</div>
			<div>{data?.price}</div>
			<div>{data?.price}</div>
			<div>{show ? data?.description : "..."}</div>
		</div>
	);
}

const CustomerFavoritesModal = ({ customer, show, setShow }) => {
	const {t, i18n} = useTranslation();
	const [preference, setPreference] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		customer && getCustomerPreference();
	}, [customer]);

	const getCustomerPreference = async () => {
		try {
			setLoading(true);
			const res = await preferenceApi.getCustomerPreference({
				customer: customer?.id,
			});
			setLoading(false);
			setPreference(res.data);
		} catch (error) {
			setLoading(false);
		}
	}
	
	const handleClose = () => {
		setShow(false);
	};

	const renderListProperty = () => {
    return (preference?.units || []).map((item, index) => {
      	return (
			<Row key={item?.id} data={item} />
      	);
    });
  };

	const renderGallery = () => {
		if (!preference || !preference?.units) return null;
		const sampleGalleries = preference?.units[0]?.gallery?.media || [];
		return (
			<>
				<div className="gallery-title">{t("CONTENT")}</div>
				<div className="gallery">
					{
						sampleGalleries.slice(0, 3).map((item, index) => {
							return (
								<div key={item.id} className="gallery-item">
									<img src={`/uploads/${item.path}`} />
									<span>{item.name}</span>
								</div>
							);
						})
					}
				</div>
			</>
		);
	}

	const renderContent = () => {
		if (loading) return <LoadingIcon />;

		if (!preference || !preference?.units) return <div className="content" style={{paddingLeft: "50px"}}>{t("No favorites found.")}</div>;

		return (
			<div className="content">
				<div className="content-header">
					<div></div>
					<div>{t("Residence")}</div>
					<div>{t("FLOOR")}</div>
					<div>{t("Availability")}</div>
					<div>{t("FIT")}</div>
					<div>{t("Lot Size")}</div>
					<div>{t("Beds")}</div>
					<div>{t("Aspect")}</div>
					<div>{t("Base price")}</div>
					<div>{t("FULL-FIT PRICE")}</div>
					<div>{t("Description")}</div>
				</div>
				<div className="content-list">{renderListProperty()}</div>
				{renderGallery()}
			</div>
		);
	}

	const modalTitle = i18n.language === "ru" ?
		`${t("FAVORITES")} ${customer?.name}` :
		`${customer?.name}'S FAVORITES`;

	return (
		<Modal
			className="wrap-customer-favorites-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				{renderContent()}
			</Modal.Body>
		</Modal>
	);
};

export default CustomerFavoritesModal;
