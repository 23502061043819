import React from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import GalleryDetailPicture from "./_gallery-deatail-image";

const GalleryDetail = (props) => {
  const dispatch = useDispatch();
  const onCloseModal = () => {
    dispatch(reqSetIsShowGalleryModal(false));
  };
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h2 className="card-title underline-sm">4B3 - District Living</h2>
        <span
          className="close"
          role="button"
          onClick={onCloseModal}
        >
          <img
            id="btn_close_gallery"
            width=""
            className="btn_close_gallery"
            src="uploads/images/icons/icon-close.svg"
            alt=""
          />
        </span>
      </div>
      <div className="card-body pt-0">
        <GalleryDetailPicture />
      </div>
    </div>
  );
};

export default GalleryDetail;
