import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMediaUrl } from "../../helper/media";
import { useSelector } from "react-redux";

const GalleryDetailPicture = (props) => {
  const galleryRef = useRef();
  const listMedia = useSelector(
    (state) => state.districtdetail.activeGalleryModal.media
  );
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: "slides",
    arrows: false,
    fade: true,
    lazyLoad: true,
  };
  return (
    <>
      <Slider ref={galleryRef} {...settings}>
        {listMedia?.map((item, key) => (
          <div key={key}>
            <div
              className="gallery-item"
              style={{
                backgroundImage: `url(${getMediaUrl(item.path)})`,
              }}
            />
          </div>
        ))}
      </Slider>
      <span className="btn-gallery prev-slide">
        <img
          className="img-fluid"
          src="/icons/arrow-left.svg"
          alt=""
          onClick={() => galleryRef.current.slickPrev()}
        />
      </span>
      <span className="btn-gallery next-slide">
        <img
          className="img-fluid"
          src="/icons/arrow-right.svg"
          alt=""
          onClick={() => galleryRef.current.slickNext()}
        />
      </span>
    </>
  );
};
export default GalleryDetailPicture;
